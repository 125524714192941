import { instance } from '../../utils/coreAPI'
import { CATEGORY_API_PATH } from '../../constants/globalConstants'
import { CategoryRequestType } from '../CategoriesPage/CategoriesPage-types'

export const getIdCategory = async (id: string) => {
  try {
    const response = await instance.get(`${CATEGORY_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const categoryChange = async (id: string, data: CategoryRequestType) => {
  try {
    const PATH = `${CATEGORY_API_PATH}/${id}`
    const response = await instance.patch(PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const categoryRemove = async (id: string) => {
  try {
    const PATH = `${CATEGORY_API_PATH}/${id}`
    const response = await instance.delete(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
