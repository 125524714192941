import { useMutation, useQueryClient } from 'react-query'
import decodeJwt from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { logoutCall, signinCall } from './AuthModule-api'
import { Credencials, TokenAnswer } from './AuthModule-types'
import {
  ACCESS_TOKEN_KEY,
  ROLE_ADMIN,
  ROLE_SUPERADMIN,
} from '../../../constants/common'
import {
  getPermissions,
  LSClear,
  setPermissions,
  setToken,
} from '../../../utils/storage'
import { authDataResetAction, authDataSetAction } from './AuthModule-actions'
import {
  DOMAIN_PATH,
  MAIN_PATH,
  SIGNIN_PATH,
} from '../../../constants/globalConstants'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import { ADMIN_COOKIE_KEY } from '../../../constants/storage'

export const useAuth = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mutation = useMutation(
    (credencials: Credencials) => signinCall(credencials),
    {
      onSuccess: async (data: TokenAnswer) => {
        const jwtToken = data[ACCESS_TOKEN_KEY]
        if (ACCESS_TOKEN_KEY in data) {
          const decodedToken: any = decodeJwt(jwtToken)
          setToken(jwtToken)
          setPermissions(decodedToken.role)
        }
        const permissions = getPermissions()
        if (permissions !== ROLE_ADMIN && permissions !== ROLE_SUPERADMIN) {
          throw new Error('Недостаточно прав для входа')
        }
        dispatch(
          authDataSetAction({
            permissions,
            token: jwtToken,
          })
        )
        const cookies = new Cookies()
        cookies.set(ADMIN_COOKIE_KEY, 'true', {
          domain: DOMAIN_PATH,
          path: '/',
        })
        navigate(MAIN_PATH, { replace: true })
      },
    }
  )
  return mutation
}

export const useLogout = () => {
  const client = useQueryClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mutation = useMutation(() => logoutCall(), {
    onSuccess: async () => {
      dispatch(authDataResetAction())
      navigate(SIGNIN_PATH, { replace: true })
      LSClear()
      const cookies = new Cookies()
      cookies.remove(ADMIN_COOKIE_KEY)
      client.clear()
    },
    onError: (error: Error) => {
      toast.error(error?.message ?? 'Не удалось выйти')
    },
  })
  return mutation
}
