import { GROUP_CONTRIBUTORS_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { GroupContributorAddType } from './ContributorGroupAddPage-types'

export const groupContributorAdd = async (data: GroupContributorAddType) => {
  try {
    const response = await instance.post(GROUP_CONTRIBUTORS_API_PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
