import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonReportList.sass'

export const SkeletonReportList = () => {
  return (
    <div className='skeleton-report-list'>
      <SkeletonBase type='text' className='skeleton-report-list__item' />
      <SkeletonBase type='text' className='skeleton-report-list__item' />
      <SkeletonBase type='text' className='skeleton-report-list__item' />
      <SkeletonBase type='text' className='skeleton-report-list__item' />
    </div>
  )
}
