import classNames from 'classnames'
import React from 'react'
import defaultUserPhoto from '../../assets/img/default-user.svg'
import './UserImg.sass'

interface UserImgProps {
  photo?: string
  className?: string
}

export const UserImg: React.FC<UserImgProps> = ({
  photo = undefined,
  className,
}) => {
  const avatar = photo ? photo : defaultUserPhoto
  const userPrfx = 'user-photo'
  const divCls = classNames(userPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={userPrfx}>
      <div className={`${userPrfx}__wrapper ${divCls}`}>
        <img
          className={`${userPrfx}__img`}
          src={avatar}
          alt='Фото пользователя'
        />
      </div>
    </div>
  )
}
