import React from 'react'
import { ModeratorWindow } from '../../../../components/ModeratorWindow'
import { ModeratorsType } from '../../../../types/moderator'

interface ModeratorsMobileProps {
  moderatorsList: ModeratorsType
  handleRowClick: (id: string) => void
}

export const ModeratorsMobile: React.FC<ModeratorsMobileProps> = ({
  moderatorsList,
  handleRowClick,
}) => {
  if (!moderatorsList.length) {
    return <span>Еще не создано ни одного модератора</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {moderatorsList.map((moderator) => (
          <ModeratorWindow
            className='windows-wrapper-windows__item'
            key={moderator.id}
            id={moderator.id}
            login={moderator.login}
            status={moderator.status}
            registerDate={moderator.registerDate}
            onClick={handleRowClick}
          />
        ))}
      </div>
    </div>
  )
}
