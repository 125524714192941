import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonMainMobile.sass'

export const SkeletonMainMobile = () => {
  return (
    <div className='skeleton-main-mobile'>
      <div className='skeleton-main-mobile-column'>
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__title'
        />
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__item'
        />
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__item'
        />
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__item'
        />
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__item'
        />
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__item'
        />
      </div>
      <div className='skeleton-main-mobile-column'>
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__title'
        />
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__item'
        />
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__item'
        />
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__item'
        />
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__item'
        />
        <SkeletonBase
          type='text'
          className='skeleton-main-mobile-column__item'
        />
      </div>
    </div>
  )
}
