import { BREADCRUMBS_CLEAR, BREADCRUMBS_SET } from './BreadCrumbs-constants'
import { breadcrumbsType } from './BreadCrumbs-types'

export const breadCrumbsSet = (breadcrumbs: breadcrumbsType) => {
  return {
    type: BREADCRUMBS_SET,
    payload: breadcrumbs,
  }
}

export const breadCrumbClear = () => {
  return {
    type: BREADCRUMBS_CLEAR,
  }
}
