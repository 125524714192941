import classNames from 'classnames'
import React from 'react'
import {
  ControlWrapperPrefixCls,
  CustomInputPrefix,
  InputPrefixCls,
} from './Input-constants'
import { InputProps } from './Input-types'
import './Input.sass'

export const Input: React.FC<InputProps> = (props) => {
  const {
    title,
    className,
    type,
    size = 'medium',
    required,
    placeholder,
    errors,
    id,
    ...rest
  } = props
  const inputClassName = classNames(InputPrefixCls, CustomInputPrefix, {
    [`${CustomInputPrefix}_error`]: !!errors,
  })
  const controlClassName = classNames(ControlWrapperPrefixCls, {
    [`${className}`]: className,
    [`${ControlWrapperPrefixCls}_size_sm`]: size === 'small',
    [`${ControlWrapperPrefixCls}_size_lg`]: size === 'large',
  })
  return (
    <div className={controlClassName}>
      {title && (
        <label htmlFor={id}>
          {required && <span>* </span>}
          {title}
        </label>
      )}
      <input
        type={type}
        id={id}
        className={inputClassName}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  )
}
