import classNames from 'classnames'
import { ReactComponent as Search } from '../../assets/img/search.svg'
import React from 'react'
import './SearchIcon.sass'

interface SearchIconProps {
  className?: string
  isActive: boolean
  onClick?: () => void
}

export const SearchIcon: React.FC<SearchIconProps> = ({
  className,
  isActive,
  onClick,
}) => {
  const searchPrfx = 'search-icon'
  const searchCls = classNames(searchPrfx, {
    [`${searchPrfx}_active`]: isActive === true,
    [`${className}`]: className,
  })
  return (
    <div className={searchCls} onClick={onClick}>
      <span className={`${searchPrfx}__title`}>Поиск</span>
      <Search className={`${searchPrfx}__img`} />
    </div>
  )
}
