import React from 'react'
import classNames from 'classnames'
import { InputProps } from './Input-types'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import './InputPassword.sass'
import {
  ControlWrapperPrefixCls,
  CustomInputPrefix,
  InputPasswordPrefixCls,
} from './Input-constants'
import { Button } from '../Button'

export const InputPassword: React.FC<InputProps> = (props) => {
  const {
    title,
    type,
    className,
    size = 'medium',
    errors,
    required,
    placeholder,
    id,
    ...rest
  } = props
  const [visible, setVisible] = React.useState(false)
  const inputClassName = classNames(InputPasswordPrefixCls, CustomInputPrefix, {
    [`${CustomInputPrefix}_error`]: !!errors,
  })
  const controlClassName = classNames(ControlWrapperPrefixCls, {
    [`${className}`]: className,
    [`${ControlWrapperPrefixCls}_size_sm`]: size === 'small',
    [`${ControlWrapperPrefixCls}_size_lg`]: size === 'large',
  })
  return (
    <div className={controlClassName}>
      <div className='password-wrapper'>
        <label htmlFor={id}>
          {required && <span>* </span>}
          {title}
        </label>
        <div className='password-container'>
          <input
            id={id}
            type={visible ? 'text' : 'password'}
            className={inputClassName}
            placeholder={placeholder}
            {...rest}
          />
          <Button
            className='control-wrapper__icon password-wrapper__icon '
            short
            htmlType='button'
            type='transparent'
            onClick={() => setVisible(!visible)}
          >
            {visible ? <FaEyeSlash /> : <FaEye />}
          </Button>
        </div>
      </div>
    </div>
  )
}
