import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { moderateDenyMaterialCall } from '../api/moderateDeny'
import { MODERATION_PATH } from '../constants/globalConstants'
import { ModerateItemRequest } from '../types/moderate'

export const useModerateDenyMaterial = (materialId: string | null) => {
  const navigate = useNavigate()
  const client = useQueryClient()
  const mutation = useMutation(
    (values: ModerateItemRequest) =>
      moderateDenyMaterialCall(
        values.id,
        values.rejectionId,
        values?.description
      ),
    {
      onSuccess: async () => {
        client.removeQueries('moderationMaterial')
        client.refetchQueries('moderationMaterial')
        navigate(`${MODERATION_PATH}`)
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось завершить операцию')
      },
    }
  )
  return mutation
}
