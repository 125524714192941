import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { BsTrash } from 'react-icons/bs'
import { Button } from '../../components/Button'
import { Input, InputPassword } from '../../components/Input'
import { phoneRe } from '../../utils/regulars'
import { InputPhone } from '../../components/InputPhone'
import { UserFormType } from '../../types/common'

interface UserAddFormProps {
  className?: string
  handleSubmit: (values: UserFormType) => void
  handleCancel: () => void
  isLoading: boolean
}

export const UserAddForm: React.FC<UserAddFormProps> = ({
  className,
  handleSubmit,
  handleCancel,
  isLoading = false,
}) => {
  const initialValues = {
    login: '',
    name: '',
    password: '',
    email: '',
    phone: '',
  }
  const validationSchema = Yup.object({
    login: Yup.string().required('Обязательно'),
    name: Yup.string().required('Обязательно'),
    password: Yup.string().required('Обязательно'),
    email: Yup.string().email().required('Обязательно'),
    phone: Yup.string()
      .matches(phoneRe, 'Неверный формат номера')
      .required('Обязательно'),
  })
  const onSubmit = async (values: UserFormType) => {
    handleSubmit({
      login: values.login,
      password: values.password,
      name: values.name,
      email: values.email,
      phone: values.phone
        ? values.phone
            .replace(/\)/g, '')
            .replace(/\(/g, '')
            .replace(/-/g, '')
            .replace(/ /g, '')
        : values.phone,
    })
  }

  return (
    <div className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
      >
        {(formik) => (
          <Form className={`user-form`}>
            <div className='user-form-left'>
              <h2 className={`user-form__title`}>Персональная информация</h2>
              <Input
                type='text'
                id='login'
                title='Логин'
                value={formik.values['login']}
                errors={formik.errors['login']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <Input
                type='text'
                id='name'
                title='Имя'
                value={formik.values['name']}
                errors={formik.errors['name']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <InputPassword
                type='password'
                id='password'
                title='Пароль'
                value={formik.values['password']}
                errors={formik.errors['password']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <Input
                type='email'
                id='email'
                title='Почта'
                value={formik.values['email']}
                errors={formik.errors['email']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <InputPhone
                id='phone'
                value={formik.values['phone']}
                errors={formik.errors['phone']}
                onChange={formik.handleChange}
                title='Номер телефона'
                className={`user-form__control`}
              />
            </div>
            <div className='user-form-btns'>
              <Button
                htmlType='submit'
                disabled={!formik.isValid}
                className={`user-form-btns__item user-form__control`}
                loading={isLoading}
              >
                Сохранить изменения
              </Button>
              <Button
                htmlType='button'
                type='transparent'
                className={`user-form-btns__item user-form__control`}
                onClick={handleCancel}
              >
                Отмена{' '}
                <span>
                  <BsTrash />
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
