import classNames from 'classnames'
import React from 'react'
import { getNormalizeDate, getStatus } from '../../utils/utils'
import './ConsumerWindow.sass'

interface ConsumerWindowProps {
  className?: string
  id: string
  login: string
  status: string
  limit: number
  registerDate: string
  contractExpiration: string
  onClick: (id: string) => void
}

export const ConsumerWindow: React.FC<ConsumerWindowProps> = ({
  className,
  id,
  status,
  login,
  limit,
  registerDate,
  contractExpiration,
  onClick,
}) => {
  const windowPrfx = 'consumer-window'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })

  const statusPrfx = 'consumer-window__status'
  const statusCls = classNames(statusPrfx, {
    [`${statusPrfx}_active`]: status === 'active',
    [`${statusPrfx}_disabled`]: status === 'disabled' || status === 'locked',
  })

  const statusValue = getStatus(status)
  const loginValue = login ?? 'Неизвестно'
  const limitValue = limit
  const contractExpirationValue = getNormalizeDate(contractExpiration)
  const registerDateValue = getNormalizeDate(registerDate)

  return (
    <div className={windowCls} onClick={() => onClick(id)}>
      <div className={statusCls}>{statusValue}</div>
      <div className={`${windowPrfx}__login`}>{loginValue}</div>
      <div className={`${windowPrfx}__limit`}>
        Лимит:{' '}
        <span className={`${windowPrfx}__limit-value`}>{limitValue}</span>
      </div>
      <div className={`${windowPrfx}__date`}>
        {registerDateValue} - {contractExpirationValue}
      </div>
    </div>
  )
}
