import classNames from 'classnames'
import React from 'react'
import { getNormalizeDate, getStatus } from '../../utils/utils'
import './AdminWindow.sass'

interface AdminWindowProps {
  className?: string
  id: string
  login: string
  status: string
  registerDate: string
  consumersCount: number
  contributorsCount: number
  moderatorsCount: number
  onClick: (id: string) => void
}

export const AdminWindow: React.FC<AdminWindowProps> = ({
  className,
  id,
  login,
  status,
  registerDate,
  consumersCount,
  contributorsCount,
  moderatorsCount,
  onClick,
}) => {
  const windowPrfx = 'admin-window'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })

  const statusPrfx = 'admin-window__status'
  const statusCls = classNames(statusPrfx, {
    [`${statusPrfx}_active`]: status === 'active',
    [`${statusPrfx}_disabled`]: status === 'disabled' || status === 'locked',
  })

  const statusValue = getStatus(status)
  const loginValue = login ?? 'Неизвестно'
  const registerDateValue = getNormalizeDate(registerDate)
  const usersCount = consumersCount + contributorsCount + moderatorsCount

  return (
    <div className={windowCls} onClick={() => onClick(id)}>
      <div className={statusCls}>{statusValue}</div>
      <div className={`${windowPrfx}__login`}>{loginValue}</div>
      <div className={`${windowPrfx}__users`}>
        Пользователей:{' '}
        <span className={`${windowPrfx}__users-value`}>{usersCount}</span>
      </div>
      <div className={`${windowPrfx}__date`}>{registerDateValue}</div>
    </div>
  )
}
