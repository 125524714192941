import classNames from 'classnames'
import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonStatisticItem.sass'

interface SkeletonStatisticItemProps {
  className?: string
}

export const SkeletonStatisticItem: React.FC<SkeletonStatisticItemProps> = ({
  className,
}) => {
  const skeletonPrfx = 'skeleton-statistic-item'
  const skeletonCls = classNames(skeletonPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={skeletonCls}>
      <SkeletonBase className={`${skeletonPrfx}__title`} type='title' />
      <SkeletonBase className={`${skeletonPrfx}__value`} type='text' />
    </div>
  )
}
