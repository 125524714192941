import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import { SkeletonMaterialDescription } from '../SkeletonMaterialDescription'
import './SkeletonModeration.sass'

export const SkeletonModeration = () => {
  return (
    <div className='skeleton-moderation'>
      <div className='skeleton-moderation-info'>
        <SkeletonBase className='skeleton-moderation-info__item' type='text' />
        <SkeletonBase className='skeleton-moderation-info__item' type='text' />
      </div>
      <SkeletonMaterialDescription />
    </div>
  )
}
