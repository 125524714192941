import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { InfoModule } from './modules/InfoModule'
import { UserChangeForm } from '../../modules/UserChangeForm'
import { AdminType } from '../AdminsPage/AdminsPage-types'
import { isObjectEmpty } from '../../utils/check'
import { IncorretResult } from '../../components/IncorretResult'
import { useBreadcrumbs } from '../../utils/hooks'
import {
  useAdminById,
  useChangeStatusAdmin,
  useRemoveAdmin,
  useUpdateAdmin,
} from './AdminEditPage-hooks'
import { ADMINS_PATH, MAIN_PATH } from '../../constants/globalConstants'
import { SkeletonFormPage } from '../../components/SkeletonFormPage'
import { useParams } from 'react-router'
import { confirmDeleteDialog } from '../../utils/confirms'
import { UserFormType, UserRequestType } from '../../types/common'

export const AdminEditPage = () => {
  const { id } = useParams<'id'>()

  const {
    data: adminData,
    isLoading,
    isError,
  } = useAdminById(id!) as {
    data: AdminType
    isLoading: boolean
    isError: boolean
  }

  const { mutateAsync: updateAdmin, isLoading: loadingChange } =
    useUpdateAdmin() as {
      mutateAsync: (values: Partial<UserRequestType>) => Promise<any>
      isLoading: boolean
    }

  const { mutateAsync: changeAdminStatus, isLoading: loadingChangeStatus } =
    useChangeStatusAdmin() as {
      mutateAsync: (id: string) => Promise<any>
      isLoading: boolean
    }
  const { mutateAsync: removeAdmin, isLoading: loadingDelete } =
    useRemoveAdmin() as {
      mutateAsync: (id: string) => Promise<any>
      isLoading: boolean
    }

  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Управление администраторами',
        path: ADMINS_PATH,
      },
      {
        id: '2',
        name: `Профиль ${adminData?.login ?? ''}`,
        path: `${ADMINS_PATH}/${id}`,
      },
    ],
    [id, adminData]
  )

  useBreadcrumbs(breadCrumbsData)

  const handleSubmit = async (userData: Partial<UserFormType>) => {
    await updateAdmin({ id, ...userData })
  }

  const removeUser = async (id: string) => {
    await removeAdmin(id)
  }

  const handleDelete = async (id: string) => {
    const isOk = await confirmDeleteDialog('Вы хотите удалить пользователя?')
    if (isOk) {
      removeUser(id)
    }
  }

  const handleChangeStatus = async (id: string) => {
    await changeAdminStatus(id)
  }
  const handleHistory = () => {}

  if (isLoading) {
    return (
      <SkeletonFormPage
        rightColumn={'statistic'}
        leftFormCount={5}
        statisticCount={3}
        withRegisterInfo={true}
      />
    )
  }
  if (isObjectEmpty(adminData) || isError) {
    return <IncorretResult />
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule
          registerBy={adminData?.registerBy}
          registerDate={adminData.registerDate}
          title={'Редактирование администратора'}
        />
      </div>

      <div className='common-page-body common-page-body_double'>
        <UserChangeForm
          className='common-page-body__left'
          name={adminData.name}
          password=''
          login={adminData.login}
          email={adminData.email}
          phone={adminData.phone}
          handleSubmit={handleSubmit}
          handleAction={handleHistory}
          loadingChange={loadingChange}
          buttonText='История активности'
        />
        <InfoModule
          className='common-page-body__right'
          id={id!}
          contributors={adminData.contributorsCount}
          consumers={adminData.consumersCount}
          moderators={adminData.moderatorsCount}
          status={adminData.status}
          onDelete={handleDelete}
          onChangeStatus={handleChangeStatus}
          loadingDelete={loadingDelete}
          loadingChangeStatus={loadingChangeStatus}
        />
      </div>
    </div>
  )
}
