import { REPORT_API_PATH } from '../../../../constants/globalConstants'
import { REPORT_ACTION_ENUM, REPORT_RIGHTS_ENUM } from '../../../../types/enums'
import { instance } from '../../../../utils/coreAPI'

export const reportGenerate = async (
  id: string,
  startDate: string,
  endDate: string,
  rights: REPORT_RIGHTS_ENUM,
  action: REPORT_ACTION_ENUM
) => {
  try {
    const response = await instance.post(
      `${REPORT_API_PATH}/contributor/${id}`,
      {
        startDate,
        endDate,
        rights,
        action,
      }
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
