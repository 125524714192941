import { CATEGORY_ALL_API_PATH } from '../constants/globalConstants'
import { CategoryType } from '../types/common'
import { instance } from '../utils/coreAPI'

export const fetchCategoriesCall = async () => {
  try {
    const response = await instance.get(CATEGORY_ALL_API_PATH)
    if (response.data?.length) {
      const categoriesOptions = response.data.map((category: CategoryType) => ({
        id: category.id,
        label: category.category,
        value: category.id.toString(),
      }))
      return categoriesOptions
    } else {
      return []
    }
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
