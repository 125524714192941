import classNames from 'classnames'
import React from 'react'
import { Button } from '../../components/Button'
import { FormTitle } from '../../components/FormTitle'
import { ReportList } from '../../components/ReportList'
import { ReportAPIAnswerType } from '../../types/report'
import './ReportListModule.sass'

interface ReportListModuleProps {
  className?: string
  handleReport: () => void
  currentPage: number
  handlePageChange: (page: string) => void
  isLoading: boolean
  isError: boolean
  reportList: ReportAPIAnswerType
}

export const ReportListModule: React.FC<ReportListModuleProps> = ({
  className,
  handleReport,
  currentPage,
  handlePageChange,
  reportList,
  isLoading,
  isError,
}) => {
  const itemPrfx = 'report-list-module'
  const itemCls = classNames(itemPrfx, {
    [`${className}`]: className,
  })
  if (isError) {
    return null
  }

  return (
    <div className={itemCls}>
      <FormTitle text={'Отчеты'} />
      <ReportList
        currentPage={currentPage}
        isLoading={isLoading}
        reports={reportList}
        handlePageChange={handlePageChange}
        className={`${itemPrfx}__items`}
      />
      <Button
        htmlType='button'
        type='transparent'
        border
        className={`${itemPrfx}__btn`}
        onClick={handleReport}
      >
        Запросить отчет
      </Button>
    </div>
  )
}
