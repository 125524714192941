import React from 'react'
import { IncorretResult } from '../../components/IncorretResult'
import { CATEGORIES_PATH, MAIN_PATH } from '../../constants/globalConstants'
import { CategoryChangeForm } from './modules/CategoryChangeForm'
import { StatisticModule } from '../../modules/StatisticModule'
import { isObjectEmpty } from '../../utils/check'
import { useBreadcrumbs } from '../../utils/hooks'
import { CategoryRequestType } from '../CategoriesPage/CategoriesPage-types'
import {
  useCategoryById,
  useRemoveCategory,
  useUpdateCategory,
} from './CategoriesEditPage-hooks'
import { SkeletonFormPage } from '../../components/SkeletonFormPage'
import { useParams } from 'react-router'
import { confirmDeleteDialog } from '../../utils/confirms'
import { CategoryType } from '../../types/common'

export const CategoriesEditPage = () => {
  const { id } = useParams<'id'>()

  const {
    data: categoryData,
    isLoading,
    isError,
  } = useCategoryById(id!) as {
    data: CategoryType
    isLoading: boolean
    isError: boolean
  }

  const { mutateAsync: updateCategory, isLoading: loadingChange } =
    useUpdateCategory() as {
      mutateAsync: (values: CategoryType) => Promise<any>
      isLoading: boolean
    }

  const { mutateAsync: removeCategory, isLoading: loadingDelete } =
    useRemoveCategory() as {
      mutateAsync: (id: string) => Promise<any>
      isLoading: boolean
    }

  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Управление категориями',
        path: CATEGORIES_PATH,
      },
      {
        id: '2',
        name: `Категория "${categoryData?.category ?? ''}"`,
        path: `${CATEGORIES_PATH}/${id}`,
      },
    ],
    [id, categoryData]
  )

  useBreadcrumbs(breadCrumbsData)

  const handleSubmit = async (categoryData: CategoryRequestType) => {
    updateCategory({ id: id!, ...categoryData })
  }

  const handleRemove = async (id: string) => {
    await removeCategory(id)
  }

  const handleDelete = async (id: string) => {
    const isOk = await confirmDeleteDialog('Вы хотите удалить категорию?')
    if (isOk) {
      handleRemove(id)
    }
  }

  if (isLoading) {
    return (
      <SkeletonFormPage
        rightColumn={'empty'}
        leftFormCount={1}
        withRegisterInfo={false}
      />
    )
  }
  if (isObjectEmpty(categoryData) || isError) {
    return <IncorretResult />
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Изменение категории'} />
      </div>
      <div className='common-page-body common-page-body_restricted'>
        <CategoryChangeForm
          id={id!}
          category={categoryData.category}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          loadingChange={loadingChange}
          loadingDelete={loadingDelete}
        />
      </div>
    </div>
  )
}
