import React from 'react'
import { IncorretResult } from '../../../../components/IncorretResult'
import { MaterialImg } from '../../../../components/MaterialImg'
import {
  getNormalizeDate,
  getProtectedPathFromHash,
} from '../../../../utils/utils'
import { StatusItem } from '../../../../components/StatusItem'
import { isObjectEmpty } from '../../../../utils/check'
import { MaterialDetailItem } from '../../../../components/MaterialDetailItem'
import { MaterialDetailInfo } from '../../../../components/MaterialDetailInfo'
import {
  useMaterialById,
  useMaterialRemove,
} from '../../MaterialInfoPage-hooks'
import { SkeletonMaterialsInfo } from '../../../../components/SkeletonMaterialsInfo'
import { Button } from '../../../../components/Button'
import { MODERATION_PATH } from '../../../../constants/globalConstants'
import { useNavigate } from 'react-router'
import { confirmDeleteDialog } from '../../../../utils/confirms'
import { ExclusiveRightsComponent } from '../../../../components/ExclusiveRightsComponent'
import { MaterialDetailType } from '../../../../types/materials'

interface MaterialDetailsProps {
  id: string
}

export const MaterialDetails: React.FC<MaterialDetailsProps> = ({ id }) => {
  const navigate = useNavigate()
  const {
    data: materialDetails,
    isLoading,
    isError,
  } = useMaterialById(id) as {
    data: MaterialDetailType
    isLoading: boolean
    isError: boolean
  }
  const { mutateAsync: removeMaterial, isLoading: loadingRemoving } =
    useMaterialRemove() as {
      mutateAsync: (id: string) => Promise<any>
      isLoading: boolean
    }

  const imgPath = getProtectedPathFromHash(materialDetails?.protectedHash)

  if (isLoading) {
    return <SkeletonMaterialsInfo />
  }

  if (isObjectEmpty(materialDetails) || isError) {
    return <IncorretResult />
  }

  const handleClick = () => {
    navigate('view')
  }

  const handleDelete = async () => {
    const isOk = await confirmDeleteDialog(
      'Уверены, что хотите удалить материал?'
    )
    if (isOk) {
      removeMaterial(id)
    }
  }
  const handleModerationClick = () => {
    navigate(`${MODERATION_PATH}?moderationId=${id}`)
  }

  const uploadDateValue = getNormalizeDate(materialDetails.uploadDate)
  const purchaseTotalValue = materialDetails?.purchasedTotal
  const purchaseMonthValue = materialDetails?.purchasedMonth
  const authorNameValue = materialDetails?.contributor?.name
  const categoryNameValue = materialDetails?.category?.category
  const tagsValue = materialDetails?.tags
  const descriptionValue = materialDetails?.description
  const rejectionTextValue = materialDetails?.rejection?.rejection
  const exclusiveRightsValue = materialDetails?.exclusiveRights

  return (
    <div className='material-details'>
      <div className='material-details-info'>
        <MaterialDetailInfo
          className='material-details-info__item'
          title='Загружено'
          value={uploadDateValue}
        />
        <StatusItem
          className='material-details-info__item'
          value={materialDetails.status}
          title={'Статус: '}
        />
        {purchaseTotalValue && (
          <MaterialDetailInfo
            className='material-details-info__item'
            title='Скачано всего'
            value={`${purchaseTotalValue}`}
          />
        )}
        {purchaseMonthValue && (
          <MaterialDetailInfo
            className='material-details-info__item'
            title='Скачано за этот месяц'
            value={`${purchaseMonthValue}`}
          />
        )}
      </div>
      <div className='material-details-data'>
        <div className='material-details-data-properties'>
          {
            <MaterialDetailItem
              title='Автор'
              data={authorNameValue}
              className='material-details-data-properties__item'
            />
          }
          {
            <MaterialDetailItem
              title='Категория'
              data={categoryNameValue}
              className='material-details-data-properties__item'
            />
          }
          {tagsValue.length && (
            <MaterialDetailItem
              title='Теги'
              data={tagsValue}
              className='material-details-data-properties__item'
            />
          )}
          {descriptionValue && (
            <MaterialDetailItem
              title='Описание'
              data={descriptionValue}
              className='material-details-data-properties__item'
            />
          )}
          {rejectionTextValue && (
            <MaterialDetailItem
              title='Причина отказа'
              data={rejectionTextValue}
              className='material-details-data-properties__item'
            />
          )}
          {exclusiveRightsValue && (
            <ExclusiveRightsComponent className='material-details-data-properties__item material-details-data-properties__item_double-margin' />
          )}
        </div>

        <div className='material-details-data-photo'>
          <MaterialImg
            srcPath={imgPath}
            contain
            className='material-details-data-photo__img'
            onClick={handleClick}
          />
          <Button
            type='danger'
            onClick={handleDelete}
            loading={loadingRemoving}
            className='material-details-data-photo__btn'
          >
            Удалить
          </Button>
          <Button
            type='primary'
            onClick={handleModerationClick}
            className='material-details-data-photo__btn'
          >
            Модерация
          </Button>
        </div>
      </div>
    </div>
  )
}
