import classNames from 'classnames'
import React from 'react'
import { getNormalizeDate } from '../../utils/utils'
import './GroupWindow.sass'

interface GroupWindowProps {
  className?: string
  name: string
  dateStart: string
  dateFinish: string | null
  membersCount: number
  onClick: () => void
}

export const GroupWindow: React.FC<GroupWindowProps> = ({
  className,
  name,
  dateStart,
  dateFinish,
  membersCount,
  onClick,
}) => {
  const windowPrfx = 'group-window'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })

  const startDateValue = getNormalizeDate(dateStart)
  const endDateValue = dateFinish ? getNormalizeDate(dateFinish) : ''

  return (
    <div className={windowCls} onClick={onClick} role='button'>
      <div className={`${windowPrfx}__name`}>{name}</div>
      <div className={`${windowPrfx}__members`}>Участников: {membersCount}</div>
      <div className={`${windowPrfx}__date`}>
        <span className={`${windowPrfx}__date-start`}>{startDateValue} </span>
        {endDateValue && (
          <span className={`${windowPrfx}__date-end`}>- {endDateValue}</span>
        )}
      </div>
    </div>
  )
}
