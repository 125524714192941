import { Routes, Route, Navigate, Outlet } from 'react-router'
import { AuthPage } from './pages/AuthPage'
import { Navbar } from './modules/Navbar'
import { GeneralPage } from './pages/GeneralPage'
import { ContributorsPage } from './pages/ContributorsPage'
import { AdminsPage } from './pages/AdminsPage'
import { ModerationPage } from './pages/ModerationPage'
import { ModerationHistoryPage } from './pages/ModerationHistoryPage'
import { RejectionsPage } from './pages/RejectionsPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { ContributorAddPage } from './pages/ContributorAddPage'
import { ContributorEditPage } from './pages/ContributorEditPage'
import { AdminAddPage } from './pages/AdminAddPage'
import { AdminEditPage } from './pages/AdminEditPage'
import { CategoriesPage } from './pages/CategoriesPage'
import { MaterialsPage } from './pages/MaterialsPage'
import { CaterogiesAddPage } from './pages/CaterogiesAddPage'
import { CategoriesEditPage } from './pages/CategoriesEditPage'
import { RejectionEditPage } from './pages/RejectionEditPage'
import { RejectionAddPage } from './pages/RejectionAddPage'
import { MaterialInfoPage } from './pages/MaterialInfoPage'
import { ModeratorsPage } from './pages/ModeratorsPage'
import { ModeratorAddPage } from './pages/ModeratorAddPage'
import { ModeratorEditPage } from './pages/ModeratorEditPage'
import {
  ADMINS_PATH,
  CATEGORIES_PATH,
  CONSUMERS_PATH,
  CONTRIBUTORS_PATH,
  FORBIDDEN_PATH,
  GROUP_CONSUMERS_PATH,
  GROUP_CONTRIBUTORS_PATH,
  MAIN_PATH,
  MATERIALS_PATH,
  MODERATION_HISTORY_PATH,
  MODERATION_PATH,
  MODERATORS_PATH,
  NEWS_PATH,
  NOT_FOUND_PATH,
  REJECTIONS_PATH,
  REQUESTS_PATH,
  SEARCH_HISTORY_PATH,
  SIGNIN_PATH,
  WITHDRAW_PATH,
} from './constants/globalConstants'
import { ConsumersPage } from './pages/ConsumersPage'
import { ConsumerAddPage } from './pages/ConsumerAddPage'
import { ConsumerEditPage } from './pages/ConsumerEditPage'
import { RequestsPage } from './pages/RequestsPage'
import { RequestEditPage } from './pages/RequestEditPage'
import { OrdersPage } from './pages/OrdersPage'
import { OrderEditPage } from './pages/OrderEditPage'
import { NewsPage } from './pages/NewsPage'
import { ForbiddenPage } from './pages/ForbiddenPage'
import { useSelector } from 'react-redux'
import { ScrollToTop } from './components/ScrollToTop'
import { RequireAdmin } from './components/RequireAdmin'
import { RequireSuperAdmin } from './components/RequireSuperAdmin'
import { RootState } from './reducers'
import { ImgFullScreen } from './components/ImgFullScreen'
import { RejectDialog } from './components/RejectDialog'
import { GetParameterPopup } from './components/GetParameterPopup'
import { SearchHistoryPage } from './pages/SearchHistoryPage'
import { FavouriteMaterialsPage } from './pages/FavouriteMaterialsPage'
import { PurchasedMaterialsPage } from './pages/PurchasedMaterialsPage'
import { ConsumerReportModule } from './pages/ConsumerEditPage/modules/ReportModule'
import { ContributorReportModule } from './pages/ContributorEditPage/modules/ReportModule'
import { ROLE_CONSUMER, ROLE_CONTRIBUTOR } from './constants/common'
import { ConsumerGroupPage } from './pages/ConsumerGroupPage'
import { ContributorGroupPage } from './pages/ContributorGroupPage'
import { ContributorGroupAddPage } from './pages/ContributorGroupAddPage'
import { ConsumerGroupAddPage } from './pages/ConsumerGroupAddPage'
import { ContributorGroupReportModule } from './pages/ContributorGroupEditPage/modules/ReportModule'
import { ConsumerGroupReportModule } from './pages/ConsumerGroupEditPage/modules/ReportModule'
import { ConsumerGroupEditPage } from './pages/ConsumerGroupEditPage'
import { ContributorGroupEditPage } from './pages/ContributorGroupEditPage'

export const AppRoutes: React.FC = () => {
  const isAuth = useSelector((state: RootState) => state.auth.isAuth)
  return isAuth ? (
    <div className='admin-page'>
      <ScrollToTop />
      <Navbar />
      <div className='admin-page-body'>
        <Routes>
          <Route element={<RequireAdmin />}>
            <Route path={MAIN_PATH} element={<GeneralPage />} />

            {/* Consumer Routes */}
            <Route path={CONSUMERS_PATH} element={<Outlet />}>
              <Route index element={<ConsumersPage />} />
              <Route path={`new`} element={<ConsumerAddPage />} />
              <Route path={`:id`} element={<Outlet />}>
                <Route path={`edit`} element={<ConsumerEditPage />} />
                <Route path={'report'} element={<ConsumerReportModule />} />
                <Route
                  path={`favourite`}
                  element={<FavouriteMaterialsPage />}
                />
                <Route
                  path={`purchased`}
                  element={<PurchasedMaterialsPage userType={ROLE_CONSUMER} />}
                />
              </Route>
            </Route>

            {/* Contributor Routes */}
            <Route path={CONTRIBUTORS_PATH} element={<Outlet />}>
              <Route index element={<ContributorsPage />} />
              <Route path={`new`} element={<ContributorAddPage />} />
              <Route path={`:id`} element={<Outlet />}>
                <Route path={`edit`} element={<ContributorEditPage />} />
                <Route path={'report'} element={<ContributorReportModule />} />
                <Route
                  path={`purchased`}
                  element={
                    <PurchasedMaterialsPage userType={ROLE_CONTRIBUTOR} />
                  }
                />
              </Route>
            </Route>

            {/* Moderator Routes */}
            <Route path={MODERATORS_PATH} element={<Outlet />}>
              <Route index element={<ModeratorsPage />} />
              <Route path={`new`} element={<ModeratorAddPage />} />
              <Route path={`:id`} element={<Outlet />}>
                <Route path={`edit`} element={<ModeratorEditPage />} />
              </Route>
            </Route>

            {/* Groups Routes */}
            <Route path={GROUP_CONTRIBUTORS_PATH} element={<Outlet />}>
              <Route index element={<ContributorGroupPage />} />
              <Route path={`new`} element={<ContributorGroupAddPage />} />
              <Route path={`:id`} element={<Outlet />}>
                <Route path={`edit`} element={<ContributorGroupEditPage />} />
                <Route
                  path={'report'}
                  element={<ContributorGroupReportModule />}
                />
              </Route>
            </Route>
            <Route path={GROUP_CONSUMERS_PATH} element={<Outlet />}>
              <Route index element={<ConsumerGroupPage />} />
              <Route path={`new`} element={<ConsumerGroupAddPage />} />
              <Route path={`:id`} element={<Outlet />}>
                <Route path={`edit`} element={<ConsumerGroupEditPage />} />
                <Route
                  path={'report'}
                  element={<ConsumerGroupReportModule />}
                />
              </Route>
            </Route>

            {/* Rejection Routes */}
            <Route path={REJECTIONS_PATH} element={<Outlet />}>
              <Route index element={<RejectionsPage />} />
              <Route path={`new`} element={<RejectionAddPage />} />
              <Route path={`:id`} element={<Outlet />}>
                <Route path={`edit`} element={<RejectionEditPage />} />
              </Route>
            </Route>

            {/* Categories Routes */}
            <Route path={CATEGORIES_PATH} element={<Outlet />}>
              <Route index element={<CategoriesPage />} />
              <Route path={`new`} element={<CaterogiesAddPage />} />
              <Route path={`:id`} element={<Outlet />}>
                <Route path={`edit`} element={<CategoriesEditPage />} />
              </Route>
            </Route>

            {/* Request Routes */}
            <Route path={REQUESTS_PATH} element={<Outlet />}>
              <Route index element={<RequestsPage />} />
              <Route path={`:id`} element={<RequestEditPage />} />
            </Route>

            {/* Withdraw Routes */}
            <Route path={WITHDRAW_PATH} element={<Outlet />}>
              <Route index element={<OrdersPage />} />
              <Route path={`:id`} element={<OrderEditPage />} />
            </Route>

            {/* Material Routes */}
            <Route path={MATERIALS_PATH} element={<Outlet />}>
              <Route index element={<MaterialsPage />} />
              <Route path={`:id`} element={<Outlet />}>
                <Route index element={<MaterialInfoPage />} />
                <Route path={`view`} element={<ImgFullScreen />} />
              </Route>
            </Route>

            {/* Moderation Routes */}
            <Route path={MODERATION_PATH} element={<Outlet />}>
              <Route index element={<ModerationPage />} />
              <Route path={':id'} element={<Outlet />}>
                <Route path={`deny`} element={<RejectDialog />} />
                <Route path={'view'} element={<ImgFullScreen />} />
              </Route>
            </Route>
            <Route
              path={MODERATION_HISTORY_PATH}
              element={<ModerationHistoryPage />}
            />

            {/* Search history Routes */}
            <Route path={SEARCH_HISTORY_PATH} element={<Outlet />}>
              <Route index element={<SearchHistoryPage />} />
            </Route>

            {/* News Routes */}
            <Route path={NEWS_PATH} element={<NewsPage />} />
          </Route>
          <Route element={<RequireSuperAdmin />}>
            <Route path={ADMINS_PATH} element={<Outlet />}>
              <Route index element={<AdminsPage />} />
              <Route path={`new`} element={<AdminAddPage />} />
              <Route path={`:id`} element={<Outlet />}>
                <Route path={`edit`} element={<AdminEditPage />} />
              </Route>
            </Route>
          </Route>
          <Route path={FORBIDDEN_PATH} element={<ForbiddenPage />} />
          <Route path={NOT_FOUND_PATH} element={<NotFoundPage />} />
        </Routes>
        <GetParameterPopup />
      </div>
    </div>
  ) : (
    <Routes>
      <Route path={SIGNIN_PATH} element={<AuthPage />} />
      <Route path='*' element={<Navigate to={SIGNIN_PATH} />} />
    </Routes>
  )
}
