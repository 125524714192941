import classNames from 'classnames'
import React from 'react'
import { EmptyComponent } from '../../components/EmptyComponent'
import { MaterialItem } from '../../components/MaterialItem'
import { MATERIALS_PATH } from '../../constants/globalConstants'
import { MaterialsListAPIType } from '../../types/materials'
import { getProtectedPathFromHash } from '../../utils/utils'
import './GalleryModule.sass'

interface GalleryModuleProps {
  className?: string
  materials: MaterialsListAPIType
}

export const GalleryModule: React.FC<GalleryModuleProps> = ({
  className,
  materials,
}) => {
  const galleryPrfx = 'gallery-module'
  const galleryCls = classNames(galleryPrfx, {
    [`${className}`]: className,
  })

  if (materials.length === 0) {
    return <EmptyComponent />
  }

  return (
    <div className={galleryCls}>
      {materials.map((material) => (
        <MaterialItem
          className={`${galleryPrfx}__item`}
          path={`${MATERIALS_PATH}/${material.id}`}
          key={material.id}
          imgSrc={getProtectedPathFromHash(material.protectedHash)}
          tags={material.tags}
          date={material.uploadDate}
          status={material.status}
          author={material.contributor?.name}
        />
      ))}
    </div>
  )
}
