import React from 'react'
import { Formik, Form } from 'formik'
import Select from 'react-select'
import * as Yup from 'yup'
import { phoneRe } from '../../../../utils/regulars'
import { Input, InputPassword } from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import { BsTrash } from 'react-icons/bs'
import { DatePickerCustom } from '../../../../components/DatePickerCustom'
import { getDateFromISO } from '../../../../utils/utils'
import { InputPhone } from '../../../../components/InputPhone'
import {
  ContributorAddFormType,
  ContributorAddType,
} from '../../../../types/contributor'
import { GroupListType } from '../../../../types/group'

interface ContributorAddModuleProps {
  className?: string
  handleSubmit: (values: ContributorAddType) => void
  handleCancel: () => void
  isLoading: boolean
  groups: GroupListType
  errorGroups: boolean
  loadingGroups: boolean
}

export const ContributorAddModule: React.FC<ContributorAddModuleProps> = ({
  className,
  handleSubmit,
  handleCancel,
  isLoading,
  groups,
  errorGroups,
  loadingGroups,
}) => {
  const initialValues = {
    login: '',
    name: '',
    password: '',
    email: '',
    phone: '',
    number: '',
    title: '',
    description: '',
    groupId: '0',
    startDate: new Date(),
  }
  const validationSchema = Yup.object({
    login: Yup.string().required('Обязательно'),
    name: Yup.string().required('Обязательно'),
    password: Yup.string().required('Обязательно'),
    email: Yup.string().email().required('Обязательно'),
    groupId: Yup.string(),
    phone: Yup.string()
      .matches(phoneRe, 'Неверный формат номера')
      .required('Обязательно'),
    number: Yup.string().required('Обязательно'),
    title: Yup.string().required('Обязательно'),
    description: Yup.string().required('Обязательно'),
    startDate: Yup.date().required('Обязательно').max(new Date()),
  })
  const onSubmit = async (values: ContributorAddFormType) => {
    const submitObject: ContributorAddType = {
      name: values.name,
      login: values.login,
      password: values.password,
      email: values.email,
      phone: values.phone
        ? values.phone
            .replace(/\)/g, '')
            .replace(/\(/g, '')
            .replace(/-/g, '')
            .replace(/ /g, '')
        : values.phone,
      ...(values.groupId !== '0' && { groupId: values.groupId }),
      contract: {
        title: values.title,
        number: values.number,
        description: values.description,
        startDate: getDateFromISO(values.startDate.toISOString()),
      },
    }
    handleSubmit(submitObject)
  }

  return (
    <div className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
      >
        {(formik) => (
          <Form className={'user-form user-form_double'}>
            <div className={`user-form-left`}>
              <h2 className={`user-form__title`}>Персональная информация</h2>
              <Input
                type='text'
                id='login'
                title='Логин'
                value={formik.values['login']}
                errors={formik.errors['login']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <Input
                type='text'
                id='name'
                title='Имя'
                value={formik.values['name']}
                errors={formik.errors['name']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <InputPassword
                type='password'
                id='password'
                title='Пароль'
                value={formik.values['password']}
                errors={formik.errors['password']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <Input
                type='email'
                id='email'
                title='Почта'
                value={formik.values['email']}
                errors={formik.errors['email']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <InputPhone
                id='phone'
                value={formik.values['phone']}
                errors={formik.errors['phone']}
                onChange={formik.handleChange}
                title='Номер телефона'
                className={`user-form__control`}
              />
              {!errorGroups && !loadingGroups && (
                <Select
                  className='react-select-container contributor-edit-form__control contributor-edit-form__control_margin-top'
                  classNamePrefix='react-select'
                  placeholder='Выберите группу'
                  name='groupId'
                  defaultValue={groups.find(
                    (group) => group.id === formik.values['groupId']
                  )}
                  onChange={(option: any) => {
                    formik.setFieldValue('groupId', option.id)
                  }}
                  getOptionLabel={(option) => option.groupName}
                  getOptionValue={(option) => option.id}
                  options={groups}
                />
              )}
            </div>
            <div className={`user-form-right`}>
              <h2 className={`user-form__title`}>Параметры контракта</h2>
              <Input
                type='text'
                id='number'
                title='Номер контракта'
                value={formik.values['number']}
                errors={formik.errors['number']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <Input
                type='text'
                id='title'
                title='Название контракта'
                value={formik.values['title']}
                errors={formik.errors['title']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <Input
                type='text'
                id='description'
                title='Описание'
                value={formik.values['description']}
                errors={formik.errors['description']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
              <DatePickerCustom
                name='startDate'
                title='Дата начала контракта'
                selected={formik.values['startDate']}
                errors={formik.errors['startDate']}
                onChange={(val) => formik.setFieldValue('startDate', val)}
                className={`user-form__control`}
              />
            </div>
            <div className={`user-form-btns`}>
              <Button
                htmlType='submit'
                disabled={!formik.isValid}
                className={`user-form-btns__item user-form__control`}
                loading={isLoading}
              >
                Сохранить изменения
              </Button>
              <Button
                htmlType='button'
                type='transparent'
                className={`user-form-btns__item user-form__control`}
                onClick={handleCancel}
              >
                Отмена{' '}
                <span>
                  <BsTrash />
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
