import { useQuery } from 'react-query'
import { REJECTIONS_CACHE_PATH } from '../../constants/globalConstants'
import { getListRejections } from './RejectionsPage-api'

export const useFetchRejections = (page: number) => {
  const query = useQuery(
    [REJECTIONS_CACHE_PATH, { page: page }],
    async () => await getListRejections(page),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}
