import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { CategoryRequestType } from '../CategoriesPage/CategoriesPage-types'
import { categoryAdd } from './CaterogiesAddPage-api'

export const useCreateCategory = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (values: CategoryRequestType) => categoryAdd(values),
    {
      onSuccess: async (data, variables, context) => {
        toast.info('Категория добавлена')
        navigate(-1)
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось добавить категорию')
      },
    }
  )

  return mutation
}
