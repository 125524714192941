import classNames from 'classnames'
import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import { SkeletonStatisticItem } from '../SkeletonStatisticItem'
import './SkeletonInfoModule.sass'

interface SkeletonInfoModuleProps {
  count: number
  className?: string
}

export const SkeletonInfoModule: React.FC<SkeletonInfoModuleProps> = ({
  count,
  className,
}) => {
  const skeletonPrfx = 'skeleton-info-module'
  const skeletonCls = classNames(skeletonPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={skeletonCls}>
      <SkeletonBase type='text' className='skeleton-info-module__title' />
      <div className='skeleton-info-module-body'>
        {[...Array(count)].map((e, i) => (
          <SkeletonStatisticItem
            className='skeleton-info-module-body__statistic-item'
            key={i}
          />
        ))}
      </div>
      <div className='skeleton-info-module-btns'>
        <SkeletonBase
          type='text'
          className='skeleton-info-module-btns__item'
        ></SkeletonBase>
        <SkeletonBase
          type='text'
          className='skeleton-info-module-btns__item'
        ></SkeletonBase>
      </div>
    </div>
  )
}
