import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { ReactComponent as SearchLogo } from '../../assets/img/search.svg'
import './FilterSearch.sass'
import { NavigateFunction } from 'react-router'
import { NAME_URL_VALUE, PAGE_URL_VALUE } from '../../constants/filterURLNames'

interface FilterSearchProps {
  className?: string
  navigate: NavigateFunction
  params: { [k: string]: string }
}

export const FilterSearch: React.FC<FilterSearchProps> = React.memo(
  ({ className, navigate, params }) => {
    const searchPrfx = 'search-container'
    const searchCls = classNames(searchPrfx, {
      [`${className}`]: className,
    })
    const name = params[NAME_URL_VALUE]

    const [searchValue, setSearchValue] = React.useState(name ?? '')

    React.useEffect(() => {
      setSearchValue(name?.toString() ?? '')
    }, [name])

    const handleSearchAccept = (e: React.FormEvent) => {
      e.preventDefault()
      if (searchValue) {
        params[NAME_URL_VALUE] = searchValue
        params[PAGE_URL_VALUE] = '1'
        navigate({ search: queryString.stringify(params) })
      } else {
        delete params[NAME_URL_VALUE]
        params[PAGE_URL_VALUE] = '1'
        navigate({ search: queryString.stringify(params) })
      }
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setSearchValue(value)
    }

    return (
      <form className={searchCls}>
        <Input
          id='date'
          type='text'
          placeholder='Введите логин пользователя'
          className={`${searchPrfx}__input`}
          value={searchValue}
          onChange={handleSearchChange}
        />
        <Button
          type='secondary'
          className={`${searchPrfx}__btn`}
          short
          onClick={handleSearchAccept}
        >
          <SearchLogo />
        </Button>
      </form>
    )
  }
)
