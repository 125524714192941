import { userTypeOptions } from './FilterConsumerType-constants'

export const getUserTypeOption = (value: string | string[] | null) => {
  if (!value) return userTypeOptions[0]
  let currentValue = '-1'
  typeof value === 'object' ? (currentValue = value[0]) : (currentValue = value)
  const userTypeOption = userTypeOptions.filter(
    (option) => option.value === Boolean(currentValue)
  )
  return userTypeOption[0] ?? userTypeOptions[0]
}
