import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { REQUESTS_CACHE_PATH } from '../../constants/globalConstants'
import { useNavigate } from 'react-router'
import { getIdRequest, requestAccept, requestDeny } from './RequestEditPage-api'
import { RequestAcceptValuesType } from './RequestEditPage-types'

export const useRequestById = (requestId: string) => {
  const query = useQuery(
    [REQUESTS_CACHE_PATH, { id: requestId }],
    async () => {
      const adminById = await getIdRequest(requestId)
      return adminById
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useRequestAccept = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (contractData: RequestAcceptValuesType) =>
      requestAccept(contractData.id, contractData.contract, contractData.limit),
    {
      onSuccess: async () => {
        navigate(-1)
        toast.info('Заявка принята')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось принять заявку')
      },
    }
  )

  return mutation
}

export const useRequestDeny = () => {
  const navigate = useNavigate()
  const mutation = useMutation((id: string) => requestDeny(id), {
    onSuccess: async () => {
      navigate(-1)
      toast.info('Заявка отклонена')
    },
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось принять заявку')
    },
  })

  return mutation
}
