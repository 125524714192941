import React from 'react'
import queryString from 'query-string'
import Select from 'react-select'
import classNames from 'classnames'
import { getUserStatusOption } from './FilterUserStatus-utils'
import { userStatusOptions } from './FilterUserStatus-constants'
import { NavigateFunction } from 'react-router'
import {
  PAGE_URL_VALUE,
  USER_STATUS_URL_VALUE,
} from '../../constants/filterURLNames'

interface FilterUserStatusProps {
  className?: string
  navigate: NavigateFunction
  params: { [k: string]: string }
}

export const FilterUserStatus: React.FC<FilterUserStatusProps> = React.memo(
  ({ className, navigate, params }) => {
    const filterPrfx = 'filter-param-container'
    const filterCls = classNames(filterPrfx, {
      [`${className}`]: className,
    })
    const status = params[USER_STATUS_URL_VALUE]

    const [statusFilter, setStatusFilter] = React.useState(
      getUserStatusOption(status)
    )

    React.useEffect(() => {
      setStatusFilter(getUserStatusOption(status))
    }, [status])

    const handleUserStatusChange = (option: any) => {
      setStatusFilter(getUserStatusOption(option.value))
      params[USER_STATUS_URL_VALUE] = option.value
      params[PAGE_URL_VALUE] = '1'
      navigate({ search: queryString.stringify(params) })
    }

    return (
      <div className={filterCls}>
        <label className={`${filterPrfx}__label`} htmlFor='userStatus'>
          Статус
        </label>
        <Select
          id='userStatus'
          className='react-select-container'
          classNamePrefix='react-select'
          getOptionValue={(option) => `${option.label}`}
          value={statusFilter}
          onChange={handleUserStatusChange}
          options={userStatusOptions}
        />
      </div>
    )
  }
)
