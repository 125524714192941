import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  ConsumerGroupAddFormType,
  ConsumerGroupEditType,
  ConsumerGroupWithUsersType,
} from "../../../../types/group";
import "./ConsumerGroupEditForm.sass";
import classNames from "classnames";
import { getModifiedValue } from "../../../../utils/check";
import { getDateFromISO } from "../../../../utils/utils";
import { FormTitle } from "../../../../components/FormTitle";
import { Input } from "../../../../components/Input";
import { WrapperDoubleLine } from "../../../../components/WrapperDoubleLine";
import { DatePickerCustom } from "../../../../components/DatePickerCustom";
import { Button } from "../../../../components/Button";

interface ConsumerGroupEditFormProps {
  className?: string;
  loadingChange: boolean;
  handleSubmit: (values: ConsumerGroupEditType) => void;
  groupData: ConsumerGroupWithUsersType;
}

export const ConsumerGroupEditForm: React.FC<ConsumerGroupEditFormProps> = ({
  className,
  loadingChange,
  handleSubmit,
  groupData,
}) => {
  const { groupName, contract } = groupData;
  const formPrfx = "consumer-edit-form";
  const formCls = classNames(formPrfx, {
    [`${className}`]: className,
  });

  const initialValues = {
    groupName: groupName,
    number: contract?.number ?? "",
    title: contract?.title ?? "",
    description: contract?.description ?? "",
    startDate: contract?.startDate ? new Date(contract.startDate) : new Date(),
    expiredDate: contract?.expiredDate
      ? new Date(contract.expiredDate)
      : new Date(),
  };

  const validationSchema = Yup.object({
    groupName: Yup.string().required("Обязательно"),

    number: Yup.string().required("Обязательно"),
    title: Yup.string().required("Обязательно"),
    description: Yup.string(),
    startDate: Yup.date().required("Обязательно").max(new Date()),
    expiredDate: Yup.date().required("Обязательно").min(new Date()),
  });
  const onSubmit = async (values: ConsumerGroupAddFormType) => {
    const modifiedValues: Partial<ConsumerGroupAddFormType> = getModifiedValue(
      values,
      initialValues
    );
    const modifiedContractValues = {
      contract: {
        ...(modifiedValues?.number && { number: modifiedValues.number }),
        ...(modifiedValues?.title && {
          title: modifiedValues.title,
        }),
        ...(modifiedValues?.description && {
          description: modifiedValues.description,
        }),
        ...(modifiedValues?.startDate && {
          startDate: getDateFromISO(modifiedValues.startDate.toISOString()),
        }),
        ...(modifiedValues?.expiredDate && {
          expiredDate: getDateFromISO(modifiedValues.expiredDate.toISOString()),
        }),
      },
    };
    const modifiedValuesForRequest = {
      ...(modifiedValues?.groupName && { name: modifiedValues.groupName }),
      ...modifiedContractValues,
    };

    handleSubmit(modifiedValuesForRequest);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={false}
    >
      {(formik) => (
        <>
          <Form className={formCls}>
            <FormTitle text={"Персональная информация"} />
            <Input
              type='text'
              id='groupName'
              title='Название группы'
              value={formik.values["groupName"]}
              errors={formik.errors["groupName"]}
              onChange={formik.handleChange}
              className={`consumer-edit-form__control`}
            />
            <FormTitle text={"Информация о контракте"} />
            <WrapperDoubleLine>
              <Input
                type='text'
                id='number'
                title='Номер'
                value={formik.values["number"]}
                errors={formik.errors["number"]}
                onChange={formik.handleChange}
                className={`consumer-edit-form__control`}
              />
              <Input
                type='text'
                id='title'
                title='Название'
                value={formik.values["title"]}
                errors={formik.errors["title"]}
                onChange={formik.handleChange}
                className={`consumer-edit-form__control`}
              />
            </WrapperDoubleLine>
            <Input
              type='text'
              id='description'
              title='Описание'
              value={formik.values["description"]}
              errors={formik.errors["description"]}
              onChange={formik.handleChange}
              className={`consumer-edit-form__control`}
            />
            <WrapperDoubleLine>
              <DatePickerCustom
                name='startDate'
                title='Дата начала контракта'
                selected={formik.values["startDate"]}
                errors={formik.errors["startDate"]}
                onChange={(val) => formik.setFieldValue("startDate", val)}
                className={`consumer-edit-form__control`}
              />
              <DatePickerCustom
                name='expiredDate'
                title='Дата конца контракта'
                selected={formik.values["expiredDate"]}
                errors={formik.errors["expiredDate"]}
                onChange={(val) => formik.setFieldValue("expiredDate", val)}
                className={`consumer-edit-form__control`}
              />
            </WrapperDoubleLine>
            <Button
              htmlType='submit'
              disabled={!formik.isValid}
              className={`consumer-edit-form__btn`}
              loading={loadingChange}
            >
              Сохранить изменения
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};
