import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { ADMINS_PATH } from '../../constants/globalConstants'
import { UserType } from '../../types/common'
import './UserRegisterInfo.sass'

interface UserRegisterInfoProps {
  className?: string
  registerBy: UserType
  registerDate: string
}

export const UserRegisterInfo: React.FC<UserRegisterInfoProps> = ({
  className,
  registerBy,
  registerDate,
}) => {
  const accountsPrfx = 'user-register-info'
  const divCls = classNames(accountsPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={divCls}>
      <div className='user-register-info-register'>
        <Link
          to={`${ADMINS_PATH}/${registerBy.id}/edit`}
          className='user-register-info-register__by'
        >
          <span className='user-register-info-register__by__text'>
            Зарегистрирован администратором:{' '}
            <span className='user-register-info-register__by__value'>
              {registerBy?.name ?? 'нет данных'}
            </span>
          </span>
        </Link>
        <div className='user-register-info-register__date'>
          <span className='user-register-info-register__date__text'>
            Дата регистрации:{' '}
            <span className='user-register-info-register__date__value'>
              {registerDate}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}
