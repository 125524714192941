import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { consumerAdd } from './ConsumerAddPage-api'
import { ConsumerAddType } from '../../types/consumer'

export const useCreateConsumer = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (values: ConsumerAddType) => consumerAdd(values),
    {
      onSuccess: async (data, variables, context) => {
        toast.info('Поставщик добавлен')
        navigate(-1)
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось добавить поставщика')
      },
    }
  )

  return mutation
}
