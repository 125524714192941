import { ROLE_CONSUMER, ROLE_CONTRIBUTOR } from '../../constants/common'
import { MATERIAL_API_PATH } from '../../constants/globalConstants'
import { ConsumerOrContributorRole } from '../../types/common'
import { instance } from '../../utils/coreAPI'
import { MATERIALS_SIZE } from './PurchasedMaterialsPage-constants'

export const getPurchasedListCall = async (
  id: string | undefined,
  page: number,
  interval: string | undefined,
  sort: string | undefined,
  purchaseInterval: string | undefined,
  userType: ConsumerOrContributorRole
) => {
  const config = {
    params: {
      page: page,
      size: MATERIALS_SIZE,
      interval,
      sort,
      purchaseInterval,
      ...(userType === ROLE_CONSUMER && { consumerId: id }),
      ...(userType === ROLE_CONTRIBUTOR && { contributorId: id }),
    },
  }
  try {
    const response = await instance.get(
      `${MATERIAL_API_PATH}/purchased`,
      config
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error?.response?.data.detail)
  }
}
