import classNames from 'classnames'
import React from 'react'
import { tagSearchCall } from '../../api/tagSearchCall'
import { InputAutoComplete } from '../InputAutoComplete'
import { TagComponent } from '../TagComponent'
import './InputTag.sass'

interface InputTagProps {
  className?: string
  tags: string[]
  handleSubmit: (value: string) => void
  handleCancel: (value: string) => void
}

export const InputTag: React.FC<InputTagProps> = ({
  className,
  tags,
  handleSubmit,
  handleCancel,
}) => {
  const containerPrfx = 'input-tag-container'
  const containerCls = classNames(containerPrfx, {
    [`${className}`]: className,
  })

  const [text, setText] = React.useState('')
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.currentTarget.value)
  }

  return (
    <div className={containerCls}>
      <div className={`${containerPrfx}-tags`}>
        {tags.map((tag, i) => (
          <TagComponent
            key={i}
            tag={tag}
            onCancel={handleCancel}
            className={`${containerPrfx}-tags__item`}
          />
        ))}
      </div>
      <InputAutoComplete
        className={`${containerPrfx}__input`}
        placeholder={'Начните вводить...'}
        value={text}
        onChange={handleChange}
        setValue={setText}
        callback={tagSearchCall}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}
