import classNames from 'classnames'
import React from 'react'
import { IoExitOutline } from 'react-icons/io5'
import { VscCircleFilled } from 'react-icons/vsc'
import { NavbarItem } from '../../components/NavbarItem'
import { ROLE_SUPERADMIN } from '../../constants/common'
import {
  ADMINS_PATH,
  CATEGORIES_PATH,
  CONSUMERS_PATH,
  CONTRIBUTORS_PATH,
  GROUP_CONSUMERS_PATH,
  GROUP_CONTRIBUTORS_PATH,
  MAIN_PATH,
  MATERIALS_PATH,
  MODERATION_HISTORY_PATH,
  MODERATION_PATH,
  MODERATORS_PATH,
  NEWS_PATH,
  REJECTIONS_PATH,
  REQUESTS_PATH,
  SEARCH_HISTORY_PATH,
  WITHDRAW_PATH,
} from '../../constants/globalConstants'
import { useLogout } from '../../pages/AuthPage/modules/AuthModule-hooks'
import { useFetchOrders } from '../../pages/OrdersPage/OrdersPage-hooks'
import {
  OrdersAPIAnswerType,
  OrderType,
} from '../../pages/OrdersPage/OrdersPage-types'
import { useFetchRequests } from '../../pages/RequestsPage/RequestsPage-hooks'
import {
  RequestsAPIAnswerType,
  RequestType,
} from '../../pages/RequestsPage/RequestsPage-types'
import { getPermissions } from '../../utils/storage'
import './MenuModule.sass'

interface MenuModuleProps {
  className?: string
  alignCenter?: boolean
}

export const MenuModule: React.FC<MenuModuleProps> = ({
  className,
  alignCenter = false,
}) => {
  const [isNewOrdersPayment, setIsNewOrdersPayment] = React.useState(false)
  const [isNewOrdersRegistration, setIsNewOrdersRegistration] =
    React.useState(false)

  const { mutate: logout } = useLogout() as {
    mutate: () => void
  }

  const menuPrfx = 'application-menu'
  const menuCls = classNames(menuPrfx, {
    [`${className}`]: className,
  })
  const permissions = getPermissions()

  const { data: requestsInfo } = useFetchRequests(1) as {
    data: RequestsAPIAnswerType
  }
  const { data: ordersInfo } = useFetchOrders(1) as {
    data: OrdersAPIAnswerType
  }
  React.useEffect(() => {
    if (
      requestsInfo?.items.some(
        (request: RequestType) => request.status === 'new'
      )
    ) {
      setIsNewOrdersRegistration(true)
    } else {
      setIsNewOrdersRegistration(false)
    }
  }, [requestsInfo])

  React.useEffect(() => {
    if (ordersInfo?.items.some((order: OrderType) => order.status === 'new')) {
      setIsNewOrdersPayment(true)
    } else {
      setIsNewOrdersPayment(false)
    }
  }, [ordersInfo])

  const handleExitClick = () => {
    logout()
  }

  return (
    <ul className={menuCls}>
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Главная страница'
        pathTo={`${MAIN_PATH}`}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Управление потребителями'
        pathTo={`${CONSUMERS_PATH}`}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Управление поставщиками'
        pathTo={`${CONTRIBUTORS_PATH}`}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Управление модераторами'
        pathTo={`${MODERATORS_PATH}`}
        icon={null}
      />
      {permissions === ROLE_SUPERADMIN && (
        <NavbarItem
          className={`${menuPrfx}__item`}
          alignCenter={alignCenter}
          text='Управление администраторами'
          pathTo={`${ADMINS_PATH}`}
          icon={null}
        />
      )}
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Группы поставщиков'
        pathTo={`${GROUP_CONTRIBUTORS_PATH}`}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Группы потребителей'
        pathTo={`${GROUP_CONSUMERS_PATH}`}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Управление материалами'
        pathTo={`${MATERIALS_PATH}`}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Управление заявками'
        pathTo={`${REQUESTS_PATH}`}
        icon={isNewOrdersRegistration ? VscCircleFilled : null}
        isNotificate
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Управление оплатой'
        pathTo={`${WITHDRAW_PATH}`}
        icon={isNewOrdersPayment ? VscCircleFilled : null}
        isNotificate
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Управление новостями'
        pathTo={`${NEWS_PATH}`}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Модерация'
        pathTo={`${MODERATION_PATH}`}
        icon={null}
      />
      <NavbarItem
        text='История модерации'
        pathTo={`${MODERATION_HISTORY_PATH}`}
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        icon={null}
      />
      <NavbarItem
        text='История поиска'
        pathTo={`${SEARCH_HISTORY_PATH}`}
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Причины отказов'
        pathTo={`${REJECTIONS_PATH}`}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Категории материалов'
        pathTo={`${CATEGORIES_PATH}`}
        icon={null}
      />
      <NavbarItem
        text='Выйти'
        pathTo={`/logout`}
        icon={IoExitOutline}
        handleClick={handleExitClick}
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
      />
    </ul>
  )
}
