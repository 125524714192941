import React from 'react'
import { BsCloud } from 'react-icons/bs'
import { useNavigate } from 'react-router'
import { Button } from '../../components/Button'
import { MAIN_PATH } from '../../constants/globalConstants'
import './NotFoundPage.sass'

export const NotFoundPage = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(MAIN_PATH)
  }
  return (
    <div className='not-found'>
      <div className='not-found-logo'>
        <BsCloud />
      </div>
      <h1 className='not-found__title'>Мы не смогли найти эту страницу :(</h1>
      <span className='not-found__text'>Но вы можете попробовать снова</span>
      <Button className='not-found__btn' type='primary' onClick={handleClick}>
        Вернуться на главную
      </Button>
    </div>
  )
}
