import React from 'react'
import './EmptyModeration.sass'

export const EmptyModeration = () => {
  return (
    <div className='empty-moderation'>
      <h1 className='empty-moderation__title'>
        На данный момент все материалы обработаны
      </h1>
      <h2 className='empty-moderation__sub'>Попробуйте зайти позже</h2>
    </div>
  )
}
