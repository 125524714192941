import classNames from 'classnames'
import React, { useEffect } from 'react'
import { IoClose } from 'react-icons/io5'
import { useOnClickOutside } from '../../utils/hooks'
import { AutocompleteOptions } from '../AutocompleteOptions/AutocompleteOptions'
import { Button } from '../Button'
import './InputAutoComplete.sass'

interface InputAutoCompleteProps {
  className?: string
  name?: string
  title?: string
  required?: boolean
  placeholder?: string
  callback: (props: any) => Promise<any>
  value: string
  setValue: (value: string) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  handleSubmit: (value: string) => void
  [x: string]: any
}

export const InputAutoComplete: React.FC<InputAutoCompleteProps> = ({
  className,
  name,
  title,
  required,
  placeholder,
  callback,
  value,
  setValue,
  onChange,
  handleSubmit,
  ...rest
}) => {
  const divRef = React.useRef<HTMLDivElement>(null)
  const controlWrapperPrefixCls = 'autocomplete-wrapper'
  const inputClassName = `${controlWrapperPrefixCls}__input`
  const optionClassName = `${controlWrapperPrefixCls}__options`
  const controlClassName = classNames(controlWrapperPrefixCls, {
    [`${className}`]: className,
  })

  const [optionsList, setOptionList] = React.useState<string[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)
  const [isOptionsVisible, setIsOptionsVisible] = React.useState<boolean>(false)

  const handleSetValue = (value: string) => {
    setValue(value)
  }

  const applyTagValue = (option?: string) => {
    setIsOptionsVisible(false)
    handleSubmit(option ? option : value)
    handleSetValue('')
  }

  const handleClickValue = (option: string) => {
    applyTagValue(option)
  }

  const handleHideOptions = () => {
    applyTagValue()
  }

  const handleButtonClick = () => {
    handleSetValue('')
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      applyTagValue()
    }
  }

  useEffect(() => {
    value && setIsOptionsVisible(true)
  }, [value])

  useOnClickOutside(divRef, handleHideOptions)

  React.useEffect(() => {
    let isSubscribe = true
    const handler = setTimeout(async () => {
      try {
        setIsError(false)
        setIsLoading(true)
        const values = await callback(value)
        if (isSubscribe) {
          setOptionList(values)
        }
      } catch {
        setIsError(true)
      }
      setIsLoading(false)
    }, 500)
    return () => {
      isSubscribe = false
      clearTimeout(handler)
    }
  }, [value, callback])

  return (
    <div className={controlClassName} ref={divRef} onKeyDown={handleKeyPress}>
      {title && <label htmlFor={name}>{title}</label>}
      <input
        type='text'
        id={name}
        className={inputClassName}
        value={value}
        onBlur={() => {}}
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
      {value && (
        <Button
          className={`${controlWrapperPrefixCls}__remove`}
          type='transparent'
          short
          onClick={handleButtonClick}
        >
          <IoClose />
        </Button>
      )}
      {isOptionsVisible && !isError && (
        <AutocompleteOptions
          className={optionClassName}
          options={optionsList}
          isLoading={isLoading}
          setValue={handleClickValue}
        />
      )}
    </div>
  )
}
