import React from "react";
import { StatisticModule } from "../../modules/StatisticModule";
import { useBreadcrumbs } from "../../utils/hooks";
import { breadCrumbsData } from "./AdminsPage-constants";
import { Administrators } from "./modules/Administrators";

export const AdminsPage = () => {
  useBreadcrumbs(breadCrumbsData);
  return (
    <div className='common-page admins'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <Administrators />
      </div>
    </div>
  );
};
