import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonUsersList.sass'

export const SkeletonUsersList = () => {
  return (
    <div className='skeleton-users-list'>
      <SkeletonBase type='text' className='skeleton-users-list__item' />
      <SkeletonBase type='text' className='skeleton-users-list__item' />
      <SkeletonBase type='text' className='skeleton-users-list__item' />
      <SkeletonBase type='text' className='skeleton-users-list__item' />
    </div>
  )
}
