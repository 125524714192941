import { isAuthenticated } from '../../../utils/auth'
import { getPermissions, getToken } from '../../../utils/storage'
import { AUTH_DATA_SET, AUTH_DATA_RESET } from './AuthModule-constants'
import { AuthActions, AuthState } from './AuthModule-types'

const initialState: AuthState = {
  token: getToken(),
  isAuth: isAuthenticated(),
  permissions: getPermissions(),
}

export const AuthReducer = (
  state = initialState,
  action: AuthActions
): AuthState => {
  switch (action.type) {
    case AUTH_DATA_SET: {
      return {
        ...state,
        token: action.payload.token,
        permissions: action.payload.permissions,
        isAuth: true,
      }
    }
    case AUTH_DATA_RESET:
      return {
        ...state,
        token: '',
        isAuth: false,
        permissions: '',
      }
    default:
      return state
  }
}
