import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import Select from 'react-select'
import { getUserTypeOption } from './FilterConsumerType-utils'
import { userTypeOptions } from './FilterConsumerType-constants'
import { NavigateFunction } from 'react-router'
import {
  CONSUMER_USER_URL_TYPE,
  PAGE_URL_VALUE,
} from '../../constants/filterURLNames'

interface FilterConsumerTypeProps {
  className?: string
  navigate: NavigateFunction
  params: { [k: string]: string }
}

export const FilterConsumerType: React.FC<FilterConsumerTypeProps> = React.memo(
  ({ className, navigate, params }) => {
    const filterPrfx = 'filter-param-container'
    const filterCls = classNames(filterPrfx, {
      [`${className}`]: className,
    })
    const type = params[CONSUMER_USER_URL_TYPE]

    const [userTypeFilter, setUserTypeFilter] = React.useState(
      getUserTypeOption(type)
    )

    React.useEffect(() => {
      setUserTypeFilter(getUserTypeOption(type))
    }, [type])

    const handleUserTypeChange = (option: any) => {
      setUserTypeFilter(getUserTypeOption(option.value))
      params[CONSUMER_USER_URL_TYPE] = option.value
      params[PAGE_URL_VALUE] = '1'
      navigate({ search: queryString.stringify(params) })
    }

    return (
      <div className={filterCls}>
        <label className={`${filterPrfx}__label`} htmlFor='userType'>
          Тип пользователя
        </label>
        <Select
          id='userType'
          className='react-select-container'
          classNamePrefix='react-select'
          getOptionValue={(option) => `${option.label}`}
          value={userTypeFilter}
          onChange={handleUserTypeChange}
          options={userTypeOptions}
        />
      </div>
    )
  }
)
