import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { ROLE_CONTRIBUTOR } from '../../constants/common'
import {
  CONTRIBUTORS_CACHE_PATH,
  REPORT_CACHE_PATH,
} from '../../constants/globalConstants'
import { ContributorEditType } from '../../types/contributor'
import {
  contributorChange,
  contributorChangeStatus,
  contributorRemove,
  getContributorReports,
  getIdContributor,
  getIdContributorStatistics,
} from './ContributorEditPage-api'

export const useContributorById = (contributorId: string) => {
  const query = useQuery(
    [CONTRIBUTORS_CACHE_PATH, { id: contributorId, type: 'data' }],
    async () => {
      const contributorById = await getIdContributor(contributorId)
      return contributorById
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useContributorStatistics = (contributorId: string) => {
  const query = useQuery(
    [CONTRIBUTORS_CACHE_PATH, { id: contributorId, type: 'stat' }],
    async () => {
      const statisticsByModeratorId = await getIdContributorStatistics(
        contributorId
      )
      return statisticsByModeratorId
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useContributorReports = (contributorId: string, page: number) => {
  const query = useQuery(
    [
      REPORT_CACHE_PATH,
      { id: contributorId, page: page, type: ROLE_CONTRIBUTOR },
    ],
    async () => {
      const contributorReports = await getContributorReports(
        contributorId,
        page
      )
      return contributorReports
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useUpdateContributor = (id: string) => {
  const client = useQueryClient()
  const mutation = useMutation(
    (values: ContributorEditType) => contributorChange(id, { ...values }),
    {
      onSuccess: async (data, variables, context) => {
        await client.invalidateQueries([
          CONTRIBUTORS_CACHE_PATH,
          { id: `${id}`, type: 'data' },
        ])
        toast.info('Данные успешно обновлены')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось обновить данные')
      },
    }
  )

  return mutation
}

export const useRemoveContributor = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (contributorId: string) => contributorRemove(contributorId),
    {
      onSuccess: async (data, variables, context) => {
        navigate(-1)
        toast.info('Поставщик удален')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось удалить поставщика')
      },
    }
  )
  return mutation
}

export const useChangeStatusContributor = () => {
  const client = useQueryClient()
  const mutation = useMutation(
    (contributorId: string) => contributorChangeStatus(contributorId),
    {
      onSuccess: async (data, contributorId, context) => {
        await client.invalidateQueries([
          CONTRIBUTORS_CACHE_PATH,
          { id: `${contributorId}`, type: 'data' },
        ])
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось сменить статус')
      },
    }
  )
  return mutation
}
