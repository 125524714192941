import { useQuery } from 'react-query'
import { getAllRejectionsCall } from '../api/allRejectionsCall'
import { REJECTIONS_CACHE_PATH } from '../constants/globalConstants'

export const useRejectionsAll = () => {
  const query = useQuery(
    REJECTIONS_CACHE_PATH,
    async () => await getAllRejectionsCall(),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
    }
  )
  return query
}
