import { useQuery } from 'react-query'
import { MATERIALS_CACHE_PATH } from '../../constants/globalConstants'
import { ConsumerOrContributorRole } from '../../types/common'
import { getPurchasedListCall } from './PurchasedMaterialsPage-api'

export const useFetchPurchasedMaterials = (
  id: string | undefined,
  page: number,
  interval: string | undefined = undefined,
  sort: string | undefined = undefined,
  purchasedInterval: string | undefined = undefined,
  userType: ConsumerOrContributorRole
) => {
  const query = useQuery(
    [
      MATERIALS_CACHE_PATH,
      { id, page, interval, sort, purchasedInterval, userType },
    ],
    async () =>
      await getPurchasedListCall(
        id,
        page,
        interval,
        sort,
        purchasedInterval,
        userType
      ),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
      enabled: !!id,
    }
  )
  return query
}
