import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonMain.sass'

export const SkeletonMain = () => {
  return (
    <div className='skeleton-main'>
      <div className='skeleton-main-legend'>
        <SkeletonBase type='text' className='skeleton-main-legend__item' />
        <SkeletonBase type='text' className='skeleton-main-legend__item' />
      </div>
      <SkeletonBase type='text' className='skeleton-main__vertical' />
      <SkeletonBase type='text' className='skeleton-main__horizontal' />
    </div>
  )
}
