import { confirmable, createConfirmation } from 'react-confirm'
import { ConfirmDialog } from '../components/ConfirmDialog'

export const confirmDeleteDialog = (
  confirmation: string,
  title: string = 'Вы уверены?',
  proceedLabel: string = 'Да',
  cancelLabel: string = 'Отмена',
  options = {}
) => {
  return createConfirmation(confirmable(ConfirmDialog))({
    confirmation,
    title,
    proceedLabel,
    cancelLabel,
    ...options,
  })
}
