import classNames from 'classnames'
import React from 'react'
import './TagComponent.sass'

interface TagComponentProps {
  className?: string
  tag: string
  onCancel: (value: string) => void
}

export const TagComponent: React.FC<TagComponentProps> = ({
  className,
  tag,
  onCancel,
}) => {
  const tagPrfx = 'tag-container'
  const tagCls = classNames(tagPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={tagCls} onClick={() => onCancel(tag)}>
      <span className={`${tagPrfx}__text`}>{tag}</span>
      <span className={`${tagPrfx}__cancel`}>x</span>
    </div>
  )
}
