import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import { SkeletonNewsComponent } from '../SkeletonNewsComponent'
import './SkeletonNews.sass'

export const SkeletonNews = () => {
  return (
    <div className='skeleton-news'>
      <SkeletonBase type='text' className='skeleton-news__title' />
      <SkeletonNewsComponent className='skeleton-news__control' />
      <SkeletonNewsComponent className='skeleton-news__control' />
      <SkeletonNewsComponent className='skeleton-news__control' />
      <SkeletonNewsComponent className='skeleton-news__control' />
      <SkeletonNewsComponent className='skeleton-news__control' />
      <div className='skeleton-news-btns'>
        <SkeletonBase type='text' className='skeleton-news-btns__item' />
        <SkeletonBase type='text' className='skeleton-news-btns__item' />
      </div>
    </div>
  )
}
