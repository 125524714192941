import React from 'react'
import { Portal } from '../Portal'
import './Modal.sass'

interface ModalProps {
  show: boolean
  onHide: () => void
  children: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({ show, onHide, children }) => {
  const handleEscClick = React.useCallback(
    (e: any) => {
      if ((e.charCode || e.keyCode) === 27) {
        onHide()
      }
    },
    [onHide]
  )
  React.useEffect(() => {
    document.body.addEventListener('keydown', handleEscClick)
    return function cleanup() {
      document.body.removeEventListener('keydown', handleEscClick)
    }
  }, [handleEscClick])

  React.useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [show])

  if (!show) {
    return null
  }

  return (
    <Portal>
      <div className='modal' role='dialog'>
        <div className='overlay' role='button' tabIndex={0} onClick={onHide} />
        <div className='modal__content'>{children}</div>
      </div>
    </Portal>
  )
}
