import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import { SkeletonSnatchNews } from '../SkeletonSnatchNews'
import './SkeletonSnatchForm.sass'

export const SkeletonSnatchForm = () => {
  return (
    <div className='skeleton-snatch-form'>
      <SkeletonBase type='text' className='skeleton-snatch-form__title' />
      <SkeletonSnatchNews className='skeleton-snatch-form__news' />
      <SkeletonSnatchNews className='skeleton-snatch-form__news' />
      <SkeletonSnatchNews className='skeleton-snatch-form__news' />
      <SkeletonSnatchNews className='skeleton-snatch-form__news' />
      <SkeletonSnatchNews className='skeleton-snatch-form__news' />
    </div>
  )
}
