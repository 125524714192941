import React from 'react'
import { Formik, Form } from 'formik'
import Select from 'react-select'
import * as Yup from 'yup'
import {
  ReportRequestFieldsType,
  ReportResponseFieldsType,
} from './ReportModule-types'
import { useReportGenerate } from './ReportModule-hooks'
import { Button } from '../../../../components/Button'
import { DatePickerCustom } from '../../../../components/DatePickerCustom'
import { getDateFromISO, getPrevMonthDate } from '../../../../utils/utils'
import './ReportModule.sass'
import { Popup } from '../../../../components/Popup'
import { useParams } from 'react-router'
import { REPORT_ACTION_ENUM, REPORT_RIGHTS_ENUM } from '../../../../types/enums'

export const ReportModule = () => {
  const { id } = useParams()
  const { mutateAsync: reportGenerate } = useReportGenerate(id!) as {
    mutateAsync: (reportValues: ReportRequestFieldsType) => Promise<any>
    isLoading: boolean
    isError: boolean
  }

  const selectActionOptions = [
    {
      label: 'Загруженные',
      value: REPORT_ACTION_ENUM.UPLOADED,
    },
    {
      label: 'Купленные',
      value: REPORT_ACTION_ENUM.PURCASED,
    },
  ]
  const selectRightsOptions = [
    {
      label: 'Эксклюзивные',
      value: REPORT_RIGHTS_ENUM.EXCLUSIVE,
    },
    {
      label: 'Не эксклюзивные',
      value: REPORT_RIGHTS_ENUM.NOT_EXCLUSIVE,
    },
  ]

  const initialValues = {
    rights: selectRightsOptions[1],
    action: selectActionOptions[1],
    startDate: getPrevMonthDate(),
    endDate: new Date(),
  }
  const validationSchema = Yup.object({
    rights: Yup.object().required('Обязательно'),
    action: Yup.object().required('Обязательно'),
    startDate: Yup.date().required('Обязательно').max(new Date()),
    endDate: Yup.date().required('Обязательно').max(new Date()),
  })
  const onSubmit = async (values: ReportResponseFieldsType) => {
    await reportGenerate({
      action: values.action.value,
      rights: values.rights.value,
      startDate: getDateFromISO(values.startDate.toISOString()),
      endDate: getDateFromISO(values.endDate.toISOString()),
    })
  }

  return (
    <Popup>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
      >
        {(formik) => (
          <Form className={'report-form'}>
            <div className={`report-form-body`}>
              <DatePickerCustom
                name='startDate'
                title='Дата начала отчета'
                selected={formik.values['startDate']}
                errors={formik.errors['startDate']}
                onChange={(val) => formik.setFieldValue('startDate', val)}
                className={`report-form-body__control`}
              />
              <DatePickerCustom
                name='endDate'
                title='Дата конца отчета'
                selected={formik.values['endDate']}
                errors={formik.errors['endDate']}
                onChange={(val) => formik.setFieldValue('endDate', val)}
                className={`report-form-body__control`}
              />
              <Select
                id='action'
                name='action'
                className='report-form-body__control react-select-container'
                classNamePrefix='react-select'
                options={selectActionOptions}
                value={formik.values['action']}
                onChange={(val) => formik.setFieldValue('action', val)}
              />
              <Select
                id='rights'
                name='rights'
                className='report-form-body__control react-select-container'
                classNamePrefix='react-select'
                options={selectRightsOptions}
                value={formik.values['rights']}
                onChange={(val) => formik.setFieldValue('rights', val)}
              />
            </div>
            <div className={`report-form-btns`}>
              <Button
                htmlType='submit'
                disabled={!formik.isValid}
                className={`report-form-btns__item report-form__control`}
              >
                Создать
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Popup>
  )
}
