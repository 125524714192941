import React from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router'
import Select from 'react-select'
import { useRejectionsAll } from '../../hooks/getAllRejections'
import { useModerateDenyMaterial } from '../../hooks/useModerateDeny'
import { ModerateItemRequest } from '../../types/moderate'
import { Button } from '../Button'
import { Popup } from '../Popup'
import { Textarea } from '../Textarea'
import { Loader } from '../Loader'
import './RejectDialog.sass'
import { RejectionsType } from '../../types/common'

type OptionsRejection = {
  id: string
  rejection: string
  label: string
}

export const RejectDialog = () => {
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  const rejectPrfx = 'rejection-dialog'
  const rejectCls = rejectPrfx
  const [selectValue, setSelectValue] = React.useState<OptionsRejection>()
  const [description, setDescription] = React.useState('')

  const { data: rejectionList, isLoading: isRejectionsLoading } =
    useRejectionsAll() as {
      data: RejectionsType
      isLoading: boolean
      isFetchedAfterMount: boolean
    }

  const { mutateAsync: moderateDenied, isLoading: loadingDenied } =
    useModerateDenyMaterial(id!) as {
      mutateAsync: (values: ModerateItemRequest) => Promise<any>
      isLoading: boolean
    }

  if (isRejectionsLoading || loadingDenied) {
    return <Loader />
  }

  const options = rejectionList.map((rejection) => ({
    id: rejection.id,
    value: rejection.rejection,
    label: rejection.rejection,
  }))

  const handleChangeSelect = (item: any) => {
    setSelectValue(item)
  }
  const handleChangeDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value)
  }

  return (
    <Popup>
      <div className={rejectCls}>
        <h2 className={`${rejectPrfx}__title`}>Выберите причину отказа</h2>
        <Select
          className='react-select-container'
          classNamePrefix='react-select'
          placeholder='Выбирите причину отказа'
          onChange={handleChangeSelect}
          options={options}
        />
        <h2 className={`${rejectPrfx}__title`}>
          Дополнительная информация по отказу
        </h2>
        <Textarea
          className={`${rejectPrfx}__description`}
          value={description}
          onChange={handleChangeDescription}
        />
        {rejectionList.length < 1 && (
          <span className={`${rejectPrfx}__warning`}>
            Нет причин отказа. Обратитесь к администратору
          </span>
        )}
        <Button
          className={`${rejectPrfx}__btn`}
          type='danger'
          onClick={() =>
            moderateDenied({
              id: id!,
              rejectionId: selectValue!.id,
              description,
            })
          }
          disabled={!selectValue}
        >
          Отклонить
        </Button>
        <Button
          className={`${rejectPrfx}__btn`}
          type='secondary'
          border
          onClick={() => navigate(-1)}
        >
          Отмена
        </Button>
      </div>
    </Popup>
  )
}
