import { instance } from '../../utils/coreAPI'
import { CATEGORY_API_PATH } from '../../constants/globalConstants'
import { CategoryRequestType } from '../CategoriesPage/CategoriesPage-types'

export const categoryAdd = async (data: CategoryRequestType) => {
  try {
    const response = await instance.post(CATEGORY_API_PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
