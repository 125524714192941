import { useQuery } from 'react-query'
import { GROUP_CONTRIBUTORS_CACHE_PATH } from '../../constants/globalConstants'
import { getListContributorGroups } from './ContributorGroupPage-api'

export const useFetchContributorGroups = (page: number) => {
  const query = useQuery(
    [GROUP_CONTRIBUTORS_CACHE_PATH, { page: page }],
    async () => await getListContributorGroups(page),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}
