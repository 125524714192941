import { MAIN_PATH, SEARCH_HISTORY_PATH } from '../../constants/globalConstants'

export const SEARCH_HISTORY_SIZE = 12

export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'История поиска',
    path: SEARCH_HISTORY_PATH,
  },
]
