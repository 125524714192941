import React from 'react'
import { useNavigate } from 'react-router'
import { StatisticModule } from '../../modules/StatisticModule'
import { UserAddForm } from '../../modules/UserAddForm'
import { UserFormType } from '../../types/common'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './ModeratorAddPage-constants'
import { useCreateModerator } from './ModeratorAddPage-hooks'

export const ModeratorAddPage = () => {
  useBreadcrumbs(breadCrumbsData)
  const navigate = useNavigate()

  const { mutateAsync: createModerator, isLoading: loadingCreate } =
    useCreateModerator() as {
      mutateAsync: (adminData: UserFormType) => Promise<any>
      isLoading: boolean
    }

  const handleSubmit = (userData: UserFormType) => {
    createModerator(userData)
  }
  const handleCancel = () => {
    navigate(-1)
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Создание нового модератора'} />
      </div>
      <div className='common-page-body'>
        <UserAddForm
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isLoading={loadingCreate}
        />
      </div>
    </div>
  )
}
