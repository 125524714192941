import classNames from "classnames";
import InputMask from "react-input-mask";
import React from "react";
import "./InputPhone.sass";

interface InputPhoneProps {
  className?: string;
  title?: string;
  value: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: string;
  placeholder?: string;
  id?: string;
  [x: string]: any;
}

export const InputPhone: React.FC<InputPhoneProps> = ({
  className,
  title,
  value,
  onChange,
  errors,
  placeholder,
  id,
  ...rest
}) => {
  const controlWrapperPrefixCls = "phone-wrapper";
  const inputClassName = classNames(`${controlWrapperPrefixCls}__input`, {
    [`${controlWrapperPrefixCls}__input_error`]: !!errors,
  });
  const controlClassName = classNames(controlWrapperPrefixCls, {
    [`${className}`]: className,
  });
  return (
    <div className={controlClassName}>
      {title && <label htmlFor={id}>{title}</label>}

      <InputMask
        id={id}
        value={value}
        onChange={onChange}
        mask='+7 (999) 999-99-99'
        alwaysShowMask
        className={inputClassName}
        type='tel'
        {...rest}
      />
    </div>
  );
};
