import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { RejectionRequestType } from '../../../RejectionsPage/RejectionsPage-types'
import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'

interface RejectionChangeFormProps {
  className?: string
  id: string
  rejection: string
  handleSubmit: (categoryData: RejectionRequestType) => void
  handleDelete: (id: string) => void
  loadingChange: boolean
  loadingDelete: boolean
}

export const RejectionChangeForm: React.FC<RejectionChangeFormProps> = ({
  id,
  rejection,
  handleSubmit,
  handleDelete,
  loadingChange,
  loadingDelete,
}) => {
  const initialValues = {
    rejection: rejection,
  }
  const validationSchema = Yup.object({
    rejection: Yup.string().required('Обязательно'),
  })
  const onSubmit = async (values: RejectionRequestType) => {
    handleSubmit({
      rejection: values.rejection,
    })
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={false}
    >
      {(formik) => (
        <Form className={`user-form`}>
          <div className='user-form-left'>
            <h2 className={`user-form__title`}>Информация о причине отказа</h2>
            <Input
              type='text'
              id='rejection'
              title='Причина'
              value={formik.values['rejection']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
          </div>

          <div className={`user-form-btns`}>
            <Button
              htmlType='submit'
              disabled={!formik.isValid}
              loading={loadingChange}
              className={`user-form-btns__item user-form-btns__item`}
            >
              Сохранить изменения
            </Button>
            <Button
              htmlType='button'
              type='danger'
              className={`user-form-btns__item user-form-btns__item`}
              onClick={() => handleDelete(id)}
              loading={loadingDelete}
            >
              Удалить
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
