import { ROLE_ADMIN, ROLE_SUPERADMIN } from '../constants/common'
import {
  USER_PERMISSIONS_STORAGE_KEY,
  USER_TOKEN_STORAGE_KEY,
} from '../constants/storage'

export const isAuthenticated = () => {
  const permissions = localStorage.getItem(USER_PERMISSIONS_STORAGE_KEY)
  const token = localStorage.getItem(USER_TOKEN_STORAGE_KEY)
  if (!permissions && !token) {
    return false
  }
  const isAuth = permissions === ROLE_SUPERADMIN || permissions === ROLE_ADMIN
  if (isAuth) {
    return true
  } else {
    return false
  }
}
