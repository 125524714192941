import React from 'react'
import { IncorretResult } from '../../../../components/IncorretResult'
import { SkeletonForm } from '../../../../components/SkeletonForm'
import {
  ContributorGroupWithUsersType,
  ContributorGroupEditType,
} from '../../../../types/group'
import { isObjectEmpty } from '../../../../utils/check'
import { useUpdateContributorGroup } from '../../ContributorGroupEditPage-hooks'
import { ContributorGroupEditForm } from '../ContributorGroupEditForm'
import './ContributorGroupEditModule.sass'

interface ContributorGroupEditModuleProps {
  className?: string
  id: string
  isLoading: boolean
  isError: boolean
  groupData: ContributorGroupWithUsersType
}

export const ContributorGroupEditModule: React.FC<ContributorGroupEditModuleProps> =
  React.memo(({ id, isLoading, isError, groupData }) => {
    const { mutateAsync: updateContributor, isLoading: loadingChange } =
      useUpdateContributorGroup(id) as {
        mutateAsync: (values: ContributorGroupEditType) => Promise<any>
        isLoading: boolean
      }

    const handleSubmit = async (userData: ContributorGroupEditType) => {
      await updateContributor({ ...userData })
    }

    if (isLoading) {
      return <SkeletonForm count={5} />
    }
    if (isObjectEmpty(groupData) || isError) {
      return <IncorretResult />
    }

    return (
      <ContributorGroupEditForm
        groupData={groupData}
        loadingChange={loadingChange}
        handleSubmit={handleSubmit}
      />
    )
  })
