import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { WITHDRAW_CACHE_PATH } from '../../constants/globalConstants'
import { useNavigate } from 'react-router'
import {
  getIdPaymentOrder,
  paymentOrderAccept,
  paymentOrderDeny,
} from './OrderEditPage-api'
import {
  PaymentOrderAcceptValuesType,
  PaymentOrderDeniedValuesType,
} from './OrderEditPage-types'

export const usePaymentOrderById = (requestId: string) => {
  const query = useQuery(
    [WITHDRAW_CACHE_PATH, { id: requestId }],
    async () => {
      const adminById = await getIdPaymentOrder(requestId)
      return adminById
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const usePaymentOrderAccept = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (orderData: PaymentOrderAcceptValuesType) =>
      paymentOrderAccept(orderData.id, orderData.description),
    {
      onSuccess: async () => {
        navigate(-1)
        toast.info('Заявка принята')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось принять заявку')
      },
    }
  )

  return mutation
}

export const usePaymentOrderDeny = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (orderData: PaymentOrderDeniedValuesType) =>
      paymentOrderDeny(orderData.id, orderData.description),
    {
      onSuccess: async () => {
        navigate(-1)
        toast.info('Заявка отклонена')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось принять заявку')
      },
    }
  )

  return mutation
}
