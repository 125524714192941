import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonMaterialDescription.sass'

export const SkeletonMaterialDescription = () => {
  return (
    <div className='skeleton-material-description'>
      <div className='skeleton-material-description-properties'>
        <div className='skeleton-material-description-properties-item'>
          <SkeletonBase
            type='title'
            className='skeleton-material-description-properties-item__title'
          />
          <SkeletonBase
            type='title'
            className='skeleton-material-description-properties-item__value'
          />
        </div>
        <div className='skeleton-material-description-properties-item'>
          <SkeletonBase
            type='title'
            className='skeleton-material-description-properties-item__title'
          />
          <SkeletonBase
            type='title'
            className='skeleton-material-description-properties-item__value'
          />
        </div>
        <div className='skeleton-material-description-properties-item'>
          <SkeletonBase
            type='title'
            className='skeleton-material-description-properties-item__title'
          />
          <SkeletonBase
            type='title'
            className='skeleton-material-description-properties-item__value'
          />
        </div>
        <div className='skeleton-material-description-properties-item'>
          <SkeletonBase
            type='title'
            className='skeleton-material-description-properties-item__title'
          />
          <SkeletonBase
            type='title'
            className='skeleton-material-description-properties-item__value'
          />
        </div>
        <div className='skeleton-material-description-properties-item'>
          <SkeletonBase
            type='title'
            className='skeleton-material-description-properties-item__title'
          />
          <SkeletonBase
            type='title'
            className='skeleton-material-description-properties-item__value'
          />
        </div>
      </div>
      <div className='skeleton-material-description-photo'>
        <SkeletonBase
          type='thumbnail'
          className='skeleton-material-description-photo__img'
        />
        <SkeletonBase
          type='thumbnail'
          className='skeleton-material-description-photo__btn'
        />
        <SkeletonBase
          type='thumbnail'
          className='skeleton-material-description-photo__btn'
        />
      </div>
    </div>
  )
}
