import classNames from 'classnames'
import React from 'react'
import { BsCheck, BsTrash, BsX } from 'react-icons/bs'
import { Button } from '../../../../components/Button'
import { IncorretResult } from '../../../../components/IncorretResult'
import { SkeletonInfoModule } from '../../../../components/SkeletonInfoModule'
import { StatisticItem } from '../../../../components/StatisticItem'
import { ModeratorStatisticAPIAnswerType } from '../../../../types/moderator'
import { useModeratorStatistics } from '../../ModeratorEditPage-hooks'

interface InfoModuleProps {
  className?: string
  id: string
  status: string
  onDelete: (id: string) => void
  onChangeStatus: (id: string) => void
  loadingDelete: boolean
  loadingChangeStatus: boolean
}

export const InfoModule: React.FC<InfoModuleProps> = ({
  className,
  id,
  status,
  onDelete,
  onChangeStatus,
  loadingDelete,
  loadingChangeStatus,
}) => {
  const modulePrfx = 'module-info'
  const divCls = classNames(modulePrfx, {
    [`${className}`]: className,
  })

  const {
    data: statisticData,
    isLoading,
    isError,
  } = useModeratorStatistics(id) as {
    data: ModeratorStatisticAPIAnswerType
    isLoading: boolean
    isError: boolean
  }

  if (isLoading) {
    return (
      <div className={divCls}>
        <SkeletonInfoModule count={6} />
      </div>
    )
  }

  if (isError) {
    return <IncorretResult />
  }

  const getButton = () => {
    if (status === 'active') {
      return (
        <Button
          className={`${modulePrfx}-btns__item ${modulePrfx}-btns__item_change-status`}
          type='transparent'
          onClick={() => onChangeStatus(id)}
          loading={loadingChangeStatus}
        >
          Отключить аккаунт
          <span>
            <BsX />
          </span>
        </Button>
      )
    } else if (status === 'disabled' || 'locked')
      return (
        <Button
          className={`${modulePrfx}-btns__item ${modulePrfx}-btns__item_change-status ${modulePrfx}-btns__item_success`}
          type='transparent'
          onClick={() => onChangeStatus(id)}
          loading={loadingChangeStatus}
        >
          Включить аккаунт
          <span>
            <BsCheck />
          </span>
        </Button>
      )
  }

  const moderatedMonth = `${statisticData?.monthly?.totalModerated}`
  const moderatedTotal = `${statisticData?.total?.totalModerated ?? '-'}`

  const acceptMonth = `${statisticData?.monthly?.acceptModerated}`
  const acceptTotal = `${statisticData?.total?.acceptModerated ?? '-'}`

  const deniedMonth = `${statisticData?.monthly?.deniedModerated}`
  const deniedTotal = `${statisticData?.total?.deniedModerated ?? '-'}`

  return (
    <div className={divCls}>
      <h2 className={`${modulePrfx}__title`}>Статистика модератора</h2>
      <div className={`${modulePrfx}-body`}>
        <StatisticItem
          title='Отмодерировано за этот месяц'
          value={moderatedMonth}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Отмодерировано всего'
          value={moderatedTotal}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Опубликовано за этот месяц'
          value={acceptMonth}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Опубликовано всего'
          value={acceptTotal}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Отклонено за этот месяц'
          value={deniedMonth}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Отклонено всего'
          value={deniedTotal}
          className={`${modulePrfx}-body__item`}
        />
      </div>
      <div className={`${modulePrfx}-btns`}>
        {getButton()}
        <Button
          className={`${modulePrfx}-btns__item ${modulePrfx}-btns__item_remove`}
          type='transparent'
          onClick={() => onDelete(id)}
          loading={loadingDelete}
        >
          Удалить аккаунт{' '}
          <span>
            <BsTrash />
          </span>
        </Button>
      </div>
    </div>
  )
}
