import React from 'react'
import { IncorretResult } from '../../../../components/IncorretResult'
import { SkeletonForm } from '../../../../components/SkeletonForm'
import { useFetchContributorGroupList } from '../../../../hooks/useFetchContributorsGroupList'
import {
  ContributorEditType,
  ContributorType,
} from '../../../../types/contributor'
import { GroupListType } from '../../../../types/group'
import { isObjectEmpty } from '../../../../utils/check'
import { confirmDeleteDialog } from '../../../../utils/confirms'
import {
  useChangeStatusContributor,
  useRemoveContributor,
  useUpdateContributor,
} from '../../ContributorEditPage-hooks'
import { ContributorEditForm } from '../ContributorEditForm'
import './ContributorEditModule.sass'

interface ContributorEditModuleProps {
  id: string
  isLoading: boolean
  isError: boolean
  contributorData: ContributorType
}

export const ContributorEditModule: React.FC<ContributorEditModuleProps> = ({
  id,
  isLoading,
  isError,
  contributorData,
}) => {
  const {
    data: groupData,
    isLoading: isGroupsLoading,
    isError: isGroupsError,
  } = useFetchContributorGroupList() as {
    data: GroupListType
    isLoading: boolean
    isError: boolean
  }

  const { mutateAsync: updateContributor, isLoading: loadingChange } =
    useUpdateContributor(id) as {
      mutateAsync: (values: ContributorEditType) => Promise<any>
      isLoading: boolean
    }

  const {
    mutateAsync: changeContributorStatus,
    isLoading: loadingChangeStatus,
  } = useChangeStatusContributor() as {
    mutateAsync: (id: string) => Promise<any>
    isLoading: boolean
  }
  const { mutateAsync: removeContributor, isLoading: loadingDelete } =
    useRemoveContributor() as {
      mutateAsync: (id: string) => Promise<any>
      isLoading: boolean
    }

  const handleSubmit = async (userData: ContributorEditType) => {
    await updateContributor({ ...userData })
  }

  const removeUser = async (id: string) => {
    await removeContributor(id)
  }

  const handleDelete = async (id: string) => {
    const isOk = await confirmDeleteDialog(
      'Уверены, что хотите удалить пользователя?'
    )
    if (isOk) {
      removeUser(id)
    }
  }

  const handleChangeStatus = async (id: string) => {
    await changeContributorStatus(id)
  }

  if (isLoading || isGroupsLoading) {
    return <SkeletonForm count={7} />
  }
  if (isObjectEmpty(contributorData) || isError) {
    return <IncorretResult />
  }

  return (
    <ContributorEditForm
      contributorData={contributorData}
      groups={groupData}
      handleSubmit={handleSubmit}
      loadingChange={loadingChange}
      loadingDelete={loadingDelete}
      loadingChangeStatus={loadingChangeStatus}
      errorGroups={isGroupsError}
      handleDelete={handleDelete}
      handleChangeStatus={handleChangeStatus}
    />
  )
}
