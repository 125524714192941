import { MAIN_PATH, REJECTIONS_PATH } from '../../constants/globalConstants'

export const REJECTIONS_SIZE = 12

export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Управление причинами отказа',
    path: REJECTIONS_PATH,
  },
]
