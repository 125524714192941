import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { Orders } from './modules/Orders'
import { breadCrumbsData } from './OrdersPage-constants'

export const OrdersPage = () => {
  useBreadcrumbs(breadCrumbsData)

  return (
    <div className='common-page admins'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <Orders />
      </div>
    </div>
  )
}
