import classNames from 'classnames'
import React from 'react'
import { CategoryType, TagsType, UserType } from '../../types/common'
import { getNormalizeDate } from '../../utils/utils'
import './SearchWindow.sass'

interface SearchWindowProps {
  className?: string
  consumer: UserType | null
  category: CategoryType | null
  tags: TagsType | null
  search: TagsType | null
  contributor: UserType | null
  searchDate: string
  handleClick: () => void
}

export const SearchWindow: React.FC<SearchWindowProps> = ({
  className,
  consumer,
  category,
  tags,
  search,
  contributor,
  searchDate,
  handleClick,
}) => {
  const windowPrfx = 'search-window'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={windowCls} onClick={handleClick}>
      {consumer && (
        <div className={`${windowPrfx}__consumer`}>
          <span className={`${windowPrfx}__consumer-title`}>Потребитель: </span>
          <span className={`${windowPrfx}__consumer-value`}>
            {consumer.name}
          </span>
        </div>
      )}
      {contributor && (
        <div className={`${windowPrfx}__contributor`}>
          <span className={`${windowPrfx}__contributor-title`}>
            Поставщик:{' '}
          </span>
          <span className={`${windowPrfx}__contributor-value`}>
            {contributor.name}
          </span>
        </div>
      )}
      {category && (
        <div className={`${windowPrfx}__category`}>
          <span className={`${windowPrfx}__category-title`}>Категория: </span>
          <span className={`${windowPrfx}__category-value`}>
            {category.category}
          </span>
        </div>
      )}
      {search && (
        <div className={`${windowPrfx}__search`}>
          <span className={`${windowPrfx}__search-title`}>Запрос: </span>
          <span className={`${windowPrfx}__search-value`}>
            {search.join(',')}
          </span>
        </div>
      )}
      {searchDate && (
        <div className={`${windowPrfx}__searchDate`}>
          <span className={`${windowPrfx}__searchDate-title`}>Дата: </span>
          <span className={`${windowPrfx}__searchDate-value`}>
            {getNormalizeDate(searchDate)}
          </span>
        </div>
      )}
      {tags && tags.length > 0 && (
        <div className={`${windowPrfx}__tags`}>
          <span className={`${windowPrfx}__tags-title`}>Теги: </span>
          <span className={`${windowPrfx}__tags-value`}>{tags.join(',')}</span>
        </div>
      )}
    </div>
  )
}
