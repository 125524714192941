import classNames from 'classnames'
import React from 'react'
import { getNormalizeDate, getRubles, getStatus } from '../../utils/utils'
import './ContributorWindow.sass'

interface ContributorWindowProps {
  className?: string
  id: string
  login: string
  status: string
  earning: number
  registerDate: string
  onClick: (id: string) => void
}

export const ContributorWindow: React.FC<ContributorWindowProps> = ({
  className,
  id,
  login,
  status,
  earning,
  registerDate,
  onClick,
}) => {
  const windowPrfx = 'contributor-window'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })

  const statusPrfx = 'contributor-window__status'
  const statusCls = classNames(statusPrfx, {
    [`${statusPrfx}_active`]: status === 'active',
    [`${statusPrfx}_disabled`]: status === 'disabled' || status === 'locked',
  })

  const statusValue = getStatus(status)
  const loginValue = login ?? 'Неизвестно'
  const earningValue = getRubles(earning)
  const registerDateValue = getNormalizeDate(registerDate)

  return (
    <div className={windowCls} onClick={() => onClick(id)}>
      <div className={statusCls}>{statusValue}</div>
      <div className={`${windowPrfx}__login`}>{loginValue}</div>
      <div className={`${windowPrfx}__earning`}>{earningValue}</div>
      <div className={`${windowPrfx}__date`}>{registerDateValue}</div>
    </div>
  )
}
