import { CONTRIBUTOR_LIST_API_PATH } from '../constants/globalConstants'
import { UserType } from '../types/common'
import { instance } from '../utils/coreAPI'

export const fetchContributorsCall = async () => {
  try {
    const response = await instance.get(CONTRIBUTOR_LIST_API_PATH)
    if (response.data?.length) {
      const contributorOptions = response.data.map((author: UserType) => ({
        label: author.name,
        value: author.id.toString(),
      }))
      return contributorOptions
    } else {
      return []
    }
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
