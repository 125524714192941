import { userStatusOptions } from './FilterUserStatus-constants'

export const getUserStatusOption = (value: string | string[] | null) => {
  if (!value) return userStatusOptions[0]
  let currentValue = '-1'
  typeof value === 'object' ? (currentValue = value[0]) : (currentValue = value)
  const userStatusOption = userStatusOptions.filter(
    (option) => option.value === currentValue
  )
  return userStatusOption[0] ?? userStatusOptions[0]
}
