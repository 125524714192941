import { orderStatusOptions } from './FilterOrderStatus-constants'

export const getOrderStatusOption = (value: string | string[] | null) => {
  if (!value) return orderStatusOptions[0]
  let currentValue = '-1'
  typeof value === 'object' ? (currentValue = value[0]) : (currentValue = value)
  const orderStatusOption = orderStatusOptions.filter(
    (option) => option.value === currentValue
  )
  return orderStatusOption[0] ?? orderStatusOptions[0]
}
