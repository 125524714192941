import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { ContributorAddType } from '../../types/contributor'
import { contributorAdd } from './ContributorAddPage-api'

export const useCreateContributor = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (values: ContributorAddType) => contributorAdd(values),
    {
      onSuccess: async (data, variables, context) => {
        toast.info('Поставщик добавлен')
        navigate(-1)
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось добавить поставщика')
      },
    }
  )

  return mutation
}
