import classNames from 'classnames'
import React from 'react'
import { getMaterialStatus } from '../../utils/utils'
import './StatusItem.sass'

interface StatusItemProps {
  className?: string
  title?: string
  value: string
}

export const StatusItem: React.FC<StatusItemProps> = ({
  className,
  value,
  title,
}) => {
  const statusPrfx = 'status-item'
  const valuePrfx = 'status-item__value'
  const statusCls = classNames(statusPrfx, {
    [`${className}`]: className,
  })

  const valueCls = classNames(valuePrfx, {
    [`${valuePrfx}_success`]: value === 'accept',
    [`${valuePrfx}_danger`]: value === 'deny',
  })

  return (
    <span className={statusCls}>
      {title && <span className={`${statusPrfx}__title`}>{title}</span>}
      <span className={valueCls}>{getMaterialStatus(value)}</span>
    </span>
  )
}
