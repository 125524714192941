import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { fetchContributorsCall } from '../api/fetchContributorsCall'
import { CONTRIBUTORS_LIST_CACHE_PATH } from '../constants/globalConstants'

export const useFetchContributorList = () => {
  const query = useQuery(
    [CONTRIBUTORS_LIST_CACHE_PATH],
    async () => await fetchContributorsCall(),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      onError: () => {
        toast.warning('Не удалось загрузить список авторов')
      },
    }
  )
  return query
}
