import {
  NEWS_API_PATH,
  NEWS_SNATCH_API_PATH,
} from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { NewsArrayType } from './NewsPage-types'

export const getNewsCall = async () => {
  try {
    const response = await instance.get(NEWS_API_PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getSnatchedNewsCall = async () => {
  try {
    const response = await instance.get(NEWS_SNATCH_API_PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const saveNewsCall = async (news: NewsArrayType) => {
  try {
    const response = await instance.post(NEWS_API_PATH, news)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const invalidateNewsCall = async () => {
  try {
    const response = await getSnatchedNewsCall()
    saveNewsCall(response)
    return response
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
