import React from 'react'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import { BreadCrumbs } from '../BreadCrumbs'
import { UserIcon } from '../../components/UserIcon'
import { UserRegisterInfo } from '../../components/UserRegisterInfo'
import classNames from 'classnames'
import './StatisticModule.sass'
import { getNormalizeDate } from '../../utils/utils'
import { Title } from '../../components/Title'
import { Link } from 'react-router-dom'
import { MAIN_PATH } from '../../constants/globalConstants'
import { UserType } from '../../types/common'

interface StatisticModuleProps {
  className?: string
  registerBy?: UserType
  registerDate?: string
  title?: string
}

export const StatisticModule: React.FC<StatisticModuleProps> = ({
  className,
  registerBy,
  registerDate,
  title,
}) => {
  const modulePrfx = 'module-statistic'
  const moduleCls = classNames(modulePrfx, {
    [`${className}`]: className,
  })

  return (
    <div className={moduleCls}>
      <div className={`${modulePrfx}-line`}>
        <Link to={MAIN_PATH} className={`${modulePrfx}-line__link`}>
          <Logo className={`${modulePrfx}-line__logo`} />
        </Link>
        <BreadCrumbs className={`${modulePrfx}-line__breadcrumbs`} />
        <UserIcon className={`${modulePrfx}-line__icon`} />
      </div>
      {title && <Title className={`${modulePrfx}__title`} text={title} />}
      {registerBy && registerDate && (
        <UserRegisterInfo
          className={`${modulePrfx}__info`}
          registerBy={registerBy}
          registerDate={
            registerDate ? getNormalizeDate(registerDate) : 'неизвестно'
          }
        />
      )}
    </div>
  )
}
