export enum DATE_OPTIONS_ENUM {
  DAY = '1d',
  WEEK = '1w',
  MONTH = '1m',
  YEAR = '1y',
}

export const filterDateOptions = [
  {
    label: 'За все время',
    value: undefined,
  },
  {
    label: 'За последние 24 часа',
    value: DATE_OPTIONS_ENUM.DAY,
  },
  {
    label: 'За последнюю неделю',
    value: DATE_OPTIONS_ENUM.WEEK,
  },
  {
    label: 'За последний месяц',
    value: DATE_OPTIONS_ENUM.MONTH,
  },
  {
    label: 'За последний год',
    value: DATE_OPTIONS_ENUM.YEAR,
  },
]
