import { MODERATOR_API_PATH } from "../../constants/globalConstants";
import { instance } from "../../utils/coreAPI";
import { MODERATORS_SIZE } from "./ModeratorsPage-constants";

export const getListModerators = async (
  page: number,
  interval: string | undefined,
  name: string | undefined,
  status: string | undefined
) => {
  try {
    const response = await instance.get(`${MODERATOR_API_PATH}`, {
      params: {
        page,
        size: MODERATORS_SIZE,
        interval,
        login: name,
        status,
      },
    });
    return response.data;
  } catch (error: any) {
    return await Promise.reject(error);
    // return error;
  }
};
