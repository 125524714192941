import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import { SkeletonMaterialDescription } from '../SkeletonMaterialDescription'
import './SkeletonMaterialsInfo.sass'

export const SkeletonMaterialsInfo = () => {
  return (
    <div className='skeleton-materials-info'>
      <div className='skeleton-materials-info-header'>
        <SkeletonBase
          className='skeleton-materials-info-header__item'
          type='text'
        />
        <SkeletonBase
          className='skeleton-materials-info-header__item'
          type='text'
        />
      </div>
      <SkeletonMaterialDescription />
    </div>
  )
}
