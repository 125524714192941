import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from '../../components/Button'
import { Input, InputPassword } from '../../components/Input'
import { getModifiedValue } from '../../utils/check'
import { phoneRe } from '../../utils/regulars'
import classNames from 'classnames'
import { InputPhone } from '../../components/InputPhone'
import { UserFormType } from '../../types/common'

interface UserChangeFormProps {
  className?: string
  name: string
  login: string
  email: string
  phone: string
  handleSubmit: (userData: Partial<UserFormType>) => void
  handleAction: () => void
  loadingChange: boolean
  password: string
  buttonText: string
}

export const UserChangeForm: React.FC<UserChangeFormProps> = ({
  className,
  name,
  login,
  password = '',
  email,
  phone,
  handleSubmit,
  handleAction,
  loadingChange,
  buttonText,
}) => {
  const formCls = classNames('user-form', {
    [`${className}`]: className,
  })
  const initialValues = {
    name: name,
    password: password,
    login: login,
    email: email,
    phone: phone,
  }
  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно'),
    login: Yup.string().required('Обязательно'),
    password: Yup.string(),
    email: Yup.string().email().required('Обязательно'),
    phone: Yup.string()
      .matches(phoneRe, 'Неверный формат номера')
      .required('Обязательно'),
  })
  const onSubmit = async (values: UserFormType) => {
    const modifiedValues: Partial<UserFormType> = getModifiedValue(
      values,
      initialValues
    )
    const modifiedValuesWithPhone = {
      ...modifiedValues,
      phone: values.phone
        ? values.phone
            .replace(/\)/g, '')
            .replace(/\(/g, '')
            .replace(/-/g, '')
            .replace(/ /g, '')
        : values.phone,
    }

    handleSubmit(modifiedValuesWithPhone)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={false}
    >
      {(formik) => (
        <Form className={formCls}>
          <div className='user-form-left'>
            <h2 className={`user-form__title`}>Персональная информация</h2>
            <Input
              type='text'
              id='name'
              title='Имя'
              value={formik.values['name']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <Input
              type='text'
              id='login'
              value={formik.values['login']}
              onChange={formik.handleChange}
              title='Логин'
              className={`user-form__control`}
            />
            <InputPassword
              type='password'
              id='password'
              value={formik.values['password']}
              onChange={formik.handleChange}
              title='Пароль'
              className={`user-form__control`}
            />
            <Input
              type='email'
              id='email'
              title='Почта'
              value={formik.values['email']}
              errors={formik.errors['email']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <InputPhone
              id='phone'
              value={formik.values['phone']}
              errors={formik.errors['phone']}
              onChange={formik.handleChange}
              title='Номер телефона'
              className={`user-form__control`}
            />
          </div>

          <div className={`user-form-btns`}>
            <Button
              htmlType='submit'
              disabled={!formik.isValid}
              loading={loadingChange}
              className={`user-form-btns__item user-form__control`}
            >
              Сохранить изменения
            </Button>
            <Button
              htmlType='button'
              type='transparent'
              className={`user-form-btns__item user-form__control`}
              onClick={handleAction}
            >
              {buttonText}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
