import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './ModerationHistoryPage-constants'
import { ModerationHistory } from './modules/ModerationHistory'

export const ModerationHistoryPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='moderators common-page'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <ModerationHistory />
      </div>
    </div>
  )
}
