import { useQuery } from "react-query";
import { MODERATORS_CACHE_PATH } from "../../constants/globalConstants";
import { getListModerators } from "./ModeratorsPage-api";

export const useFetchModerators = (
  page: number,
  status: string | undefined = undefined,
  interval: string | undefined = undefined,
  name: string | undefined = undefined
) => {
  const query = useQuery(
    [MODERATORS_CACHE_PATH, { page, interval, name, status }],
    async () => await getListModerators(page, interval, name, status),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
    }
  );

  return query;
};
