import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { CategoryRequestType } from '../../../CategoriesPage/CategoriesPage-types'
import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'

interface CategoryChangeFormProps {
  id: string
  className?: string
  category: string
  handleSubmit: (categoryData: CategoryRequestType) => void
  handleDelete: (id: string) => void
  loadingChange: boolean
  loadingDelete: boolean
}

export const CategoryChangeForm: React.FC<CategoryChangeFormProps> = ({
  category,
  handleSubmit,
  handleDelete,
  loadingChange,
  loadingDelete,
  id,
}) => {
  const initialValues = {
    category: category,
  }
  const validationSchema = Yup.object({
    category: Yup.string().required('Обязательно'),
  })
  const onSubmit = async (values: CategoryRequestType) => {
    handleSubmit({
      category: values.category,
    })
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={false}
    >
      {(formik) => (
        <Form className={`user-form`}>
          <div className='user-form-left'>
            <h2 className={`user-form__title`}>Информация о категории</h2>
            <Input
              type='text'
              id='category'
              title='Категория'
              value={formik.values['category']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
          </div>
          <div className={`user-form-btns`}>
            <Button
              htmlType='submit'
              disabled={!formik.isValid}
              loading={loadingChange}
              className={`user-form-btns__item user-form__control`}
            >
              Сохранить изменения
            </Button>
            <Button
              htmlType='button'
              type='danger'
              className={`user-form-btns__item user-form__control`}
              onClick={() => handleDelete(id)}
              loading={loadingDelete}
            >
              Удалить
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
