import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { breadCrumbsData } from './ContributorAddPage-constants'
import { useBreadcrumbs } from '../../utils/hooks'
import { useCreateContributor } from './ContributorAddPage-hooks'
import { ContributorAddModule } from './modules/ContributorAddModule'
import { ContributorAddType } from '../../types/contributor'
import { useNavigate } from 'react-router'
import { useFetchContributorGroupList } from '../../hooks/useFetchContributorsGroupList'
import { GroupListType } from '../../types/group'

export const ContributorAddPage = () => {
  const navigate = useNavigate()
  useBreadcrumbs(breadCrumbsData)

  const {
    data: groupData,
    isLoading: isGroupsLoading,
    isError: isGroupsError,
  } = useFetchContributorGroupList() as {
    data: GroupListType
    isLoading: boolean
    isError: boolean
  }

  const { mutateAsync: createContributor, isLoading: loadingCreate } =
    useCreateContributor() as {
      mutateAsync: (contributorData: ContributorAddType) => Promise<any>
      isLoading: boolean
    }

  const handleSubmit = async (userData: ContributorAddType) => {
    await createContributor(userData)
  }
  const handleCancel = () => {
    navigate(-1)
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Создание нового поставщика'} />
      </div>
      <div className='common-page-body'>
        <ContributorAddModule
          groups={groupData}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isLoading={loadingCreate}
          loadingGroups={isGroupsLoading}
          errorGroups={isGroupsError}
        />
      </div>
    </div>
  )
}
