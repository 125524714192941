import React from "react";
import { AppRoutes } from "./AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ConfigProvider } from "antd/es";
import ruRu from "antd/locale/ru_RU";

import "./assets/styles/App.sass";

export const App: React.FC = () => {
  return (
    <ConfigProvider
      locale={ruRu}
      theme={{
        token: {
          colorPrimary: "#141e61",
          colorTextBase: "#141e61",
          colorPrimaryHover: "#26359C",
        },
      }}
    >
      <div className='App'>
        <AppRoutes />
        <ToastContainer hideProgressBar={true} />
      </div>
    </ConfigProvider>
  );
};
