import { instance } from '../../../../utils/coreAPI'
import {
  CATEGORY_API_PATH,
  MATERIAL_API_PATH,
} from '../../../../constants/globalConstants'
import { CategoriesType } from '../../../CategoriesPage/CategoriesPage-types'
import { HashAPIType, ModerateAcceptAPIType } from './ModerationModule-types'

export const getModerationMaterial = async (moderationId: string | null) => {
  const PATH = moderationId
    ? `${MATERIAL_API_PATH}/to-moderation/${moderationId}`
    : `${MATERIAL_API_PATH}/to-moderation/rand`
  try {
    const response = await instance.get(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const materialRotateRightCall = async (
  id: string
): Promise<HashAPIType> => {
  try {
    const response = await instance.post(
      `${MATERIAL_API_PATH}/${id}/rotate/right`
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const materialRotateLeftCall = async (
  id: string
): Promise<HashAPIType> => {
  try {
    const response = await instance.post(
      `${MATERIAL_API_PATH}/${id}/rotate/left`
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const moderateAcceptMaterial = async (values: ModerateAcceptAPIType) => {
  try {
    const { id, categoryId, tags, description, exclusiveRights } = values
    const response = await instance.post(
      `${MATERIAL_API_PATH}/moderation/${id}/accept`,
      {
        categoryId,
        tags,
        description,
        exclusiveRights,
      }
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getCategories = async () => {
  try {
    const response = await instance.get(`${CATEGORY_API_PATH}/list`)
    const categories: CategoriesType = response.data
    const categoriesOptions = categories.map((category) => ({
      id: category.id,
      label: category.category,
      value: category.category,
    }))
    return categoriesOptions
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
