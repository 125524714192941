import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './CategoriesPage-constants'
import { Categories } from './modules/Categories'

export const CategoriesPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page admins'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <Categories />
      </div>
    </div>
  )
}
