import React from 'react'
import { useParams } from 'react-router'
import { IncorretResult } from '../../components/IncorretResult'
import { SkeletonFormPage } from '../../components/SkeletonFormPage'
import { ROLE_CONTRIBUTOR } from '../../constants/common'
import { MAIN_PATH, REQUESTS_PATH } from '../../constants/globalConstants'
import { StatisticModule } from '../../modules/StatisticModule'
import { isObjectEmpty } from '../../utils/check'
import { useBreadcrumbs } from '../../utils/hooks'
import { isStatusValid } from '../../utils/utils'
import { RequestType } from '../RequestsPage/RequestsPage-types'
import { ConsumerRequestForm } from './modules/ConsumerRequestForm'
import { ContributorRequestForm } from './modules/ContributorRequestForm'
import { RequestInfoModule } from './modules/RequestInfoModule'
import { useRequestById, useRequestDeny } from './RequestEditPage-hooks'
import { useRequestAccept } from './RequestEditPage-hooks'
import { RequestAcceptValuesType } from './RequestEditPage-types'

export const RequestEditPage = () => {
  const { id } = useParams<'id'>()

  const {
    data: requestData,
    isLoading,
    isError,
  } = useRequestById(id!) as {
    data: RequestType
    isLoading: boolean
    isError: boolean
  }

  const { mutateAsync: acceptRequest, isLoading: loadingAccept } =
    useRequestAccept() as {
      mutateAsync: (values: RequestAcceptValuesType) => Promise<any>
      isLoading: boolean
    }
  const { mutateAsync: denyRequest, isLoading: loadingDeny } =
    useRequestDeny() as {
      mutateAsync: (id: string) => Promise<any>
      isLoading: boolean
    }

  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Управление заявками',
        path: REQUESTS_PATH,
      },
      {
        id: '2',
        name: `Заявка ${requestData?.login ?? ''}`,
        path: `${REQUESTS_PATH}/${id}`,
      },
    ],
    [id, requestData]
  )
  useBreadcrumbs(breadCrumbsData)

  const handleSubmit = async (values: RequestAcceptValuesType) => {
    await acceptRequest(values)
  }
  const handleDeny = async (requestId: string) => {
    await denyRequest(requestId)
  }

  if (isLoading) {
    return (
      <SkeletonFormPage
        rightColumn={'statistic'}
        leftFormCount={6}
        withRegisterInfo={false}
        statisticCount={5}
      />
    )
  }
  if (isObjectEmpty(requestData) || isError) {
    return <IncorretResult />
  }

  const getBody = () => {
    if (isStatusValid(requestData?.status)) {
      return (
        <div className='common-page-body common-page-body_double'>
          {requestData.userType === ROLE_CONTRIBUTOR ? (
            <ContributorRequestForm
              id={id!}
              handleSubmit={handleSubmit}
              handleDeny={handleDeny}
              className='common-page-body__left'
              loadingAccept={loadingAccept}
              loadingDeny={loadingDeny}
            />
          ) : (
            <ConsumerRequestForm
              id={id!}
              handleSubmit={handleSubmit}
              handleDeny={handleDeny}
              className='common-page-body__left'
              loadingAccept={loadingAccept}
              loadingDeny={loadingDeny}
            />
          )}
          <RequestInfoModule
            className='common-page-body__right'
            name={requestData.name}
            login={requestData.login}
            email={requestData.email}
            phone={requestData.phone}
            registerDate={requestData.registerDate}
          />
        </div>
      )
    } else {
      return (
        <div className='common-page-body common-page-body_restricted'>
          <RequestInfoModule
            name={requestData.name}
            login={requestData.login}
            email={requestData.email}
            phone={requestData.phone}
            registerDate={requestData.registerDate}
          />
        </div>
      )
    }
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Модерация заявки'} />
      </div>
      {getBody()}
    </div>
  )
}
