import { instance } from '../../utils/coreAPI'
import { CATEGORY_API_PATH } from '../../constants/globalConstants'
import { CATEGORIES_SIZE } from './CategoriesPage-constants'

export const getListCategories = async (page: number) => {
  try {
    const response = await instance.get(CATEGORY_API_PATH, {
      params: {
        page,
        size: CATEGORIES_SIZE,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
