import classNames from 'classnames'
import React from 'react'
import Select from 'react-select'
import queryString from 'query-string'
import { getAuthorOption } from './FilterAuthor-utils'
import { NavigateFunction } from 'react-router'
import { useFetchContributorList } from '../../hooks/useFetchContributorsList'
import {
  CONTRIBUTOR_URL_VALUE,
  PAGE_URL_VALUE,
} from '../../constants/filterURLNames'
import { OptionType } from '../../types/common'

interface FilterAuthorProps {
  className?: string
  navigate: NavigateFunction
  params: { [k: string]: string }
}

export const FilterAuthor: React.FC<FilterAuthorProps> = React.memo(
  ({ className, navigate, params }) => {
    const filterPrfx = 'filter-param-container'
    const filterCls = classNames(filterPrfx, {
      [`${className}`]: className,
    })
    const authorId = params[CONTRIBUTOR_URL_VALUE]

    const { data: authorsList, isLoading: isAuthorsLoading } =
      useFetchContributorList() as {
        data: OptionType[]
        isLoading: boolean
      }

    const authorOption = React.useCallback(
      () => getAuthorOption(authorId, authorsList),
      [authorId, authorsList]
    )

    const [authorFilter, setAuthorFilter] = React.useState(authorOption)

    React.useEffect(() => {
      setAuthorFilter(authorOption)
    }, [authorId, authorOption])

    const handleFilterAuthorChange = (option: any) => {
      setAuthorFilter(getAuthorOption(option.value, authorsList))
      params[CONTRIBUTOR_URL_VALUE] = option.value
      params[PAGE_URL_VALUE] = '1'
      navigate({ search: queryString.stringify(params) })
    }

    return (
      <div className={filterCls}>
        <label className={`${filterPrfx}__label`} htmlFor='author'>
          По автору
        </label>
        <Select
          id='author'
          className='react-select-container'
          classNamePrefix='react-select'
          placeholder={'Имя автора...'}
          value={authorFilter}
          onChange={handleFilterAuthorChange}
          isLoading={isAuthorsLoading}
          options={authorsList}
        />
      </div>
    )
  }
)
