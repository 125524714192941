import { SEARCH_HISTORY_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { SEARCH_HISTORY_SIZE } from './SearchHistoryPage-constants'

export const getListSearchHistory = async (
  page: number,
  interval: string | undefined,
  category: string | undefined,
  author: string | undefined,
  isConsumer: boolean | undefined = undefined
) => {
  try {
    const response = await instance.get(SEARCH_HISTORY_API_PATH, {
      params: {
        page,
        size: SEARCH_HISTORY_SIZE,
        interval,
        category,
        author,
        guestOnly: isConsumer,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
