import React, { ReactElement } from "react";
import { Cell } from "react-table";
import AntTable from "../../../../components/Table/AntTable";
import { getNormalizeDate } from "../../../../utils/utils";
import {
  SearchHistoryArrayType,
  SearchHistoryType,
} from "../../SearchHistoryPage-types";

const COLUMNS = [
  {
    title: "Потребитель",
    dataIndex: "consumer",

    render: (cellInfo: any) => {
      return <>{cellInfo?.name ?? "-"}</>;
    },
  },
  {
    title: "Категория",
    dataIndex: "category",
    render: (category: any) => {
      return <>{category?.category ?? "-"}</>;
    },
  },
  {
    title: "Теги",
    dataIndex: "tags",
    render: (tags: any) => {
      return <>{tags ? tags.join(",") : "-"}</>;
    },
  },
  {
    title: "Запрос",
    dataIndex: "search",
    render: (search: any) => {
      return <>{search ? search.join(",") : "-"}</>;
    },
  },
  {
    title: "Поставщик",
    dataIndex: "сcontributor",

    render: (сcontributor: any) => {
      return <>{сcontributor?.name || "-"}</>;
    },
  },
  {
    title: "Дата поиска",
    dataIndex: "searchDate",

    render: (searchDate: any) => {
      return <>{getNormalizeDate(searchDate)}</>;
    },
  },
];

interface RequestsTableProps {
  searchHistoryList: SearchHistoryArrayType;
  handleClick: (id: string) => void;
  handleChangePage: (page: number) => void;
  currentPage: number;
  btn: ReactElement<any, any> | null;
  total: number;
  pageSize: number;
}

export const SearchHistoryTable: React.FC<RequestsTableProps> = ({
  searchHistoryList,
  handleClick,
  handleChangePage,
  currentPage,
  btn,
  total,
  pageSize,
}) => {
  const antColumns = React.useMemo(() => COLUMNS, []) as any;
  const data = React.useMemo(() => searchHistoryList, [searchHistoryList]);

  return (
    <AntTable
      columns={antColumns}
      data={data}
      currentPage={currentPage}
      handleChangePage={handleChangePage}
      handleRowClick={handleClick}
      btn={btn}
      total={total}
      pageSize={pageSize}
    />
  );
};
