import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import './NavbarItem.sass'
import { IconType } from 'react-icons'

interface NavbarItemProps {
  className?: string
  text: string
  alignCenter?: boolean
  isNotificate?: boolean
  pathTo: string
  icon: IconType | null
  handleClick?: () => void
}

export const NavbarItem: React.FC<NavbarItemProps> = ({
  className,
  alignCenter,
  text,
  pathTo,
  isNotificate = false,
  icon,
  handleClick,
}) => {
  const linkClsName = classNames('navbar-item', {
    [`${'navbar-item_align_center'}`]: alignCenter,
    [`${className}`]: className,
  })
  const activeLinkClsName = classNames('navbar-item', 'navbar-item_active', {
    [`${'navbar-item_align_center'}`]: alignCenter,
    [`${'navbar-item_align_center_active'}`]: alignCenter,
    [`${className}`]: className,
    [`${className}_active`]: className,
  })

  const iconClsName = classNames('navbar-item__icon', {
    [`navbar-item__icon_notificate`]: isNotificate,
  })
  const CustomIcon = icon as IconType
  return (
    <NavLink
      to={pathTo}
      className={(props) => {
        return props.isActive ? activeLinkClsName : linkClsName
      }}
      onClick={handleClick ?? handleClick}
    >
      <li className='navbar-item__content'>
        <span className='navbar-item__text'>{text}</span>
        {icon !== null && (
          <span className={iconClsName}>
            <CustomIcon />
          </span>
        )}
      </li>
    </NavLink>
  )
}
