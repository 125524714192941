import { SELF_STATISTICS } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'

export const getDashboardInfo = async () => {
  try {
    const response = await instance.get(SELF_STATISTICS, {
      params: {
        self: false,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error?.response?.data.detail)
  }
}
