import React from 'react'
import { useNavigate } from 'react-router'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './ContributorGroupAddPage-constants'
import { useCreateGroupContributor } from './ContributorGroupAddPage-hooks'
import { GroupContributorAddType } from './ContributorGroupAddPage-types'
import './ContributorGroupAddPage.sass'
import { ContributorGroupAddForm } from './modules/ContributorGroupAddForm'

export const ContributorGroupAddPage = () => {
  const navigate = useNavigate()
  useBreadcrumbs(breadCrumbsData)

  const { mutateAsync: createConsumer, isLoading: loadingCreate } =
    useCreateGroupContributor() as {
      mutateAsync: (consumerData: GroupContributorAddType) => Promise<any>
      isLoading: boolean
    }

  const handleSubmit = async (userData: GroupContributorAddType) => {
    await createConsumer(userData)
  }
  const handleCancel = () => {
    navigate(-1)
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Создание новой группы'} />
      </div>
      <div className='common-page-body'>
        <ContributorGroupAddForm
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isLoading={loadingCreate}
        />
      </div>
    </div>
  )
}
