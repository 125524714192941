export const userStatusOptions = [
  {
    label: 'Все',
    value: undefined,
  },
  {
    label: 'Активен',
    value: 'active',
  },
  {
    label: 'Удален',
    value: 'disabled',
  },
  {
    label: 'Заблокирован',
    value: 'locked',
  },
]
