import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { NewsForm } from './modules/NewsForm'
import { TodayNews } from './modules/TodayNews'
import { breadCrumbsData } from './NewsPage-constants'

export const NewsPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Редактирование новостной повестки'} />
      </div>
      <div className='common-page-body common-page-body_double'>
        <NewsForm className='common-page-body__left' />
        <TodayNews className='common-page-body__right' />
      </div>
    </div>
  )
}
