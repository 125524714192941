import classNames from 'classnames'
import React from 'react'
import './MaterialDetailItem.sass'

interface MaterialDetailItemProps {
  className?: string
  title: string
  data: string | string[]
}

export const MaterialDetailItem: React.FC<MaterialDetailItemProps> = ({
  className,
  title,
  data,
}) => {
  const detailPrfx = 'material-details-item'
  const detailCls = classNames(detailPrfx, {
    [`${className}`]: className,
  })

  const getData = React.useMemo(() => {
    if (typeof data === 'string') {
      return data
    } else if (typeof data === 'object') {
      return data.map((dataItem, i) => (
        <span className={`${detailPrfx}__tag`} key={i}>
          {dataItem}
        </span>
      ))
    }
  }, [data])

  return (
    <div className={`${detailCls}`}>
      <h2 className={`${detailPrfx}__title`}>{title}</h2>
      <span className={`${detailPrfx}__text`}>{getData}</span>
    </div>
  )
}
