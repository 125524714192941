import classNames from 'classnames'
import React from 'react'
import './FormTitle.sass'

interface FormTitleProps {
  className?: string
  text: string
}

export const FormTitle: React.FC<FormTitleProps> = ({ className, text }) => {
  const itemPrfx = 'form-title'
  const itemCls = classNames(itemPrfx, {
    [`${className}`]: className,
  })
  return <h3 className={itemCls}>{text}</h3>
}
