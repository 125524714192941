import { CONSUMER_API_PATH } from '../../constants/globalConstants'
import { ConsumerAddType } from '../../types/consumer'
import { instance } from '../../utils/coreAPI'

export const consumerAdd = async (data: ConsumerAddType) => {
  try {
    const response = await instance.post(CONSUMER_API_PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
