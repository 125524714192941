import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as UserLogo } from '../../assets/img/logo-user.svg'
import './GroupUsersListItem.sass'

interface GroupUsersListItemProps {
  className?: string
  userName: string
  linkPath: string
}

export const GroupUsersListItem: React.FC<GroupUsersListItemProps> = ({
  className,
  userName,
  linkPath,
}) => {
  const itemPrfx = 'group-user-list-item'
  const itemCls = classNames(itemPrfx, {
    [`${className}`]: className,
  })
  return (
    <li className={itemCls} title={userName}>
      <Link to={linkPath} className={`${itemPrfx}__link`}>
        <span className={`${itemPrfx}__icon`}>
          <UserLogo />
        </span>
        <span className={`${itemPrfx}__name`}>{userName}</span>
      </Link>
    </li>
  )
}
