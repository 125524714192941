import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { MATERIALS_CACHE_PATH } from '../../constants/globalConstants'
import { useNavigate } from 'react-router'
import {
  getMaterialDetailsById,
  materialRemoveCall,
} from './MaterialInfoPage-api'

export const useMaterialById = (materialId: string) => {
  const query = useQuery(
    [MATERIALS_CACHE_PATH, materialId],
    async () => {
      const adminById = await getMaterialDetailsById(materialId)
      return adminById
    },
    {
      refetchOnMount: true,
    }
  )
  return query
}

export const useMaterialRemove = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (materialId: string) => materialRemoveCall(materialId),
    {
      onSuccess: async (data, variables, context) => {
        navigate(-1)
        toast.info('Материал удален')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось удалить материал')
      },
    }
  )
  return mutation
}
