import { instance } from '../../utils/coreAPI'
import { MATERIAL_API_PATH } from '../../constants/globalConstants'

export const getMaterialDetailsById = async (id: string) => {
  try {
    const response = await instance.get(`${MATERIAL_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error?.response?.data.detail)
  }
}

export const materialRemoveCall = async (id: string) => {
  try {
    const response = await instance.delete(`${MATERIAL_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error?.response?.data.detail)
  }
}
