import React from "react";
import { useNavigate } from "react-router";
import { EmptyComponent } from "../../../../components/EmptyComponent";
import { IncorretResult } from "../../../../components/IncorretResult";
import { SkeletonMain } from "../../../../components/SkeletonMain";
import { SkeletonMainMobile } from "../../../../components/SkeletonMainMobile";
import { isMobileDevice, isObjectEmpty } from "../../../../utils/check";
import { useFetchGeneralInfo } from "../../GeneralPage-hooks";
import { DashboardAPIResponse } from "../../GeneralPage-types";
import { InfoModuleDesktop } from "../InfoModuleDesktop";
import { InfoModuleMobile } from "../InfoModuleMobile";

export const InfoModule = () => {
  const navigate = useNavigate();
  const isMobile = isMobileDevice();
  const {
    data: dashboardRequestData,
    isLoading,
    isError,
  } = useFetchGeneralInfo() as {
    data: DashboardAPIResponse;
    isLoading: boolean;
    isError: boolean;
  };
  console.log(dashboardRequestData);

  const totalData = React.useMemo(
    () => dashboardRequestData?.total,
    [dashboardRequestData]
  );

  const monthlyData = React.useMemo(
    () => dashboardRequestData?.monthly,
    [dashboardRequestData]
  );

  if (isLoading) {
    if (isMobile) {
      return <SkeletonMainMobile />;
    } else {
      return <SkeletonMain />;
    }
  }

  if (isError) {
    return <IncorretResult />;
  }
  if (isObjectEmpty(totalData) && isObjectEmpty(monthlyData)) {
    return (
      <EmptyComponent text='администратора' action={() => navigate(`new`)} />
    );
  }

  return isMobile ? (
    <InfoModuleMobile totalData={totalData} monthlyData={monthlyData} />
  ) : (
    <InfoModuleDesktop totalData={totalData} monthlyData={monthlyData} />
  );
};
