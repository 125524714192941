import classNames from 'classnames'
import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonSnatchNews.sass'

interface SkeletonSnatchNewsProps {
  className?: string
}

export const SkeletonSnatchNews: React.FC<SkeletonSnatchNewsProps> = ({
  className,
}) => {
  const skeletonCls = classNames('skeleton-snatch-news', {
    [`${className}`]: className,
  })
  return (
    <div className={skeletonCls}>
      <SkeletonBase type='text' className='skeleton-snatch-news__text' />
      <SkeletonBase type='text' className='skeleton-snatch-news__text' />
      <div className='skeleton-snatch-news-tags'>
        <SkeletonBase type='text' className='skeleton-snatch-news-tags__item' />
        <SkeletonBase type='text' className='skeleton-snatch-news-tags__item' />
        <SkeletonBase type='text' className='skeleton-snatch-news-tags__item' />
        <SkeletonBase type='text' className='skeleton-snatch-news-tags__item' />
        <SkeletonBase type='text' className='skeleton-snatch-news-tags__item' />
      </div>
    </div>
  )
}
