import React from 'react'
import { SkeletonWindow } from '../SkeletonWindow'
import './SkeletonWindows.sass'

export const SkeletonWindows = () => {
  return (
    <div className='skeleton-windows'>
      <div className='skeleton-windows-content'>
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
        <SkeletonWindow />
      </div>
    </div>
  )
}
