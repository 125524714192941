import React, { ReactElement } from "react";
import AntTable from "../../../../components/Table/AntTable";
import { ContributorsType } from "../../../../types/contributor";
import { getNormalizeDate, getStatus } from "../../../../utils/utils";

const COLUMNS = [
  {
    title: "Логин",
    dataIndex: "login",
  },
  {
    title: "Имя",
    dataIndex: "name",
  },
  {
    title: "Зарегистрирован",
    dataIndex: "registerBy",
    render: (cellInfo: any) => {
      return <>{cellInfo.name ?? "Неизвестно"}</>;
    },
  },
  {
    title: "Дата регистрации",
    dataIndex: "registerDate",
    render: (cellInfo: any) => {
      return <>{getNormalizeDate(cellInfo)}</>;
    },
  },
  {
    title: "Статус",
    dataIndex: "status",
    render: (cellInfo: any) => {
      return cellInfo === "active" ? (
        <span className='cell_green'>{getStatus(cellInfo)}</span>
      ) : (
        <span className='cell_red'>{getStatus(cellInfo)}</span>
      );
    },
  },
];

interface ContributorsTableProps {
  contributorsList: ContributorsType;
  handleRowClick: (id: string) => void;
  handleChangePage: (page: number) => void;
  currentPage: number;
  btn: ReactElement<any, any> | null;
  total: number;
  pageSize: number;
}

export const ContributorsTable: React.FC<ContributorsTableProps> = ({
  contributorsList,
  handleRowClick,
  handleChangePage,
  currentPage,
  btn,
  total,
  pageSize,
}) => {
  const antColumns = React.useMemo(() => COLUMNS, []) as any;
  const data = React.useMemo(() => contributorsList, [contributorsList]);
  return (
    <AntTable
      columns={antColumns}
      data={data}
      currentPage={currentPage}
      handleChangePage={handleChangePage}
      handleRowClick={handleRowClick}
      btn={btn}
      total={total}
      pageSize={pageSize}
    />
  );
};
