import React from 'react'
import { getDateFromISO } from '../../../../utils/utils'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  GroupContributorAddType,
  GroupContributorFormValuesType,
} from '../../ContributorGroupAddPage-types'
import { FormTitle } from '../../../../components/FormTitle'
import { Input } from '../../../../components/Input'
import { DatePickerCustom } from '../../../../components/DatePickerCustom'
import { Button } from '../../../../components/Button'
import { BsTrash } from 'react-icons/bs'
import './ContributorGroupAddForm.sass'

interface ContributorGroupAddFormProps {
  className?: string
  handleSubmit: (values: GroupContributorAddType) => void
  handleCancel: () => void
  isLoading: boolean
}

export const ContributorGroupAddForm: React.FC<
  ContributorGroupAddFormProps
> = ({ handleSubmit, handleCancel, isLoading }) => {
  const initialValues = {
    groupName: '',
    number: '',
    title: '',
    description: '',
    startDate: new Date(),
  }
  const validationSchema = Yup.object({
    groupName: Yup.string().required('Обязательно'),
    number: Yup.string().required('Обязательно'),
    title: Yup.string().required('Обязательно'),
    description: Yup.string().required('Обязательно'),
    startDate: Yup.date().required('Обязательно').max(new Date()),
  })
  const onSubmit = async (values: GroupContributorFormValuesType) => {
    const submitObject: GroupContributorAddType = {
      groupName: values.groupName,
      contract: {
        title: values.title,
        number: values.number,
        description: values.description,
        startDate: getDateFromISO(values.startDate.toISOString()),
      },
    }
    handleSubmit(submitObject)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={false}
    >
      {(formik) => (
        <Form className={'user-form'}>
          <div className={`user-form-left`}>
            <FormTitle text='Информация о группе' />
            <Input
              type='text'
              id='groupName'
              title='Название'
              value={formik.values['groupName']}
              errors={formik.errors['groupName']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <FormTitle text='Параметры контракта' />
            <Input
              type='text'
              id='number'
              title='Номер контракта'
              value={formik.values['number']}
              errors={formik.errors['number']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <Input
              type='text'
              id='title'
              title='Название контракта'
              value={formik.values['title']}
              errors={formik.errors['title']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <Input
              type='text'
              id='description'
              title='Описание'
              value={formik.values['description']}
              errors={formik.errors['description']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <DatePickerCustom
              name='startDate'
              title='Дата начала контракта'
              selected={formik.values['startDate']}
              errors={formik.errors['startDate']}
              onChange={(val) => formik.setFieldValue('startDate', val)}
              className={`user-form__control`}
            />
          </div>
          <div className={`user-form-btns`}>
            <Button
              htmlType='submit'
              disabled={!formik.isValid}
              className={`user-form-btns__item user-form__control`}
              loading={isLoading}
            >
              Сохранить изменения
            </Button>
            <Button
              htmlType='button'
              type='transparent'
              className={`user-form-btns__item user-form__control`}
              onClick={handleCancel}
            >
              Отмена{' '}
              <span>
                <BsTrash />
              </span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
