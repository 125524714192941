import {
  CONSUMERS_STATISTIC_API_PATH,
  CONSUMER_API_PATH,
  REPORT_API_PATH,
} from '../../constants/globalConstants'
import { ConsumerEditType } from '../../types/consumer'
import { instance } from '../../utils/coreAPI'
import { REPORT_SIZE } from './ConsumerEditPage-constants'

export const getIdConsumer = async (id: string) => {
  try {
    const response = await instance.get(`${CONSUMER_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getIdConsumerStatistics = async (id: string) => {
  try {
    const response = await instance.get(`${CONSUMERS_STATISTIC_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getConsumerReports = async (id: string, page: number) => {
  try {
    const response = await instance.get(`${REPORT_API_PATH}`, {
      params: {
        consumer: id,
        page: page,
        size: REPORT_SIZE,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const consumerChange = async (id: string, data: ConsumerEditType) => {
  try {
    const PATH = `${CONSUMER_API_PATH}/${id}`
    const response = await instance.patch(PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const consumerRemove = async (id: string) => {
  try {
    const PATH = `${CONSUMER_API_PATH}/${id}`
    const response = await instance.delete(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
export const consumerChangeStatus = async (id: string) => {
  try {
    const PATH = `${CONSUMER_API_PATH}/${id}/switch/lock`
    const response = await instance.post(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
