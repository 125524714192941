import React from 'react'
import { ConsumerWindow } from '../../../../components/ConsumerWindow'
import { ConsumersListType } from '../../../../types/consumer'

interface ConsumersMobileProps {
  consumersList: ConsumersListType
  handleRowClick: (id: string) => void
}

export const ConsumersMobile: React.FC<ConsumersMobileProps> = ({
  consumersList,
  handleRowClick,
}) => {
  if (!consumersList.length) {
    return <span>Еще не создано ни одного потребителя</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {consumersList.map((consumer) => (
          <ConsumerWindow
            className='windows-wrapper-windows__item'
            key={consumer.id}
            id={consumer.id}
            status={consumer.status}
            login={consumer.login}
            limit={consumer.limit}
            registerDate={consumer.registerDate}
            contractExpiration={consumer.contractExpiration}
            onClick={handleRowClick}
          />
        ))}
      </div>
    </div>
  )
}
