import { CONTRIBUTOR_API_PATH } from '../../constants/globalConstants'
import { ContributorAddType } from '../../types/contributor'
import { instance } from '../../utils/coreAPI'

export const contributorAdd = async (data: ContributorAddType) => {
  try {
    const response = await instance.post(CONTRIBUTOR_API_PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
