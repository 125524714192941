import { MATERIAL_API_PATH } from '../constants/globalConstants'
import { instance } from '../utils/coreAPI'

export const moderateDenyMaterialCall = async (
  id: string,
  rejectionId: string,
  description?: string
) => {
  try {
    const response = await instance.post(
      `${MATERIAL_API_PATH}/moderation/${id}/deny/${rejectionId}`,
      {
        description,
      }
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
