import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './ConsumerPage-constants'
import { Consumers } from './modules/Consumers'

export const ConsumersPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='contributors common-page'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <Consumers />
      </div>
    </div>
  )
}
