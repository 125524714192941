import React from 'react'
import { useParams } from 'react-router'
import { useBreadcrumbs } from '../../utils/hooks'
import { StatisticModule } from '../../modules/StatisticModule'
import { MaterialsModule } from './modules/MaterialsModule'
import './FavouriteMaterialsPage.sass'
import { CONSUMERS_PATH, MAIN_PATH } from '../../constants/globalConstants'

export const FavouriteMaterialsPage = () => {
  const { id } = useParams<'id'>()
  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Управление потребителями',
        path: CONSUMERS_PATH,
      },
      {
        id: '2',
        name: `Профиль потребителя`,
        path: `${CONSUMERS_PATH}/${id}/edit`,
      },
      {
        id: '3',
        name: `Избранные материалы`,
        path: `${CONSUMERS_PATH}/${id}/favourite`,
      },
    ],
    [id]
  )
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <MaterialsModule id={id} />
      </div>
    </div>
  )
}
