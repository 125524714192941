import { BREADCRUMBS_SET, BREADCRUMBS_CLEAR } from './BreadCrumbs-constants'
import { BreadCrumbsActions, BreadCrumbsState } from './BreadCrumbs-types'

const defaultItem = {
  id: '0',
  name: 'Кабинет поставщика',
  path: '/',
}

const initialState: BreadCrumbsState = {
  breadcrumbs: [defaultItem],
}

export const BreadCrumbsReducer = (
  state = initialState,
  action: BreadCrumbsActions
): BreadCrumbsState => {
  switch (action.type) {
    case BREADCRUMBS_SET:
      return {
        ...state,
        breadcrumbs: [...action.payload],
      }
    case BREADCRUMBS_CLEAR:
      return {
        ...state,
        breadcrumbs: [defaultItem],
      }
    default:
      return state
  }
}
