import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { fetchCategoriesCall } from '../api/fetchCategoriesCall'
import { CATEGORIES_CACHE_PATH } from '../constants/globalConstants'

export const useFetchCategoriesList = () => {
  const query = useQuery(
    CATEGORIES_CACHE_PATH,
    async () => await fetchCategoriesCall(),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: () => {
        toast.warning('Не удалось загрузить список категорий')
      },
    }
  )
  return query
}
