import React from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { RejectionRequestType } from '../../../RejectionsPage/RejectionsPage-types'
import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import { BsTrash } from 'react-icons/bs'

interface RejectionAddFormProps {
  className?: string
  handleSubmit: (values: RejectionRequestType) => void
  handleCancel: () => void
  isLoading: boolean
}

export const RejectionAddForm: React.FC<RejectionAddFormProps> = ({
  className,
  handleSubmit,
  handleCancel,
  isLoading = false,
}) => {
  const initialValues = {
    rejection: '',
  }
  const validationSchema = Yup.object({
    rejection: Yup.string().required('Обязательно'),
  })
  const onSubmit = async (values: RejectionRequestType, { resetForm }: any) => {
    handleSubmit({
      rejection: values.rejection,
    })
    resetForm()
  }
  return (
    <div className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
      >
        {(formik) => (
          <Form className={`user-form`}>
            <div className='user-form-left'>
              <h2 className={`user-form__title`}>
                Информация о причине отказа
              </h2>
              <Input
                type='text'
                id='rejection'
                title='Причина'
                value={formik.values['rejection']}
                onChange={formik.handleChange}
                className={`user-form__control`}
              />
            </div>
            <div className='user-form-btns'>
              <Button
                htmlType='submit'
                disabled={!formik.isValid}
                className={`user-form-btns__item user-form__control`}
                loading={isLoading}
              >
                Сохранить изменения
              </Button>
              <Button
                htmlType='button'
                type='transparent'
                className={`user-form-btns__item user-form__control`}
                onClick={handleCancel}
              >
                Отмена{' '}
                <span>
                  <BsTrash />
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
