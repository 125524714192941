import { MAIN_PATH, MATERIALS_PATH } from '../../constants/globalConstants'

export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Список материалов',
    path: MATERIALS_PATH,
  },
]
