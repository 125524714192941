import { WITHDRAW_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'

export const getIdPaymentOrder = async (id: string) => {
  try {
    const response = await instance.get(`${WITHDRAW_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const paymentOrderAccept = async (id: string, description: string) => {
  try {
    const PATH = `${WITHDRAW_API_PATH}/${id}/accept`
    const response = await instance.post(PATH, { description })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const paymentOrderDeny = async (id: string, description: string) => {
  try {
    const response = await instance.post(`${WITHDRAW_API_PATH}/${id}/deny`, {
      description,
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
