import { instance } from '../../utils/coreAPI'
import { REJECTION_API_PATH } from '../../constants/globalConstants'
import { REJECTIONS_SIZE } from './RejectionsPage-constants'

export const getListRejections = async (page: number) => {
  try {
    const response = await instance.get(REJECTION_API_PATH, {
      params: {
        page,
        size: REJECTIONS_SIZE,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
