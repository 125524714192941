import classNames from 'classnames'
import React from 'react'
import { ROLE_CONSUMER } from '../../constants/common'
import {
  CONSUMERS_PATH,
  CONTRIBUTORS_PATH,
} from '../../constants/globalConstants'
import { ConsumerOrContributorRole, UserType } from '../../types/common'
import { GroupUsersListItem } from '../GroupUsersListItem'
import { IncorretResult } from '../IncorretResult'
import { SkeletonUsersList } from '../SkeletonUsersList'
import { Title } from '../Title'
import './GroupUsersList.sass'

interface GroupUsersListProps {
  className?: string
  members: UserType[]
  membersType: ConsumerOrContributorRole
  isLoading: boolean
  isError: boolean
}

export const GroupUsersList: React.FC<GroupUsersListProps> = ({
  className,
  members,
  membersType,
  isLoading,
  isError,
}) => {
  const itemPrfx = 'group-users-list'
  const itemCls = classNames(`${itemPrfx}-wrapper`, {
    [`${className}`]: className,
  })

  if (isLoading) {
    return (
      <div className={itemCls}>
        <Title text='Список пользователей' className={`${itemPrfx}__header`} />
        <SkeletonUsersList />
      </div>
    )
  }

  if (isError) {
    return (
      <div className={itemCls}>
        <Title text='Список пользователей' className={`${itemPrfx}__header`} />
        <IncorretResult />
      </div>
    )
  }

  if (!members || members.length === 0) {
    return (
      <div className={itemCls}>
        <Title text='Список пользователей' className={`${itemPrfx}__header`} />
        <span className={`${itemPrfx}__empty`}>Пока нет участников</span>
      </div>
    )
  }

  const rootLinkBody =
    membersType === ROLE_CONSUMER ? CONSUMERS_PATH : CONTRIBUTORS_PATH

  return (
    <div className={itemCls}>
      <Title text='Список пользователей' className={`${itemPrfx}__header`} />
      <ul className={itemPrfx}>
        {members.map((user) => (
          <GroupUsersListItem
            key={user.id}
            userName={user.name}
            linkPath={`${rootLinkBody}/${user.id}/edit`}
          />
        ))}
      </ul>
    </div>
  )
}
