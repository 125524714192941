import classNames from 'classnames'
import React from 'react'
import { FixedArray } from '../../types/globalInterfaces'
import './WrapperGrid.sass'

interface WrapperGridProps {
  className?: string
  children: FixedArray<React.ReactNode, 2> | FixedArray<React.ReactNode, 3>
}

export const WrapperGrid: React.FC<WrapperGridProps> = ({
  className,
  children,
}) => {
  const childrenLength = children.length
  const wrapperPrfx = 'grid'
  const grid2 = 'grid-2'
  const grid3 = 'grid-3'
  const wrapperCls = classNames(wrapperPrfx, {
    [grid2]: children.length === 2,
    [grid3]: children.length === 3,
    [`${className}`]: className,
  })

  return (
    <div className={wrapperCls}>
      {childrenLength === 2 && (
        <>
          <div className={`${grid2}_1`}>{children[0]}</div>
          <div className={`${grid2}_2`}>{children[1]}</div>
        </>
      )}
      {childrenLength === 3 && (
        <>
          <div className={`${grid3}_1`}>{children[0]}</div>
          <div className={`${grid3}_2`}>{children[1]}</div>
          <div className={`${grid3}_3`}>{children[2]}</div>
        </>
      )}
    </div>
  )
}
