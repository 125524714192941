import { useQuery } from 'react-query'
import { MATERIALS_CACHE_PATH } from '../../constants/globalConstants'
import { getFavouriteListCall } from './FavouriteMaterialsPage-api'

export const useFetchFavouriteMaterials = (
  id: string | undefined,
  page: number,
  interval: string | undefined = undefined,
  sort: string | undefined = undefined,
  favoriteInterval: string | undefined = undefined
) => {
  const query = useQuery(
    [MATERIALS_CACHE_PATH, { id, page, interval, sort, favoriteInterval }],
    async () =>
      await getFavouriteListCall(id, page, interval, sort, favoriteInterval),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
      enabled: !!id,
    }
  )
  return query
}
