import classNames from "classnames";
import React from "react";
import queryString from "query-string";
import { IncorretResult } from "../../../../components/IncorretResult";
import { Pagination } from "../../../../components/Pagination";
import { isMobileDevice } from "../../../../utils/check";
import { REQUESTS_SIZE } from "../../RequestsPage-constants";
import { useFetchRequests } from "../../RequestsPage-hooks";
import { RequestsAPIAnswerType } from "../../RequestsPage-types";
import { RequestsMobile } from "../RequestsMobile";
import { RequestsTable } from "../RequestsTable";
import { FilterModule } from "../../../../modules/FilterModule";
import { SkeletonBody } from "../../../../components/SkeletonBody";
import { useNavigate } from "react-router";
import { useURLParams } from "../../../../hooks/useURLParams";
import {
  INTERVAL_URL_VALUE,
  NAME_URL_VALUE,
  ORDER_STATUS_URL_VALUE,
  PAGE_URL_VALUE,
  USER_TYPE_URL_VALUE,
} from "../../../../constants/filterURLNames";

export const Requests = () => {
  const navigate = useNavigate();
  const urlParams = useURLParams();
  const interval = urlParams[INTERVAL_URL_VALUE];
  const name = urlParams[NAME_URL_VALUE];
  const status = urlParams[ORDER_STATUS_URL_VALUE];
  const userType = urlParams[USER_TYPE_URL_VALUE];
  const page = urlParams[PAGE_URL_VALUE];

  const currentPage = page ? +page : 1;

  const isMobile = isMobileDevice();

  const {
    data: requestsData,
    isLoading,
    isFetching,
    isError,
  } = useFetchRequests(currentPage, status, interval, name, userType) as {
    data: RequestsAPIAnswerType;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
  };

  const requestsList = React.useMemo(() => requestsData?.items, [requestsData]);
  const total = requestsData?.total;
  const pageCount = React.useMemo(() => {
    return Math.ceil(total / REQUESTS_SIZE);
  }, [total]);

  const handleRowClick = (id: number | string) => {
    navigate(`${id}`);
  };

  const handleChangePage = (page: number) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString();
    navigate({ search: queryString.stringify(urlParams) });
  };

  if (isLoading) {
    const mode = isMobile === true ? "windows" : "table";
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={5}
        actionMode={"pagination"}
        withFilters={true}
      />
    );
  }
  if (isError) {
    return <IncorretResult />;
  }

  const bodyCls = classNames("list-data-wrapper", {
    "list-data-wrapper_fetching": isFetching === true,
  });

  return (
    <div className={bodyCls}>
      <FilterModule
        title={"Заявки на регистрацию"}
        withOrderStatus
        withSearch
        withDateInterval
        withUserType
        className='list-data-wrapper__filters'
      />
      {isMobile ? (
        <>
          <RequestsMobile
            requestsList={requestsList}
            handleRowClick={handleRowClick}
          />
          <div className='list-data-wrapper-actions'>
            {pageCount > 1 && (
              <Pagination
                className='list-data-wrapper-actions__pagination'
                activeId={`${currentPage - 1}`}
                onChange={handleChangePage}
                count={pageCount}
              />
            )}
          </div>
        </>
      ) : (
        <RequestsTable
          requestsList={requestsList}
          handleRowClick={handleRowClick}
          handleChangePage={handleChangePage}
          currentPage={currentPage}
          btn={null}
          total={total}
          pageSize={REQUESTS_SIZE}
        />
      )}
    </div>
  );
};
