import React from 'react'
import { SkeletonForm } from '../SkeletonForm'
import { SkeletonHeaderStatistic } from '../SkeletonHeaderStatistic'
import { SkeletonInfoModule } from '../SkeletonInfoModule'
import './SkeletonFormPage.sass'

interface SkeletonFormPageProps {
  rightColumn: 'form' | 'statistic' | 'empty'
  leftFormCount: number
  withRegisterInfo: boolean
  statisticCount?: number
}

export const SkeletonFormPage: React.FC<SkeletonFormPageProps> = ({
  rightColumn,
  leftFormCount,
  withRegisterInfo,
  statisticCount = 5,
}) => {
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <SkeletonHeaderStatistic withRegisterInfo={withRegisterInfo} />
      </div>
      <div className='common-page-body common-page-body_double'>
        <SkeletonForm
          count={leftFormCount}
          className='common-page-body__left'
        />
        {rightColumn === 'statistic' && (
          <SkeletonInfoModule
            count={statisticCount}
            className='common-page-body__right'
          />
        )}
        {rightColumn === 'form' && (
          <SkeletonForm count={5} className='common-page-body__right' />
        )}
      </div>
    </div>
  )
}
