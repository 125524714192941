import { CATEGORIES_PATH, MAIN_PATH } from '../../constants/globalConstants'

export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Управление категориями',
    path: CATEGORIES_PATH,
  },
  {
    id: '2',
    name: 'Добавление новой категории',
    path: `${CATEGORIES_PATH}/new`,
  },
]
