import React from 'react'
import { useNavigate } from 'react-router'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './ConsumerGroupAddPage-constants'
import { useCreateGroupConsumer } from './ConsumerGroupAddPage-hooks'
import { GroupConsumerAddType } from './ConsumerGroupAddPage-types'
import './ConsumerGroupAddPage.sass'
import { ConsumerGroupAddForm } from './modules/ConsumerGroupAddForm'

export const ConsumerGroupAddPage = () => {
  const navigate = useNavigate()
  useBreadcrumbs(breadCrumbsData)

  const { mutateAsync: createConsumer, isLoading: loadingCreate } =
    useCreateGroupConsumer() as {
      mutateAsync: (consumerData: GroupConsumerAddType) => Promise<any>
      isLoading: boolean
    }

  const handleSubmit = async (userData: GroupConsumerAddType) => {
    await createConsumer(userData)
  }
  const handleCancel = () => {
    navigate(-1)
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Создание новой группы'} />
      </div>
      <div className='common-page-body'>
        <ConsumerGroupAddForm
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isLoading={loadingCreate}
        />
      </div>
    </div>
  )
}
