import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  ReportRequestFieldsType,
  ReportResponseFieldsType,
} from './ReportModule-types'
import { useReportGenerate } from './ReportModule-hooks'
import { Button } from '../../../../components/Button'
import { DatePickerCustom } from '../../../../components/DatePickerCustom'
import { getDateFromISO, getPrevMonthDate } from '../../../../utils/utils'
import './ReportModule.sass'
import { Popup } from '../../../../components/Popup'
import { useParams } from 'react-router'

export const ReportModule = () => {
  const { id } = useParams()
  const { mutateAsync: reportGenerate } = useReportGenerate(id!) as {
    mutateAsync: (reportValues: ReportRequestFieldsType) => Promise<any>
    isLoading: boolean
    isError: boolean
  }

  const initialValues = {
    startDate: getPrevMonthDate(),
    endDate: new Date(),
  }
  const validationSchema = Yup.object({
    startDate: Yup.date().required('Обязательно').max(new Date()),
    endDate: Yup.date().required('Обязательно').max(new Date()),
  })
  const onSubmit = async (values: ReportResponseFieldsType) => {
    await reportGenerate({
      startDate: getDateFromISO(values.startDate.toISOString()),
      endDate: getDateFromISO(values.endDate.toISOString()),
    })
  }

  return (
    <Popup>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
      >
        {(formik) => (
          <Form className={'report-form'}>
            <div className={`report-form-body`}>
              <DatePickerCustom
                name='startDate'
                title='Дата начала отчета'
                selected={formik.values['startDate']}
                errors={formik.errors['startDate']}
                onChange={(val) => formik.setFieldValue('startDate', val)}
                className={`report-form-body__control`}
              />
              <DatePickerCustom
                name='endDate'
                title='Дата конца отчета'
                selected={formik.values['endDate']}
                errors={formik.errors['endDate']}
                onChange={(val) => formik.setFieldValue('endDate', val)}
                className={`report-form-body__control`}
              />
            </div>
            <div className={`report-form-btns`}>
              <Button
                htmlType='submit'
                disabled={!formik.isValid}
                className={`report-form-btns__item report-form__control`}
              >
                Создать
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Popup>
  )
}
