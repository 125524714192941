import classNames from 'classnames'
import React from 'react'
import { Button } from '../../../../components/Button'
import { IncorretResult } from '../../../../components/IncorretResult'
import { NewsComponent } from '../../../../components/NewsComponent'
import { SkeletonNews } from '../../../../components/SkeletonNews'
import { Title } from '../../../../components/Title'
import {
  useFetchNews,
  useInvalidateNews,
  useSaveNews,
} from '../../NewsPage-hooks'
import { NewsArrayType } from '../../NewsPage-types'
import './NewsForm.sass'

interface NewsFormProps {
  className?: string
}

export const NewsForm: React.FC<NewsFormProps> = ({ className }) => {
  const formPrfx = 'news-form'
  const formCls = classNames(formPrfx, {
    [`${className}`]: className,
  })

  const { data, isLoading, isError } = useFetchNews() as {
    data: NewsArrayType
    isLoading: boolean
    isError: boolean
  }

  const [newsData, setNewsData] = React.useState<NewsArrayType>(data)

  React.useEffect(() => {
    setNewsData(data)
  }, [data])

  const handleChangeTags = (idx: number) => (tag: string) => {
    if (!tag) {
      return
    }
    const currentNewsTags = newsData[idx].tags
    if (!currentNewsTags.includes(tag)) {
      const copyNewsData = [...newsData]
      copyNewsData[idx].tags = [...currentNewsTags, tag]
      setNewsData(copyNewsData)
    }
  }
  const handleRemoveTags = (idx: number) => (tag: string) => {
    const currentTags = newsData[idx].tags
    const tagIndex = currentTags.indexOf(tag)
    const filteredTags = newsData[idx].tags.filter((_, i) => i !== tagIndex)
    const copyNewsData = [...newsData]
    copyNewsData[idx].tags = filteredTags
    setNewsData(copyNewsData)
  }

  const handleChangeText =
    (idx: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const copyNewsData = [...newsData]
      copyNewsData[idx].title = e.currentTarget.value
      setNewsData(copyNewsData)
    }

  const { mutateAsync: saveNews, isLoading: loadingSave } = useSaveNews() as {
    mutateAsync: (news: NewsArrayType) => Promise<any>
    isLoading: boolean
  }

  const { mutateAsync: invalidateNews, isLoading: loadingInvalidate } =
    useInvalidateNews() as {
      mutateAsync: () => Promise<any>
      isLoading: boolean
    }

  const handleSaveClick = () => {
    saveNews(newsData)
  }

  const handleInvalidateClick = () => {
    invalidateNews()
  }

  const infoPrfx = `${formPrfx}-info`
  const infoCls = classNames(infoPrfx, {
    [`${infoPrfx}_loading`]: loadingSave || loadingInvalidate,
  })

  if (isLoading) {
    return <SkeletonNews />
  }
  if (isError) {
    return <IncorretResult />
  }

  return (
    <div className={formCls}>
      <div className={infoCls}>
        <Title
          className={`${infoPrfx}__title`}
          text={'Новостная повестка ресурса'}
        />
        {data.map((news, idx) => (
          <NewsComponent
            className={`${infoPrfx}__control`}
            handleRemoveTags={handleRemoveTags(idx)}
            handleChangeTags={handleChangeTags(idx)}
            handleChangeText={handleChangeText(idx)}
            news={news}
            key={news.id ?? idx}
          />
        ))}
      </div>
      <div className={`${formPrfx}-btns`}>
        <Button
          className={`${formPrfx}-btns__item`}
          onClick={handleSaveClick}
          loading={loadingSave}
        >
          Сохранить
        </Button>
        <Button
          className={`${formPrfx}-btns__item`}
          onClick={handleInvalidateClick}
          loading={loadingInvalidate}
          type='secondary'
          border
        >
          Актуализировать
        </Button>
      </div>
    </div>
  )
}
