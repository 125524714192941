import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  PaymentOrderAcceptValuesType,
  PaymentOrderDeniedValuesType,
  PaymentOrderFormType,
} from '../../OrderEditPage-types'
import { Textarea } from '../../../../components/Textarea'
import { Button } from '../../../../components/Button'
import { isStatusValid } from '../../../../utils/utils'

interface OrderRequestFormProps {
  className?: string
  id: string
  status: string
  handleSubmit: (values: PaymentOrderAcceptValuesType) => void
  handleDeny: (values: PaymentOrderDeniedValuesType) => void
  loadingDeny: boolean
  loadingAccept: boolean
}

export const OrderRequestForm: React.FC<OrderRequestFormProps> = ({
  className,
  id,
  status,
  handleSubmit,
  handleDeny,
  loadingDeny,
  loadingAccept,
}) => {
  const initialValues = {
    description: '',
  }
  const validationSchema = Yup.object({
    description: Yup.string().required('Обязательно'),
  })

  const onSubmit = async (values: PaymentOrderFormType) => {
    handleSubmit({ id: id, description: values.description })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
    >
      {(formik) => (
        <Form className={'user-form'}>
          <div className='user-form-left'>
            <h2 className={`user-form__title`}>Описание заявки</h2>
            <Textarea
              type='text'
              id='description'
              title='Номер контракта'
              value={formik.values['description']}
              errors={formik.errors['description']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
          </div>
          {isStatusValid(status) && (
            <div className={`user-form-btns`}>
              <Button
                htmlType='submit'
                disabled={!formik.isValid}
                short
                className={`user-form-btns__item user-form__control`}
                loading={loadingAccept}
              >
                Принять
              </Button>
              <Button
                htmlType='button'
                short
                type='danger'
                disabled={!formik.isValid}
                className={`user-form-btns__item user-form__control`}
                loading={loadingDeny}
                onClick={() =>
                  handleDeny({
                    id,
                    description: formik.values['description'],
                  })
                }
              >
                Отклонить
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}
