import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { UserFormType } from '../../types/common'
import { adminAdd } from './AdminAddPage-api'

export const useCreateAdmin = () => {
  const navigate = useNavigate()
  const mutation = useMutation((values: UserFormType) => adminAdd(values), {
    onSuccess: async () => {
      toast.info('Администратор добавлен')
      navigate(-1)
    },
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось добавить администратора')
    },
  })

  return mutation
}
