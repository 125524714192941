import {
  GROUP_CONSUMERS_PATH,
  MAIN_PATH,
} from '../../constants/globalConstants'

export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Группы потребителей',
    path: GROUP_CONSUMERS_PATH,
  },
  {
    id: '2',
    name: 'Добавление новой группы',
    path: `${GROUP_CONSUMERS_PATH}/new`,
  },
]
