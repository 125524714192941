import React from 'react'
import { AuthModule } from './modules/AuthModule'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import './AuthPage.sass'

export const AuthPage = () => {
  return (
    <div className='auth-page'>
      <Logo className='auth-page__logo' />
      <AuthModule />
    </div>
  )
}
