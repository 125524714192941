import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { MODERATORS_CACHE_PATH } from '../../constants/globalConstants'
import { useNavigate } from 'react-router'
import {
  getIdModerator,
  getIdModeratorStatistics,
  moderatorChange,
  moderatorChangeStatus,
  moderatorRemove,
} from './ModeratorEditPage-api'
import { UserRequestType } from '../../types/common'

export const useModeratorById = (moderatorId: string) => {
  const query = useQuery(
    [MODERATORS_CACHE_PATH, { id: moderatorId, type: 'data' }],
    async () => {
      const moderatorById = await getIdModerator(moderatorId)
      return moderatorById
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useModeratorStatistics = (moderatorId: string) => {
  const query = useQuery(
    [MODERATORS_CACHE_PATH, { id: moderatorId, type: 'stat' }],
    async () => {
      const statisticsByModeratorId = await getIdModeratorStatistics(
        moderatorId
      )
      return statisticsByModeratorId
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useUpdateModerator = () => {
  const client = useQueryClient()
  const mutation = useMutation(
    (values: UserRequestType) => moderatorChange(values.id!, { ...values }),
    {
      onSuccess: async (data, variables, context) => {
        await client.invalidateQueries([
          MODERATORS_CACHE_PATH,
          { id: variables.id, type: 'data' },
        ])
        toast.info('Данные успешно обновлены')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось обновить данные')
      },
    }
  )

  return mutation
}

export const useRemoveModerator = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (moderatorId: string) => moderatorRemove(moderatorId),
    {
      onSuccess: async (data, variables, context) => {
        navigate(-1)
        toast.info('Модератор удален')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось удалить модератора')
      },
    }
  )
  return mutation
}

export const useChangeStatusModerator = () => {
  const client = useQueryClient()
  const mutation = useMutation(
    (moderatorId: string) => moderatorChangeStatus(moderatorId),
    {
      onSuccess: async (data, id, context) => {
        await client.invalidateQueries([
          MODERATORS_CACHE_PATH,
          { id: id, type: 'data' },
        ])
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось сменить статус')
      },
    }
  )
  return mutation
}
