import React from 'react'
import { RequestWindow } from '../../../../components/RequestWindow'
import { RequestsType } from '../../RequestsPage-types'

interface RequestsMobileProps {
  requestsList: RequestsType
  handleRowClick: (id: number | string) => void
}

export const RequestsMobile: React.FC<RequestsMobileProps> = ({
  requestsList,
  handleRowClick,
}) => {
  if (!requestsList.length) {
    return <span>Еще не оставлено ни одной заявки</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {requestsList.map((request) => (
          <RequestWindow
            className='windows-wrapper-windows__item'
            key={request.id}
            id={request.id}
            login={request.login}
            status={request.status}
            registerDate={request.registerDate}
            userType={request.userType}
            onClick={handleRowClick}
          />
        ))}
      </div>
    </div>
  )
}
