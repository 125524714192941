import classNames from 'classnames'
import React from 'react'
import { NewsType } from '../../pages/NewsPage/NewsPage-types'
import './SnatchNewsComponent.sass'

interface SnatchNewsComponentProps {
  className?: string
  news: NewsType
}

export const SnatchNewsComponent: React.FC<SnatchNewsComponentProps> = ({
  className,
  news,
}) => {
  const newsPrfx = 'snatched-news'
  const newsCls = classNames(newsPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={newsCls}>
      <h3 className={`${newsPrfx}__title`}>
        {news?.title ?? 'Не удалось определить'}
      </h3>
      <div className={`${newsPrfx}-tags`}>
        {news.tags.map((tag, idx) => (
          <span className={`${newsPrfx}-tags__item`} key={idx}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  )
}
