import React, { ReactElement } from "react";
import { Table, Row, Pagination } from "antd/es";
import type { ColumnsType } from "antd/es/table";
import type { PaginationProps, TableProps } from "antd";

interface TablePropsMain extends TableProps<any> {
  columns: ColumnsType<any>;
  data: any;
  handleRowClick?: (id: string) => void;
  currentPage: number;
  btn: ReactElement<any, any> | null;
  handleChangePage: (page: number) => void;
  pageSize: number;
  total: number;
}

interface PaginationPropsMain {
  paginationProps: PaginationProps;
  btn: ReactElement<any, any> | null;
}
const AntTable: React.FC<TablePropsMain> = ({
  columns,
  data,
  handleRowClick,
  handleChangePage,
  currentPage,
  btn,
  pageSize,
  total,
}) => {
  const pagination = {
    current: currentPage,
    total,
    defaultCurrent: currentPage,
    pageSize,
    hideOnSinglePage: true,
    onChange: (page: number, pageSize: number) => {
      handleChangePage(page - 1);
    },
    showSizeChanger: false,
    showQuickJumper: total > 1000,
  };
  return (
    <Table
      onRow={(record, rowIndex) => {
        return {
          onClick: () => {
            if (handleRowClick) {
              handleRowClick(record.id || rowIndex);
            }
          },
        };
      }}
      rowKey={(record) => {
        return record.id || record.searchDate;
      }}
      bordered
      columns={columns}
      dataSource={data}
      pagination={false}
      footer={() => <TableFooter paginationProps={pagination} btn={btn} />}
    />
  );
};
const TableFooter: React.FC<PaginationPropsMain> = ({
  paginationProps,
  btn,
}) => {
  return (
    <Row justify='space-between'>
      <Row>{btn}</Row>
      <Pagination {...paginationProps} />
    </Row>
  );
};

export default AntTable;
