import React from 'react'
import { RejectionAddForm } from './modules/RejectionAddForm'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { RejectionRequestType } from '../RejectionsPage/RejectionsPage-types'
import { breadCrumbsData } from './RejectionAddPage-constants'
import { useCreateRejection } from './RejectionAddPage-hooks'
import { useNavigate } from 'react-router'

export const RejectionAddPage = () => {
  const navigate = useNavigate()
  useBreadcrumbs(breadCrumbsData)

  const { mutateAsync: createRejection, isLoading: loadingCreate } =
    useCreateRejection() as {
      mutateAsync: (rejectionData: RejectionRequestType) => Promise<any>
      isLoading: boolean
    }

  const handleSubmit = (rejectionData: RejectionRequestType) => {
    createRejection(rejectionData)
  }
  const handleCancel = () => {
    navigate(-1)
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Добавление причины отказа'} />
      </div>
      <div className='common-page-body'>
        <RejectionAddForm
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isLoading={loadingCreate}
        />
      </div>
    </div>
  )
}
