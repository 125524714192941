export enum USER_STATUS_ENUM {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  LOCKED = 'locked',
}

export enum REPORT_STATUS_ENUM {
  PROCESSING = 'processing',
  DONE = 'done',
  ERROR = 'error',
}

export enum MATERIAL_STATUS_ENUM {
  NEW = 'new',
  WAITING = 'waiting',
  ACCEPT = 'accept',
  DENY = 'deny',
}

export enum ORDER_STATUS_ENUM {
  NEW = 'new',
  WAITING = 'waiting',
  ACCEPT = 'accept',
  DENY = 'deny',
}

export enum REPORT_TYPE_ENUM {
  CONSUMER = 0,
  CONTRIBUTOR_BUY = 1,
  CONTRIBUTR_EXCLUSIVE_BUY = 2,
  CONTRIBUTOR_UPLOAD = 3,
  CONTRIBUTOR_EXCLUSIVE_UPLOAD = 4,
}

export enum REPORT_ACTION_ENUM {
  UPLOADED = 'uploaded',
  PURCASED = 'purchased',
}

export enum REPORT_RIGHTS_ENUM {
  EXCLUSIVE = 'exclusive',
  NOT_EXCLUSIVE = 'not_exclusive',
}
