import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router'
import { FORBIDDEN_PATH } from '../../constants/globalConstants'
import { isSuperAdmin } from '../../utils/storage'

export const RequireSuperAdmin = () => {
  const isUserSuperAdmin = isSuperAdmin()
  const location = useLocation()
  if (!isUserSuperAdmin) {
    return <Navigate to={FORBIDDEN_PATH} state={{ from: location }} />
  }
  return <Outlet />
}
