import { GROUP_CONTRIBUTORS_API_PATH } from '../constants/globalConstants'
import { GroupListItemType, GroupListType } from '../types/group'
import { instance } from '../utils/coreAPI'

export const fetchContributorGroupList = async () => {
  try {
    const response = await instance.get(`${GROUP_CONTRIBUTORS_API_PATH}/list`)
    const data: GroupListType = response.data

/*     const withoutGroupOption: GroupListItemType = {
      id: '0',
      groupName: 'Без группы',
    } */
    const dataWithoutGroupOption = [...data,/*  withoutGroupOption */]
    return dataWithoutGroupOption
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
