import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router'
import { FORBIDDEN_PATH } from '../../constants/globalConstants'
import { isAdmin } from '../../utils/storage'

export const RequireAdmin = () => {
  const isUserAdmin = isAdmin()
  const location = useLocation()
  if (!isUserAdmin) {
    return <Navigate to={FORBIDDEN_PATH} state={{ from: location }} />
  }
  return <Outlet />
}
