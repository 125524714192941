export const orderStatusOptions = [
  {
    label: 'Все',
    value: undefined,
  },
  {
    label: 'Новые',
    value: 'new',
  },
  {
    label: 'Модерация',
    value: 'waiting',
  },
  {
    label: 'Одобрено',
    value: 'accept',
  },
  {
    label: 'Отказано',
    value: 'deny',
  },
]
