import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router'
import { FormTitle } from '../../../../components/FormTitle'
import { IncorretResult } from '../../../../components/IncorretResult'
import { SkeletonInfoModule } from '../../../../components/SkeletonInfoModule'
import { StatisticItem } from '../../../../components/StatisticItem'
import {
  FAVORITE_INTERVAL_URL_VALUE,
  PURCHASE_INTERVAL_URL_VALUE,
} from '../../../../constants/filterURLNames'
import { ConsumerStatisticAPIAnswerType } from '../../../../types/consumer'

interface InfoModuleProps {
  className?: string
  isLoading: boolean
  isError: boolean
  statisticData: ConsumerStatisticAPIAnswerType
}

export const InfoModule: React.FC<InfoModuleProps> = React.memo(
  ({ className, isLoading, isError, statisticData }) => {
    const navigate = useNavigate()
    const modulePrfx = 'module-info'
    const divCls = classNames(modulePrfx, {
      [`${className}`]: className,
    })

    if (isLoading) {
      return (
        <div className={divCls}>
          <SkeletonInfoModule count={4} />
        </div>
      )
    }
    if (isError) {
      return <IncorretResult />
    }

    const purchasedTotal = `${statisticData?.total?.purchased}` ?? '-'
    const purchasedMonth = `${statisticData?.monthly?.purchased}` ?? '-'

    const favouriteTotal = `${statisticData?.total?.favourites}` ?? '-'
    const favouriteMonth = `${statisticData?.monthly?.favourites}` ?? '-'

    const viewedTotal = `${statisticData?.total?.viewed}` ?? '-'
    const viewedMonth = `${statisticData?.monthly?.viewed}` ?? '-'

    const handleMonthPurchasedClick = () => {
      navigate(`../purchased?${PURCHASE_INTERVAL_URL_VALUE}=begin`)
    }
    const handleTotalPurchasedClick = () => {
      navigate('../purchased')
    }
    const handleMonthFavouriteClick = () => {
      navigate(`../favourite?${FAVORITE_INTERVAL_URL_VALUE}=begin`)
    }
    const handleTotalFavouriteClick = () => {
      navigate('../favourite')
    }

    return (
      <div className={divCls}>
        <FormTitle text={'Статистика потребителя'} />
        <div className={`${modulePrfx}-body`}>
          <StatisticItem
            className={`${modulePrfx}-body__item`}
            title={'Куплено за этот месяц'}
            value={purchasedMonth}
            onClick={handleMonthPurchasedClick}
          />
          <StatisticItem
            className={`${modulePrfx}-body__item`}
            title={'Куплено всего'}
            value={purchasedTotal}
            onClick={handleTotalPurchasedClick}
          />
          <StatisticItem
            className={`${modulePrfx}-body__item`}
            title={'Избранное за этот месяц'}
            value={favouriteMonth}
            onClick={handleMonthFavouriteClick}
          />
          <StatisticItem
            className={`${modulePrfx}-body__item`}
            title={'Избранного всего'}
            value={favouriteTotal}
            onClick={handleTotalFavouriteClick}
          />
          <StatisticItem
            title={'Просмотрено за этот месяц'}
            value={viewedMonth}
            className={`${modulePrfx}-body__item`}
          />
          <StatisticItem
            title={'Просмотрено всего'}
            value={viewedTotal}
            className={`${modulePrfx}-body__item`}
          />
        </div>
      </div>
    )
  }
)
