import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { RejectionRequestType } from '../RejectionsPage/RejectionsPage-types'
import { rejectionAdd } from './RejectionAddPage-api'

export const useCreateRejection = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (values: RejectionRequestType) => rejectionAdd(values),
    {
      onSuccess: async () => {
        toast.info('Ограничение добавлено')
        navigate(-1)
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось добавить ограничение')
      },
    }
  )

  return mutation
}
