import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { Requests } from './modules/Requests'
import { breadCrumbsData } from './RequestsPage-constants'

export const RequestsPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <Requests />
      </div>
    </div>
  )
}
