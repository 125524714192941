import React, { ReactElement } from "react";
import AntTable from "../../../../components/Table/AntTable";
import { RejectionsType } from "../../../../types/common";
import { COLUMNS } from "./RejectionsTable-constants";

interface RejectionsTableProps {
  rejectionsList: RejectionsType;
  handleRowClick: (id: string) => void;
  handleChangePage: (page: number) => void;
  currentPage: number;
  btn: ReactElement<any, any> | null;
  total: number;
  pageSize: number;
}

export const RejectionsTable: React.FC<RejectionsTableProps> = ({
  rejectionsList,
  handleRowClick,
  handleChangePage,
  currentPage,
  btn,
  total,
  pageSize,
}) => {
  const antColumns = React.useMemo(() => COLUMNS, []) as any;
  const data = React.useMemo(() => rejectionsList, [rejectionsList]);

  return (
    <AntTable
      columns={antColumns}
      data={data}
      currentPage={currentPage}
      handleChangePage={handleChangePage}
      handleRowClick={handleRowClick}
      btn={btn}
      total={total}
      pageSize={pageSize}
    />
  );
};
