import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  RequestAcceptValuesType,
  RequestFormValuesType,
} from '../../RequestEditPage-types'
import { Input, InputNumber } from '../../../../components/Input'
import { DatePickerCustom } from '../../../../components/DatePickerCustom'
import { Button } from '../../../../components/Button'
import { getDateFromISO } from '../../../../utils/utils'
import { ContractType } from '../../../../types/contract'

interface ConsumerRequestFormProps {
  className?: string
  id: string
  handleSubmit: (values: RequestAcceptValuesType) => void
  handleDeny: (id: string) => void
  loadingDeny: boolean
  loadingAccept: boolean
}

export const ConsumerRequestForm: React.FC<ConsumerRequestFormProps> = ({
  id,
  handleSubmit,
  handleDeny,
  loadingDeny,
  loadingAccept,
}) => {
  const initialValues = {
    number: '',
    title: '',
    description: '',
    startDate: new Date(),
    expiredDate: new Date(),
    limit: 0,
  }
  const validationSchema = Yup.object({
    limit: Yup.number().required('Обязательно'),
    number: Yup.string().required('Обязательно'),
    title: Yup.string().required('Обязательно'),
    description: Yup.string().required('Обязательно'),
    startDate: Yup.date().required('Обязательно').max(new Date()),
    expiredDate: Yup.date().required('Обязательно'),
  })

  const onSubmit = async (values: RequestFormValuesType) => {
    const contractObject: ContractType = {
      title: values.title,
      number: values.number,
      description: values.description,
      startDate: getDateFromISO(values.startDate.toISOString()),
      expiredDate: getDateFromISO(values.expiredDate.toISOString()),
    }
    handleSubmit({ id: id, contract: contractObject, limit: values.limit })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={false}
    >
      {(formik) => (
        <Form className={'user-form'}>
          <div className={'user-form-left'}>
            <h2 className={`user-form__title`}>Информация о контракте</h2>
            <Input
              type='text'
              id='number'
              title='Номер контракта'
              value={formik.values['number']}
              errors={formik.errors['number']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <Input
              type='text'
              id='title'
              title='Название контракта'
              value={formik.values['title']}
              errors={formik.errors['title']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <Input
              type='text'
              id='description'
              title='Описание'
              value={formik.values['description']}
              errors={formik.errors['description']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <DatePickerCustom
              name='startDate'
              title='Дата начала контракта'
              selected={formik.values['startDate']}
              errors={formik.errors['startDate']}
              onChange={(val) => formik.setFieldValue('startDate', val)}
              className={`user-form__control`}
            />
            <DatePickerCustom
              name='expiredDate'
              title='Дата конца контракта'
              selected={formik.values['expiredDate']}
              errors={formik.errors['expiredDate']}
              onChange={(val) => formik.setFieldValue('expiredDate', val)}
              className={`user-form__control`}
            />
            <InputNumber
              name='limit'
              className={`user-form__control`}
              min={0}
              max={1000}
              step={1}
              type='number'
              title={'Лимит скачивания в месяц:'}
              value={formik.values['limit']}
              onChange={formik.handleChange}
            />
          </div>
          <div className={`user-form-btns`}>
            <Button
              htmlType='submit'
              disabled={!formik.isValid}
              className={`user-form-btns__item user-form__control`}
              loading={loadingAccept}
            >
              Принять
            </Button>
            <Button
              htmlType='button'
              type='danger'
              className={`user-form-btns__item user-form__control`}
              loading={loadingDeny}
              onClick={() => handleDeny(id)}
            >
              Отклонить
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
