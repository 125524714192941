import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { ROLE_CONTRIBUTOR } from '../../../../constants/common'
import { REPORT_CACHE_PATH } from '../../../../constants/globalConstants'
import { reportGenerate } from './ReportModule-api'
import { ReportRequestFieldsType } from './ReportModule-types'

export const useReportGenerate = (id: string) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    (values: ReportRequestFieldsType) =>
      reportGenerate(
        id,
        values.startDate,
        values.endDate,
        values.rights,
        values.action
      ),
    {
      onMutate: () => {
        toast.info(
          'Отчет будет сформирован в ближайшее время. Вы можете продолжить пользоваться сервисом.'
        )
      },
      onSuccess: async (data, variables, context) => {
        navigate('../edit?page=1')
        queryClient.invalidateQueries([
          REPORT_CACHE_PATH,
          { id, page: 1, type: ROLE_CONTRIBUTOR },
        ])
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось создать отчет')
      },
    }
  )

  return mutation
}
