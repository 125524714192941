import {
  GROUP_CONSUMERS_API_PATH,
  REPORT_API_PATH,
} from '../../constants/globalConstants'
import { ConsumerGroupEditType } from '../../types/group'
import { instance } from '../../utils/coreAPI'
import { REPORT_SIZE } from './ConsumerGroupEditPage-constants'

export const consumerGroupReportGenerate = async (
  id: string,
  startDate: string,
  endDate: string
) => {
  try {
    const response = await instance.post(
      `${REPORT_API_PATH}/consumer_group/${id}`,
      {
        startDate,
        endDate,
      }
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getGroupConsumerById = async (id: string) => {
  try {
    const response = await instance.get(`${GROUP_CONSUMERS_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const groupConsumerUpdate = async (
  id: string,
  data: ConsumerGroupEditType
) => {
  try {
    const PATH = `${GROUP_CONSUMERS_API_PATH}/${id}`
    const response = await instance.patch(PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getConsumerGroupReports = async (id: string, page: number) => {
  try {
    const response = await instance.get(`${REPORT_API_PATH}`, {
      params: {
        consumerGroup: id,
        page: page,
        size: REPORT_SIZE,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
