import { REPORT_API_PATH } from '../../../../constants/globalConstants'
import { instance } from '../../../../utils/coreAPI'

export const reportGenerate = async (
  id: string,
  startDate: string,
  endDate: string
) => {
  try {
    const response = await instance.post(`${REPORT_API_PATH}/consumer/${id}`, {
      startDate,
      endDate,
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
