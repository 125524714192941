import React from 'react'
import { GroupWindow } from '../../../../components/GroupWindow'
import { ContributorGroupType } from '../../ContributorGroupPage-types'
import './GroupsMobile.sass'

interface GroupsMobileProps {
  className?: string
  groupList: ContributorGroupType[]
  handleRowClick: (id: string) => void
}

export const GroupsMobile: React.FC<GroupsMobileProps> = ({
  groupList,
  handleRowClick,
}) => {
  if (!groupList.length) {
    return <span>Еще не создано ни одной группы</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {groupList.map((group) => (
          <GroupWindow
            className='windows-wrapper-windows__item'
            key={group.id}
            name={group.groupName}
            dateStart={group.contract.startDate}
            dateFinish={null}
            membersCount={group.membersCount}
            onClick={() => handleRowClick(`${group.id}`)}
          />
        ))}
      </div>
    </div>
  )
}
