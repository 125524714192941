import classNames from 'classnames'
import React from 'react'
import './StatisticItem.sass'

// TODO: Добавить возможность Link'a

interface StatisticItemProps {
  className?: string
  title: string
  value: string
  green?: boolean
  danger?: boolean
  light?: boolean
  bold?: boolean
  suffix?: string
  onClick?: () => void
}

export const StatisticItem: React.FC<StatisticItemProps> = ({
  className,
  title,
  value,
  green,
  danger,
  suffix,
  onClick,
}) => {
  const statPrfx = 'statistic-item'
  const divCls = classNames(statPrfx, {
    [`${className}`]: className,
  })
  const titleCls = classNames(`${statPrfx}__title`, {})
  const valueCls = classNames(`${statPrfx}__value`, {
    [`${statPrfx}__value_green`]: green,
    [`${statPrfx}__value_danger`]: danger,
  })
  return (
    <div className={divCls} onClick={onClick}>
      <span className={titleCls}>{`${title}`}</span>
      <span className={valueCls}>
        {value} {suffix}
      </span>
    </div>
  )
}
