import { materialStatusOptions } from './FilterMaterialStatus-constants'

export const getMaterialStatusOption = (value: string | string[] | null) => {
  if (!value) return materialStatusOptions[0]
  let currentValue = '-1'
  typeof value === 'object' ? (currentValue = value[0]) : (currentValue = value)
  const materialStatusOption = materialStatusOptions.filter(
    (option) => option.value === currentValue
  )
  return materialStatusOption[0] ?? materialStatusOptions[0]
}
