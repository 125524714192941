import React from "react";
import Select from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Input,
  InputNumber,
  InputPassword,
} from "../../../../components/Input";
import { Button } from "../../../../components/Button";
import { BsTrash } from "react-icons/bs";
import { phoneRe } from "../../../../utils/regulars";
import { DatePickerCustom } from "../../../../components/DatePickerCustom";
import { getDateFromISO } from "../../../../utils/utils";
import { InputPhone } from "../../../../components/InputPhone";
import { FormTitle } from "../../../../components/FormTitle";
import {
  ConsumerAddFormType,
  ConsumerAddType,
} from '../../../../types/consumer'
import {ConsumerGroupType} from '../../../../pages/ConsumerGroupPage/ConsumerGroupPage-types'

export type GroupListType = ConsumerGroupType[]

interface ConsumerAddFormProps {
  className?: string;
  handleSubmit: (values: ConsumerAddType) => void;
  handleCancel: () => void;
  isLoading: boolean;
  groups: GroupListType;
  errorGroups: boolean;
  loadingGroups: boolean;
}

export const ConsumerAddForm: React.FC<ConsumerAddFormProps> = ({
  handleSubmit,
  handleCancel,
  isLoading = false,
  groups,
  errorGroups,
  loadingGroups,
}) => {
  console.log(groups);

  const initialValues = {
    login: "",
    name: "",
    password: "",
    email: "",
    phone: "",
    limit: 0,
    groupId: "0",
    number: "",
    title: "",
    description: "",
    startDate: new Date(),
    expiredDate: new Date(),
  };

  const validationSchema = Yup.object({
    login: Yup.string().required("Обязательно"),
    name: Yup.string().required("Обязательно"),
    password: Yup.string().required("Обязательно"),
    email: Yup.string().email().required("Обязательно"),
    phone: Yup.string()
      .matches(phoneRe, "Неверный формат номера")
      .required("Обязательно"),
    groupId: Yup.string(),
    limit: Yup.number().required("Обязательно"),
    number: Yup.string().required("Обязательно"),
    title: Yup.string().required("Обязательно"),
    description: Yup.string().required("Обязательно"),
    startDate: Yup.date().required("Обязательно"),
    expiredDate: Yup.date().required("Обязательно").min(Yup.ref("startDate")),
  });
  const onSubmit = async (values: ConsumerAddFormType) => {
    const submitObject: ConsumerAddType = {
      login: values.login,
      password: values.password,
      name: values.name,
      email: values.email,
      phone: values.phone
        ? values.phone
            .replace(/\)/g, "")
            .replace(/\(/g, "")
            .replace(/-/g, "")
            .replace(/ /g, "")
        : values.phone,
      limit: values.limit,
      ...(values.groupId !== "0" && { groupId: values.groupId }),
      contract: {
        title: values.title,
        number: values.number,
        description: values.description,
        startDate: getDateFromISO(values.startDate.toISOString()),
        expiredDate: getDateFromISO(values.expiredDate.toISOString()),
      },
    };
    handleSubmit(submitObject);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={false}
    >
      {(formik) => (
        <Form className={"user-form user-form_double"}>
          <div className={`user-form-left`}>
            <FormTitle text='Персональная информация' />
            <Input
              type='text'
              id='login'
              title='Логин'
              value={formik.values["login"]}
              errors={formik.errors["login"]}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <Input
              type='text'
              id='name'
              title='Имя'
              value={formik.values["name"]}
              errors={formik.errors["name"]}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <InputPassword
              type='password'
              id='password'
              title='Пароль'
              value={formik.values["password"]}
              errors={formik.errors["password"]}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <Input
              type='email'
              id='email'
              title='Почта'
              value={formik.values["email"]}
              errors={formik.errors["email"]}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <InputPhone
              id='phone'
              value={formik.values["phone"]}
              errors={formik.errors["phone"]}
              onChange={formik.handleChange}
              title='Номер телефона'
              className={`user-form__control`}
            />
            {!errorGroups && !loadingGroups && (
              <Select
                className='react-select-container contributor-edit-form__control contributor-edit-form__control_margin-top'
                classNamePrefix='react-select'
                placeholder='Выберите группу'
                name='groupId'
                isClearable                
                onChange={(option: any) => {  

                    formik.setValues({
                      ...formik.values, 
                      groupId: option?.id || "",
                      title: option?.contract?.title || "",
                      number: option?.contract?.number || "",
                      description: option?.contract?.description || "",
                      startDate: option ? new Date(option?.contract?.startDate) : new Date(),
                      expiredDate: option ? new Date(option?.contract?.expiredDate): new Date()
                    })                      
                }}
                getOptionLabel={(option) => option.groupName}
                getOptionValue={(option) => String(option.id)}
                options={groups}
              />
            )}
            <InputNumber
              name='limit'
              className={`user-form__control`}
              min={0}
              max={1000}
              step={1}
              type='number'
              title={"Лимит скачивания в месяц:"}
              value={formik.values["limit"]}
              onChange={formik.handleChange}
            />
          </div>
          <div className={`user-form-right`}>
            <FormTitle text='Параметры контракта' />
            <Input
              type='text'
              id='number'
              title='Номер контракта'
              value={formik.values["number"]}
              errors={formik.errors["number"]}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <Input
              type='text'
              id='title'
              title='Название контракта'
              value={formik.values["title"]}
              errors={formik.errors["title"]}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <Input
              type='text'
              id='description'
              title='Описание'
              value={formik.values["description"]}
              errors={formik.errors["description"]}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
            <DatePickerCustom
              name='startDate'
              title='Дата начала контракта'
              selected={formik.values["startDate"]}
              errors={formik.errors["startDate"]}
              onChange={(val) => formik.setFieldValue("startDate", val)}
              className={`user-form__control`}
            />
            <DatePickerCustom
              name='expiredDate'
              title='Дата конца контракта'
              selected={formik.values["expiredDate"]}
              errors={formik.errors["expiredDate"]}
              onChange={(val) => formik.setFieldValue("expiredDate", val)}
              className={`user-form__control`}
            />
          </div>
          <div className={`user-form-btns`}>
            <Button
              htmlType='submit'
              disabled={!formik.isValid}
              className={`user-form-btns__item user-form__control`}
              loading={isLoading}
            >
              Сохранить изменения
            </Button>
            <Button
              htmlType='button'
              type='transparent'
              className={`user-form-btns__item user-form__control`}
              onClick={handleCancel}
            >
              Отмена{" "}
              <span>
                <BsTrash />
              </span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
