import { MATERIAL_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { MATERIALS_SIZE } from './FavouriteMaterialsPage-constants'

export const getFavouriteListCall = async (
  id: string | undefined,
  page: number,
  interval: string | undefined,
  sort: string | undefined,
  favoriteInterval: string | undefined = undefined
) => {
  const config = {
    params: {
      page: page,
      size: MATERIALS_SIZE,
      interval,
      sort,
      favoriteInterval,
    },
  }
  try {
    const response = await instance.get(
      `${MATERIAL_API_PATH}/favorites/${id}`,
      config
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error?.response?.data.detail)
  }
}
