import React from 'react'
import { CategoryAddForm } from './modules/CategoryAddForm'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { CategoryRequestType } from '../CategoriesPage/CategoriesPage-types'
import { breadCrumbsData } from './CategoriesAddPage-constants'
import { useCreateCategory } from './CaterogiesAddPage-hooks'
import { useNavigate } from 'react-router'

export const CaterogiesAddPage = () => {
  const navigate = useNavigate()
  useBreadcrumbs(breadCrumbsData)

  const { mutateAsync: createCategory, isLoading: loadingCreate } =
    useCreateCategory() as {
      mutateAsync: (categoryData: CategoryRequestType) => Promise<any>
      isLoading: boolean
    }

  const handleSubmit = async (categoryData: CategoryRequestType) => {
    await createCategory(categoryData)
  }
  const handleCancel = () => {
    navigate(-1)
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Добавление категории'} />
      </div>
      <div className='common-page-body'>
        <CategoryAddForm
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isLoading={loadingCreate}
        />
      </div>
    </div>
  )
}
