import classNames from 'classnames'
import React from 'react'
import { ReportAPIAnswerType } from '../../types/report'
import { Pagination } from '../Pagination'
import { ReportListItem } from '../ReportListItem'
import { SkeletonReportList } from '../SkeletonReportList'
import './ReportList.sass'

interface ReportListProps {
  className?: string
  currentPage: number
  isLoading: boolean
  reports: ReportAPIAnswerType
  handlePageChange: (page: string) => void
}

export const ReportList: React.FC<ReportListProps> = React.memo(
  ({ className, isLoading, reports, currentPage, handlePageChange }) => {
    const itemPrfx = 'report-list'
    const itemCls = classNames(itemPrfx, {
      [`${className}`]: className,
    })

    if (isLoading) {
      return <SkeletonReportList />
    }

    if (!reports.items.length) {
      return <span>По пользователю еще нет отчётов</span>
    }

    const total = reports?.total
    const size = reports?.size

    const pageCount = Math.ceil(total / size)

    return (
      <div className={itemCls}>
        {reports.items.map((report, idx) => (
          <ReportListItem
            key={idx}
            reportItem={report}
            className={`${itemPrfx}__item`}
          />
        ))}
        {pageCount > 1 && (
          <Pagination
            className={`${itemPrfx}__pagination`}
            activeId={`${currentPage - 1}`}
            onChange={handlePageChange}
            count={pageCount}
          />
        )}
      </div>
    )
  }
)
