import classNames from "classnames";
import React from "react";
import Select from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  ContributorAddFormType,
  ContributorEditType,
  ContributorType,
} from "../../../../types/contributor";
import "./ContributorEditForm.sass";
import { phoneRe } from "../../../../utils/regulars";
import { getModifiedValue } from "../../../../utils/check";
import { getDateFromISO } from "../../../../utils/utils";
import { FormTitle } from "../../../../components/FormTitle";
import { WrapperDoubleLine } from "../../../../components/WrapperDoubleLine";
import { Input, InputPassword } from "../../../../components/Input";
import { InputPhone } from "../../../../components/InputPhone";
import { DatePickerCustom } from "../../../../components/DatePickerCustom";
import { Button } from "../../../../components/Button";
import { AccountButtons } from "../../../../components/AccountButtons";
import { GroupListType } from "../../../../types/group";

interface ContributorEditFormProps {
  className?: string;
  contributorData: ContributorType;
  groups: GroupListType;
  loadingChange: boolean;
  loadingDelete: boolean;
  loadingChangeStatus: boolean;
  errorGroups: boolean;
  handleDelete: (id: string) => void;
  handleChangeStatus: (id: string) => void;
  handleSubmit: (values: ContributorEditType) => void;
}

export const ContributorEditForm: React.FC<ContributorEditFormProps> = ({
  className,
  contributorData,
  groups,
  loadingChange,
  loadingDelete,
  loadingChangeStatus,
  handleDelete,
  handleSubmit,
  handleChangeStatus,
  errorGroups,
}) => {
  const { name, login, phone, email, status, id, group, contract } =
    contributorData;
  const formPrfx = "contributor-edit-form";
  const formCls = classNames(formPrfx, {
    [`${className}`]: className,
  });

  const groupId = group?.id;

  const initialValues = {
    name: name,
    password: "",
    login: login,
    phone: phone,
    email: email,
    groupId: groupId ?? "",

    number: contract?.number ?? "",
    title: contract?.title ?? "",
    description: contract?.description ?? "",
    startDate: contract?.startDate ? new Date(contract.startDate) : new Date(),
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Обязательно"),
    login: Yup.string().required("Обязательно"),
    password: Yup.string(),
    email: Yup.string().email().required("Обязательно"),
    phone: Yup.string()
      .matches(phoneRe, "Неверный формат номера")
      .required("Обязательно"),
    groupId: Yup.string(),
    number: Yup.string().required("Обязательно"),
    title: Yup.string().required("Обязательно"),
    description: Yup.string(),
    startDate: Yup.date().required("Обязательно").max(new Date()),
  });
  const onSubmit = async (values: ContributorAddFormType) => {
    const modifiedValues: Partial<ContributorAddFormType> = getModifiedValue(
      values,
      initialValues
    );
    const modifiedContractValues = {
      contract: {
        ...(modifiedValues?.number && { number: modifiedValues.number }),
        ...(modifiedValues?.title && {
          title: modifiedValues.title,
        }),
        ...(modifiedValues?.description && {
          description: modifiedValues.description,
        }),
        ...(modifiedValues?.startDate && {
          startDate: getDateFromISO(modifiedValues.startDate.toISOString()),
        }),
      },
    };
    const modifiedValuesForRequest = {
      ...(modifiedValues?.name && { name: modifiedValues.name }),
      ...(modifiedValues?.login && { login: modifiedValues.login }),
      ...(modifiedValues?.password && { password: modifiedValues.password }),
      ...(modifiedValues?.email && { email: modifiedValues.email }),
      ...(modifiedValues?.groupId && { groupId: modifiedValues.groupId }),
      ...(modifiedValues?.phone && {
        phone: modifiedValues.phone
          .replace(/\)/g, "")
          .replace(/\(/g, "")
          .replace(/-/g, "")
          .replace(/ /g, ""),
      }),
      ...modifiedContractValues,
    };
    if(modifiedValues?.groupId === '') modifiedValuesForRequest.groupId = 0;
    
    handleSubmit(modifiedValuesForRequest);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={false}
    >
      {(formik) => (
        <>
          <Form className={formCls}>
            <FormTitle text={"Персональная информация"} />
            <WrapperDoubleLine>
              <Input
                type='text'
                id='login'
                title='Логин'
                value={formik.values["login"]}
                errors={formik.errors["login"]}
                onChange={formik.handleChange}
                className={`contributor-edit-form__control`}
              />
              <Input
                type='text'
                id='name'
                title='Имя'
                value={formik.values["name"]}
                errors={formik.errors["name"]}
                onChange={formik.handleChange}
                className={`contributor-edit-form__control`}
              />
            </WrapperDoubleLine>
            <InputPassword
              type='password'
              id='password'
              title='Пароль'
              value={formik.values["password"]}
              errors={formik.errors["password"]}
              onChange={formik.handleChange}
              className={`contributor-edit-form__control`}
            />
            <WrapperDoubleLine>
              <Input
                type='email'
                id='email'
                title='Почта'
                value={formik.values["email"]}
                errors={formik.errors["email"]}
                onChange={formik.handleChange}
                className={`contributor-edit-form__control`}
              />
              <InputPhone
                id='phone'
                value={formik.values["phone"]}
                errors={formik.errors["phone"]}
                onChange={formik.handleChange}
                title='Номер телефона'
                className={`contributor-edit-form__control`}
              />
            </WrapperDoubleLine>
            {!errorGroups && (
              <Select
                className='react-select-container contributor-edit-form__control contributor-edit-form__control_margin-top'
                classNamePrefix='react-select'
                placeholder='Выберите группу'
                name='groupId'
                defaultValue={formik.values["groupId"] && groups.find(
                  (group) => group.id === formik.values["groupId"]
                )}
                onChange={(option: any) => {
                  formik.setFieldValue("groupId", option?.id || "");
                }}
                getOptionLabel={(option) => (option) ? option.groupName : ""}
                getOptionValue={(option) => (option) ? option?.id : ""}
                options={groups}
                isClearable
              />
            )}
            <FormTitle text={"Информация о контракте"} />
            <WrapperDoubleLine>
              <Input
                id='number'
                type='text'
                title='Номер'
                value={formik.values["number"]}
                errors={formik.errors["number"]}
                onChange={formik.handleChange}
                className={`contributor-edit-form__control`}
              />
              <Input
                type='text'
                id='title'
                title='Название'
                value={formik.values["title"]}
                errors={formik.errors["title"]}
                onChange={formik.handleChange}
                className={`contributor-edit-form__control`}
              />
            </WrapperDoubleLine>
            <Input
              type='text'
              id='description'
              title='Описание'
              value={formik.values["description"]}
              errors={formik.errors["description"]}
              onChange={formik.handleChange}
              className={`contributor-edit-form__control`}
            />
            <DatePickerCustom
              name='startDate'
              title='Дата начала контракта'
              selected={formik.values["startDate"] as Date}
              errors={formik.errors["startDate"]}
              onChange={(val) => formik.setFieldValue("startDate", val)}
              className={`contributor-edit-form__control`}
            />
            <Button
              htmlType='submit'
              disabled={!formik.isValid}
              className={`contributor-edit-form__btn`}
              loading={loadingChange}
            >
              Сохранить изменения
            </Button>
          </Form>
          <AccountButtons
            status={status}
            loadingDeleteUser={loadingDelete}
            loadingChangeStatus={loadingChangeStatus}
            handleChangeStatus={() => handleChangeStatus(id)}
            handleDeleteUser={() => handleDelete(id)}
          />
        </>
      )}
    </Formik>
  );
};
