import { MAIN_PATH, MODERATORS_PATH } from '../../constants/globalConstants'

export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Управление модераторами',
    path: MODERATORS_PATH,
  },
  {
    id: '2',
    name: 'Добавление нового модератора',
    path: `${MODERATORS_PATH}/new`,
  },
]
