import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { CONTRIBUTOR_GROUP } from '../../constants/common'
import {
  GROUP_CONTRIBUTORS_CACHE_PATH,
  REPORT_CACHE_PATH,
} from '../../constants/globalConstants'
import { ContributorGroupEditType } from '../../types/group'
import {
  contributorGroupReportGenerate,
  getContributorGroupReports,
  getGroupContributorById,
  groupContributorUpdate,
} from './ContributorGroupEditPage-api'
import { ReportRequestFieldsType } from './ContributorGroupEditPage-types'

export const useReportGenerate = (id: string) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    (values: ReportRequestFieldsType) =>
      contributorGroupReportGenerate(
        id,
        values.startDate,
        values.endDate,
        values.rights,
        values.action
      ),
    {
      onMutate: () => {
        toast.info(
          'Отчет будет сформирован в ближайшее время. Вы можете продолжить пользоваться сервисом.'
        )
      },
      onSuccess: async (data, variables, context) => {
        navigate('../edit?page=1')
        queryClient.invalidateQueries([
          REPORT_CACHE_PATH,
          { id, page: 1, type: CONTRIBUTOR_GROUP },
        ])
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось создать отчет')
      },
    }
  )

  return mutation
}

export const useContributorGroupReports = (
  contributorGroupId: string,
  page: number
) => {
  const query = useQuery(
    [
      REPORT_CACHE_PATH,
      { id: contributorGroupId, page: page, type: CONTRIBUTOR_GROUP },
    ],
    async () => {
      const contributorReports = await getContributorGroupReports(
        contributorGroupId,
        page
      )
      return contributorReports
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useGroupContributorById = (contributorGroupId: string) => {
  const query = useQuery(
    [GROUP_CONTRIBUTORS_CACHE_PATH, { id: contributorGroupId }],
    async () => {
      const contributorById = await getGroupContributorById(contributorGroupId)
      return contributorById
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
      enabled: Boolean(contributorGroupId),
    }
  )
  return query
}

export const useUpdateContributorGroup = (id: string) => {
  const client = useQueryClient()
  const mutation = useMutation(
    (values: ContributorGroupEditType) =>
      groupContributorUpdate(id, { ...values }),
    {
      onSuccess: async (data, variables, context) => {
        await client.invalidateQueries([
          GROUP_CONTRIBUTORS_CACHE_PATH,
          { id: `${id}` },
        ])
        toast.info('Данные успешно обновлены')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось обновить данные')
      },
    }
  )

  return mutation
}
