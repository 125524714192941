import { combineReducers } from 'redux'
import { AuthReducer } from './pages/AuthPage/modules/AuthModule-reducers'
import { BreadCrumbsReducer } from './modules/BreadCrumbs/BreadCrumbs-reducer'

export const reducers = combineReducers({
  auth: AuthReducer,
  breadCrumbs: BreadCrumbsReducer,
})

export type RootState = ReturnType<typeof reducers>
