import { useQuery } from 'react-query'
import { CATEGORIES_CACHE_PATH } from '../../constants/globalConstants'
import { getListCategories } from './CategoriesPage-api'

export const useFetchCategories = (page: number) => {
  const query = useQuery(
    [CATEGORIES_CACHE_PATH, { page: page }],
    async () => await getListCategories(page),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}
