import { useQuery } from 'react-query'
import { ADMINS_CACHE_PATH } from '../../constants/globalConstants'
import { getListAdmins } from './AdminsPage-api'

export const useFetchAdmins = (
  page: number,
  status: string | undefined = undefined,
  interval: string | undefined = undefined,
  name: string | undefined = undefined
) => {
  const query = useQuery(
    [ADMINS_CACHE_PATH, { page, interval, name, status }],
    async () => await getListAdmins(page, interval, name, status),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}
