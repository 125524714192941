import classNames from 'classnames'
import queryString from 'query-string'
import React from 'react'
import { useNavigate } from 'react-router'
import { Button } from '../../components/Button'
import { FilterIcon } from '../../components/FilterIcon'
import { SearchIcon } from '../../components/SearchIcon'
import { Title } from '../../components/Title'
import {
  CATEGORY_URL_VALUE,
  CONSUMER_USER_URL_TYPE,
  CONTRIBUTOR_URL_VALUE,
  INTERVAL_URL_VALUE,
  MATERIAL_STATUS_URL_VALUE,
  NAME_URL_VALUE,
  ORDER_STATUS_URL_VALUE,
  PAGE_URL_VALUE,
  SORT_URL_VALUE,
  USER_STATUS_URL_VALUE,
  USER_TYPE_URL_VALUE,
} from '../../constants/filterURLNames'
import { useURLParams } from '../../hooks/useURLParams'
import { FilterAuthor } from '../FilterAuthor'
import { FilterCategory } from '../FilterCategory'
import { FilterConsumerType } from '../FilterConsumerType'
import { FilterDateInterval } from '../FilterDateInterval'
import { FilterMaterialStatus } from '../FilterMaterialStatus'
import { FilterOrderStatus } from '../FilterOrderStatus'
import { FilterSearch } from '../FilterSearch'
import { FilterSort } from '../FilterSort'
import { FilterUserStatus } from '../FilterUserStatus'
import { FilterUserType } from '../FilterUserType'
import { MODE_FILTER, MODE_NONE, MODE_SEARCH } from './FilterMode-constants'
import { FilterModeType } from './FilterModule-types'
import './FilterModule.sass'

interface FilterModuleProps {
  className?: string
  title?: string
  withOrderStatus?: boolean
  withDateInterval?: boolean
  withSearch?: boolean
  withUserType?: boolean
  withUserStatus?: boolean
  withMaterialStatus?: boolean
  withSort?: boolean
  withAuthor?: boolean
  withCategory?: boolean
  withConsumerType?: boolean
}

export const FilterModule: React.FC<FilterModuleProps> = ({
  className,
  title,
  withOrderStatus,
  withDateInterval,
  withSearch,
  withUserType,
  withUserStatus,
  withMaterialStatus,
  withSort,
  withAuthor,
  withCategory,
  withConsumerType,
}) => {
  const navigate = useNavigate()
  const [filterMode, setFilterMode] = React.useState<FilterModeType>('none')
  const filterPrfx = 'filters-module'
  const filterCls = classNames(filterPrfx, {
    [`${className}`]: className,
  })
  const settingsPrfx = 'filters-module-settings'
  const settingsCls = classNames(settingsPrfx, {
    [`${settingsPrfx}_show`]: filterMode === 'filter',
  })
  const searchPrfx = 'filters-module-search'
  const searchCls = classNames(searchPrfx, {
    [`${searchPrfx}_show`]: filterMode === 'search',
  })

  const params = useURLParams()

  const handleExpandFilters = () => {
    if (filterMode === MODE_FILTER) {
      setFilterMode(MODE_NONE)
    } else {
      setFilterMode(MODE_FILTER)
    }
  }

  const handleExpandSearch = () => {
    if (filterMode === MODE_SEARCH) {
      setFilterMode(MODE_NONE)
    } else {
      setFilterMode(MODE_SEARCH)
    }
  }

  const handleResetClick = () => {
    if (params) {
      withOrderStatus && delete params[ORDER_STATUS_URL_VALUE]
      withDateInterval && delete params[INTERVAL_URL_VALUE]
      withSearch && delete params[NAME_URL_VALUE]
      withUserType && delete params[USER_TYPE_URL_VALUE]
      withUserStatus && delete params[USER_STATUS_URL_VALUE]
      withMaterialStatus && delete params[MATERIAL_STATUS_URL_VALUE]
      withSort && delete params[SORT_URL_VALUE]
      withAuthor && delete params[CONTRIBUTOR_URL_VALUE]
      withCategory && delete params[CATEGORY_URL_VALUE]
      withConsumerType && delete params[CONSUMER_USER_URL_TYPE]
      params[PAGE_URL_VALUE] = '1'
    }
    navigate({ search: queryString.stringify(params) })
  }

  return (
    <div className={filterCls}>
      <div className={`${filterPrfx}-actions`}>
        {title && (
          <Title text={title} className={`${filterPrfx}-actions__title`} />
        )}
        <div className={`${filterPrfx}-actions-btns`}>
          {withSearch && (
            <SearchIcon
              className={`${filterPrfx}-actions-btns__item`}
              isActive={filterMode === MODE_SEARCH}
              onClick={handleExpandSearch}
            />
          )}
          <FilterIcon
            className={`${filterPrfx}-actions-btns__item`}
            isActive={filterMode === MODE_FILTER}
            onClick={handleExpandFilters}
          />
        </div>
      </div>
      <div className={settingsCls}>
        {withOrderStatus && (
          <FilterOrderStatus
            params={params}
            navigate={navigate}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withDateInterval && (
          <FilterDateInterval
            params={params}
            navigate={navigate}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withUserType && (
          <FilterUserType
            params={params}
            navigate={navigate}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withUserStatus && (
          <FilterUserStatus
            params={params}
            navigate={navigate}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withMaterialStatus && (
          <FilterMaterialStatus
            params={params}
            navigate={navigate}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withSort && (
          <FilterSort
            params={params}
            navigate={navigate}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withAuthor && (
          <FilterAuthor
            params={params}
            navigate={navigate}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withCategory && (
          <FilterCategory
            params={params}
            navigate={navigate}
            className={`${settingsPrfx}__control`}
          />
        )}
        {withConsumerType && (
          <FilterConsumerType
            params={params}
            navigate={navigate}
            className={`${settingsPrfx}__control`}
          />
        )}
        <Button
          className={`${settingsPrfx}__btn`}
          onClick={handleResetClick}
          type='secondary'
          short
        >
          Сбросить фильтры
        </Button>
      </div>
      {withSearch && (
        <div className={searchCls}>
          <FilterSearch params={params} navigate={navigate} />
        </div>
      )}
    </div>
  )
}
