import React from 'react'
import { RejectionWindow } from '../../../../components/RejectionWindow'
import { RejectionsType } from '../../../../types/common'

interface RejectionsMobileProps {
  rejectionsList: RejectionsType
  handleRowClick: (id: string) => void
}

export const RejectionsMobile: React.FC<RejectionsMobileProps> = ({
  rejectionsList,
  handleRowClick,
}) => {
  if (!rejectionsList.length) {
    return <span>Еще не создано ни одного исключения</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {rejectionsList.map((rejection) => (
          <RejectionWindow
            className='windows-wrapper-windows__item'
            key={rejection.id}
            id={rejection.id}
            name={rejection.rejection}
            onClick={handleRowClick}
          />
        ))}
      </div>
    </div>
  )
}
