import React from 'react'
import { AdminWindow } from '../../../../components/AdminWindow'
import { AdminsType } from '../../AdminsPage-types'

interface AdministratorsMobileProps {
  adminsList: AdminsType
  handleRowClick: (id: string) => void
}

export const AdministratorsMobile: React.FC<AdministratorsMobileProps> = ({
  adminsList,
  handleRowClick,
}) => {
  if (!adminsList.length) {
    return <span>Еще не создано ни одного администратора</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {adminsList.map((admin) => (
          <AdminWindow
            className='windows-wrapper-windows__item'
            key={admin.id}
            id={admin.id}
            login={admin.login}
            status={admin.status}
            registerDate={admin.registerDate}
            consumersCount={admin.consumersCount}
            contributorsCount={admin.contributorsCount}
            moderatorsCount={admin.moderatorsCount}
            onClick={handleRowClick}
          />
        ))}
      </div>
    </div>
  )
}
