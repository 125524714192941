import { CONSUMER_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { CONSUMERS_SIZE } from './ConsumerPage-constants'

export const getListConsumers = async (
  page: number,
  interval: string | undefined,
  name: string | undefined,
  status: string | undefined
) => {
  const PATH = `${CONSUMER_API_PATH}`
  try {
    const response = await instance.get(PATH, {
      params: {
        page,
        size: CONSUMERS_SIZE,
        interval,
        login: name,
        status,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
