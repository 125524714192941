import React from 'react'
import { IncorretResult } from '../../../../components/IncorretResult'
import { SkeletonForm } from '../../../../components/SkeletonForm'
import {
  ConsumerGroupEditType,
  ConsumerGroupWithUsersType,
} from '../../../../types/group'
import { isObjectEmpty } from '../../../../utils/check'
import { useUpdateConsumerGroup } from '../../ConsumerGroupEditPage-hooks'
import { ConsumerGroupEditForm } from '../ConsumerGroupEditForm'
import './ConsumerGroupEditModule.sass'

interface ConsumerGroupEditModuleProps {
  className?: string
  id: string
  isLoading: boolean
  isError: boolean
  groupData: ConsumerGroupWithUsersType
}

export const ConsumerGroupEditModule: React.FC<ConsumerGroupEditModuleProps> =
  React.memo(({ id, isLoading, isError, groupData }) => {
    const { mutateAsync: updateConsumer, isLoading: loadingChange } =
      useUpdateConsumerGroup(id) as {
        mutateAsync: (values: ConsumerGroupEditType) => Promise<any>
        isLoading: boolean
      }

    const handleSubmit = async (userData: ConsumerGroupEditType) => {
      await updateConsumer({ ...userData })
    }

    if (isLoading) {
      return <SkeletonForm count={5} />
    }
    if (isObjectEmpty(groupData) || isError) {
      return <IncorretResult />
    }

    return (
      <ConsumerGroupEditForm
        groupData={groupData}
        loadingChange={loadingChange}
        handleSubmit={handleSubmit}
      />
    )
  })
