import React from 'react'
import { FixedArray } from '../../types/globalInterfaces'
import './WrapperDoubleLine.sass'

interface WrapperDoubleLineProps {
  className?: string
  children: FixedArray<React.ReactNode, 2>
}

export const WrapperDoubleLine: React.FC<WrapperDoubleLineProps> = ({
  className,
  children,
}) => {
  return <div className={'wrapper-double-line'}>{children}</div>
}
