import { useQuery } from 'react-query'
import { MAIN_CACHE_PATH } from '../../constants/globalConstants'
import { getDashboardInfo } from './GeneralPage-api'

export const useFetchGeneralInfo = () => {
  const query = useQuery(
    MAIN_CACHE_PATH,
    async () => await getDashboardInfo(),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}
