import React, { ReactElement } from "react";
// import { Table } from "../../../../components/Table";
import AntTable from "../../../../components/Table/AntTable";
import { getMaterialStatus, getNormalizeDate } from "../../../../utils/utils";
import { ModerationsHistoryType } from "../../ModerationHistoryPage-types";

const COLUMNS = [
  {
    title: "Дата загрузки",
    dataIndex: "material",
    render: (material: any) => {
      return <>{getNormalizeDate(material?.uploadDate)}</>;
    },
  },
  {
    title: "Автор",
    dataIndex: "material",
    render: (material: any) => {
      return <>{material?.contributor?.name ?? "Неизвестно"}</>;
    },
  },
  {
    title: "Начало модерации",
    dataIndex: "moderateStart",
    render: (material: any) => {
      return <>{getNormalizeDate(material)}</>;
    },
  },
  {
    title: "Модератор",
    dataIndex: "moderatedBy",
    render: (moderatedBy: any) => {
      return <>{moderatedBy?.name ?? "Неизвестно"}</>;
    },
  },
  {
    title: "Статус",
    dataIndex: "status",
    render: (status: any) => {
      return status === "accept" ? (
        <span className='cell_green'>{getMaterialStatus(status)}</span>
      ) : (
        <span className='cell_red'>{getMaterialStatus(status)}</span>
      );
    },
  },
];

interface ModerationHistoryTableProps {
  moderationHistoryList: ModerationsHistoryType;
  handleRowClick: (id: string) => void;
  handleChangePage: (page: number) => void;
  currentPage: number;
  btn: ReactElement<any, any> | null;
  total: number;
  pageSize: number;
}

export const ModerationHistoryTable: React.FC<ModerationHistoryTableProps> = ({
  moderationHistoryList,
  handleRowClick,
  handleChangePage,
  currentPage,
  btn,
  total,
  pageSize,
}) => {
  const antColumns = React.useMemo(() => COLUMNS, []) as any;
  const data = React.useMemo(
    () => moderationHistoryList,
    [moderationHistoryList]
  );

  return (
    <AntTable
      columns={antColumns}
      data={data}
      currentPage={currentPage}
      handleChangePage={handleChangePage}
      handleRowClick={handleRowClick}
      btn={btn}
      total={total}
      pageSize={pageSize}
    />
  );
};
