import React from 'react'
import { useParams } from 'react-router'
import { IncorretResult } from '../../components/IncorretResult'
import { SkeletonFormPage } from '../../components/SkeletonFormPage'
import { MAIN_PATH, WITHDRAW_PATH } from '../../constants/globalConstants'
import { StatisticModule } from '../../modules/StatisticModule'
import { isObjectEmpty } from '../../utils/check'
import { useBreadcrumbs } from '../../utils/hooks'
import { isStatusValid } from '../../utils/utils'
import { OrderInfoModule } from './modules/OrderInfoModule'
import { OrderRequestForm } from './modules/OrderRequestForm'
import {
  usePaymentOrderAccept,
  usePaymentOrderById,
  usePaymentOrderDeny,
} from './OrderEditPage-hooks'
import {
  PaymentOrderAcceptValuesType,
  PaymentOrderDeniedValuesType,
  PaymentOrderType,
} from './OrderEditPage-types'

export const OrderEditPage = () => {
  const { id } = useParams<'id'>()

  const {
    data: paymentOrderData,
    isLoading,
    isError,
  } = usePaymentOrderById(id!) as {
    data: PaymentOrderType
    isLoading: boolean
    isError: boolean
  }

  const { mutateAsync: paymentOrderAcceptRequest, isLoading: loadingAccept } =
    usePaymentOrderAccept() as {
      mutateAsync: (values: PaymentOrderAcceptValuesType) => Promise<any>
      isLoading: boolean
    }
  const { mutateAsync: paymentOrderDenyRequest, isLoading: loadingDeny } =
    usePaymentOrderDeny() as {
      mutateAsync: (values: PaymentOrderDeniedValuesType) => Promise<any>
      isLoading: boolean
    }

  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Управление платежами',
        path: WITHDRAW_PATH,
      },
      {
        id: '2',
        name: `Заявка от ${paymentOrderData?.contributor?.name ?? ''}`,
        path: `${WITHDRAW_PATH}/${id}`,
      },
    ],
    [id, paymentOrderData]
  )
  useBreadcrumbs(breadCrumbsData)

  const handleSubmit = async (values: PaymentOrderAcceptValuesType) => {
    await paymentOrderAcceptRequest(values)
  }
  const handleDeny = async (values: PaymentOrderDeniedValuesType) => {
    await paymentOrderDenyRequest(values)
  }
  if (isLoading) {
    return (
      <SkeletonFormPage
        rightColumn={'statistic'}
        leftFormCount={1}
        withRegisterInfo={false}
        statisticCount={7}
      />
    )
  }
  if (isObjectEmpty(paymentOrderData) || isError) {
    return <IncorretResult />
  }

  const getBody = () => {
    if (isStatusValid(paymentOrderData?.status)) {
      return (
        <div className={'common-page-body common-page-body_double'}>
          <OrderRequestForm
            id={id!}
            className='common-page-body__left'
            handleSubmit={handleSubmit}
            handleDeny={handleDeny}
            status={paymentOrderData?.status}
            loadingAccept={loadingAccept}
            loadingDeny={loadingDeny}
          />
          <OrderInfoModule
            className='common-page-body__right'
            contributor={paymentOrderData?.contributor?.name}
            moderator={paymentOrderData?.moderator?.name}
            creationDate={paymentOrderData?.createDate}
            solutionDate={paymentOrderData?.solutionDate}
            description={paymentOrderData?.description}
            outgo={paymentOrderData?.outgo}
            status={paymentOrderData?.status}
          />
        </div>
      )
    } else {
      return (
        <div className={'common-page-body common-page-body_restricted'}>
          <OrderInfoModule
            contributor={paymentOrderData?.contributor?.name}
            moderator={paymentOrderData?.moderator?.name}
            creationDate={paymentOrderData?.createDate}
            solutionDate={paymentOrderData?.solutionDate}
            description={paymentOrderData?.description}
            outgo={paymentOrderData?.outgo}
            status={paymentOrderData?.status}
          />
        </div>
      )
    }
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Модерация заявки'} />
      </div>
      {getBody()}
    </div>
  )
}
