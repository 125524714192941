import {
  GROUP_CONTRIBUTORS_API_PATH,
  REPORT_API_PATH,
} from '../../constants/globalConstants'
import { REPORT_ACTION_ENUM, REPORT_RIGHTS_ENUM } from '../../types/enums'
import { ConsumerGroupEditType } from '../../types/group'
import { instance } from '../../utils/coreAPI'
import { REPORT_SIZE } from './ContributorGroupEditPage-constants'

export const contributorGroupReportGenerate = async (
  id: string,
  startDate: string,
  endDate: string,
  rights: REPORT_RIGHTS_ENUM,
  action: REPORT_ACTION_ENUM
) => {
  try {
    const response = await instance.post(
      `${REPORT_API_PATH}/contributor_group/${id}`,
      {
        startDate,
        endDate,
        rights,
        action,
      }
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getGroupContributorById = async (id: string) => {
  try {
    const response = await instance.get(`${GROUP_CONTRIBUTORS_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const groupContributorUpdate = async (
  id: string,
  data: ConsumerGroupEditType
) => {
  try {
    const PATH = `${GROUP_CONTRIBUTORS_API_PATH}/${id}`
    const response = await instance.patch(PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getContributorGroupReports = async (id: string, page: number) => {
  try {
    const response = await instance.get(`${REPORT_API_PATH}`, {
      params: {
        contributorGroup: id,
        page: page,
        size: REPORT_SIZE,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
