import classNames from 'classnames'
import React from 'react'
import './CategoryWindow.sass'

interface CategoryWindowProps {
  className?: string
  id: string
  category: string
  onClick: (id: string) => void
}

export const CategoryWindow: React.FC<CategoryWindowProps> = ({
  className,
  id,
  category,
  onClick,
}) => {
  const windowPrfx = 'category-window'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })

  const categoryValue = category ?? 'Неизвестно'

  return (
    <div className={windowCls} onClick={() => onClick(id)}>
      <div className={`${windowPrfx}__category`}>{categoryValue}</div>
    </div>
  )
}
