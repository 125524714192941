import { MAIN_PATH, NEWS_PATH } from '../../constants/globalConstants'

export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Управление новостями',
    path: NEWS_PATH,
  },
]
