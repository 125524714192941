import { OptionType } from '../../types/common'

export const getCategoryOption = (
  id: string | string[] | null,
  categoriesList: OptionType[] | undefined
) => {
  if (!categoriesList) return null
  if (!id) return null
  let currentId = '-1'
  typeof id === 'object' ? (currentId = id[0]) : (currentId = id)
  const categoryOption = categoriesList.filter(
    (categoryObject) => categoryObject.value === currentId
  )
  return categoryOption[0] ?? null
}
