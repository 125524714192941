export const userTypeOptions = [
  {
    label: 'Все',
    value: undefined,
  },
  {
    label: 'Гости',
    value: true,
  },
]
