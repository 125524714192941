import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { SearchHistory } from './modules/SearchHistory'
import { breadCrumbsData } from './SearchHistoryPage-constants'
import './SearchHistoryPage.sass'

export const SearchHistoryPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <SearchHistory />
      </div>
    </div>
  )
}
