import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { moderatorAdd } from './ModeratorAddPage-api'
import { UserFormType } from '../../types/common'

export const useCreateModerator = () => {
  const navigate = useNavigate()
  const mutation = useMutation((values: UserFormType) => moderatorAdd(values), {
    onSuccess: async (data, variables, context) => {
      toast.info('Модератор добавлен')
      navigate(-1)
    },
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось добавить модератора')
    },
  })

  return mutation
}
