import React from 'react'
import { IncorretResult } from '../../../../components/IncorretResult'
import { SkeletonForm } from '../../../../components/SkeletonForm'
import { useFetchConsumerGroupList } from '../../../../hooks/useFetchConsumerGroupList'
import {
  ConsumerEditType,
  ConsumerListItemType,
} from '../../../../types/consumer'
import { GroupListType } from '../../../../types/group'
import { isObjectEmpty } from '../../../../utils/check'
import { confirmDeleteDialog } from '../../../../utils/confirms'
import {
  useChangeStatusConsumer,
  useRemoveConsumer,
  useUpdateConsumer,
} from '../../ConsumerEditPage-hooks'
import { ConsumerEditForm } from '../ConsumerEditForm'
import './ConsumerEditModule.sass'

interface ConsumerEditModuleProps {
  className?: string
  id: string
  isLoading: boolean
  isError: boolean
  consumerData: ConsumerListItemType
}

export const ConsumerEditModule: React.FC<ConsumerEditModuleProps> = React.memo(
  ({ className, id, isLoading, isError, consumerData }) => {
    const {
      data: groupData,
      isLoading: isGroupsLoading,
      isError: isGroupsError,
    } = useFetchConsumerGroupList() as {
      data: GroupListType
      isLoading: boolean
      isError: boolean
    }

    const { mutateAsync: updateConsumer, isLoading: loadingChange } =
      useUpdateConsumer(id) as {
        mutateAsync: (values: ConsumerEditType) => Promise<any>
        isLoading: boolean
      }

    const {
      mutateAsync: changeContributorStatus,
      isLoading: loadingChangeStatus,
    } = useChangeStatusConsumer() as {
      mutateAsync: (id: string) => Promise<any>
      isLoading: boolean
    }
    const { mutateAsync: removeConsumer, isLoading: loadingDelete } =
      useRemoveConsumer() as {
        mutateAsync: (id: string) => Promise<any>
        isLoading: boolean
      }

    const handleSubmit = async (userData: ConsumerEditType) => {
      await updateConsumer({ ...userData })
    }

    const removeUser = async (id: string) => {
      await removeConsumer(id)
    }

    const handleDelete = async (id: string) => {
      const isOk = await confirmDeleteDialog('Вы хотите удалить пользователя?')
      if (isOk) {
        removeUser(id)
      }
    }

    const handleChangeStatus = async (id: string) => {
      await changeContributorStatus(id)
    }

    if (isLoading || isGroupsLoading) {
      return <SkeletonForm count={7} />
    }
    if (isObjectEmpty(consumerData) || isError) {
      return <IncorretResult />
    }

    return (
      <ConsumerEditForm
        consumerData={consumerData}
        loadingChange={loadingChange}
        loadingDelete={loadingDelete}
        loadingChangeStatus={loadingChangeStatus}
        loadingGroups={isGroupsLoading}
        errorGroups={isGroupsError}
        handleDelete={handleDelete}
        handleSubmit={handleSubmit}
        handleChangeStatus={handleChangeStatus}
        groups={groupData}
      />
    )
  }
)
