import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { ROLE_CONSUMER } from '../../constants/common'
import {
  CONSUMERS_CACHE_PATH,
  REPORT_CACHE_PATH,
} from '../../constants/globalConstants'
import { ConsumerEditType } from '../../types/consumer'
import {
  consumerChange,
  consumerChangeStatus,
  consumerRemove,
  getConsumerReports,
  getIdConsumer,
  getIdConsumerStatistics,
} from './ConsumerEditPage-api'

export const useConsumerById = (consumerId: string) => {
  const query = useQuery(
    [CONSUMERS_CACHE_PATH, { id: consumerId, type: 'data' }],
    async () => {
      const consumerById = await getIdConsumer(consumerId)
      return consumerById
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
      enabled: Boolean(consumerId),
    }
  )
  return query
}

export const useConsumerStatistics = (consumerId: string) => {
  const query = useQuery(
    [CONSUMERS_CACHE_PATH, { id: consumerId, type: 'stat' }],
    async () => {
      const statisticsByModeratorId = await getIdConsumerStatistics(consumerId)
      return statisticsByModeratorId
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
      enabled: Boolean(consumerId),
    }
  )
  return query
}

export const useConsumerReports = (consumerId: string, page: number) => {
  const query = useQuery(
    [REPORT_CACHE_PATH, { id: consumerId, page: page, type: ROLE_CONSUMER }],
    async () => {
      const contributorReports = await getConsumerReports(consumerId, page)
      return contributorReports
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useUpdateConsumer = (id: string) => {
  const client = useQueryClient()
  const mutation = useMutation(
    (values: ConsumerEditType) => consumerChange(id, { ...values }),
    {
      onSuccess: async (data, variables, context) => {
        await client.invalidateQueries([
          CONSUMERS_CACHE_PATH,
          { id: `${id}`, type: 'data' },
        ])
        toast.info('Данные успешно обновлены')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось обновить данные')
      },
    }
  )

  return mutation
}

export const useRemoveConsumer = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (consumerId: string) => consumerRemove(consumerId),
    {
      onSuccess: async (data, variables, context) => {
        navigate(-1)
        toast.info('Потребитель удален')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось удалить потребитель')
      },
    }
  )
  return mutation
}

export const useChangeStatusConsumer = () => {
  const client = useQueryClient()
  const mutation = useMutation(
    (consumerId: string) => consumerChangeStatus(consumerId),
    {
      onSuccess: async (data, id, context) => {
        await client.invalidateQueries([
          CONSUMERS_CACHE_PATH,
          { id: `${id}`, type: 'data' },
        ])
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось сменить статус')
      },
    }
  )
  return mutation
}
