import { submitValues } from '../types/globalInterfaces'

export const isObjectEmpty = (obj: object) => {
  for (var i in obj) return false
  return true
}

export const getModifiedValue = (
  values: submitValues,
  initialValues: submitValues
) => {
  let modifiedValues = {} as submitValues

  if (values) {
    Object.entries(values).forEach((entry) => {
      let key: string = entry[0]
      let value = entry[1]

      if (value !== initialValues[key]) {
        modifiedValues[key] = value
      }
    })
  }
  return modifiedValues
}

export function isMobileDevice() {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  } catch (e) {
    return false
  }
}
