import React from 'react'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './ConsumerAddPage-constants'
import { useCreateConsumer } from './ConsumerAddPage-hooks'
import { StatisticModule } from '../../modules/StatisticModule'
import { ConsumerAddForm } from './modules/ConsumerAddForm'
import { useNavigate } from 'react-router'
import { ConsumerAddType } from '../../types/consumer'
import {useFetchConsumerGroups} from '../../pages/ConsumerGroupPage/ConsumerGroupPage-hooks'
import {ConsumerGroupAPIAnswerType} from '../../pages/ConsumerGroupPage/ConsumerGroupPage-types'

export const ConsumerAddPage = () => {
  const navigate = useNavigate();
  useBreadcrumbs(breadCrumbsData);

  const {
    data: groups,
    isLoading: isGroupsLoading,
    isError: isGroupsError,
  } = useFetchConsumerGroups(1) as {
    data: ConsumerGroupAPIAnswerType
    isLoading: boolean
    isError: boolean
  }

  console.log(groups);
  const { mutateAsync: createConsumer, isLoading: loadingCreate } =
    useCreateConsumer() as {
      mutateAsync: (consumerData: ConsumerAddType) => Promise<any>;
      isLoading: boolean;
    };

  const handleSubmit = async (userData: ConsumerAddType) => {
    await createConsumer(userData);
  };
  const handleCancel = () => {
    navigate(-1);
  };  

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={"Создание нового потребителя"} />
      </div>
      <div className='common-page-body'>
      {!isGroupsLoading && !isGroupsError && (
        <ConsumerAddForm
          groups={groups.items}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isLoading={loadingCreate}
          loadingGroups={isGroupsLoading}
          errorGroups={isGroupsError}
        />)}
      </div>
    </div>
  );
};
