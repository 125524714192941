import { CONTRIBUTORS_PATH, MAIN_PATH } from '../../constants/globalConstants'

export const CONTRIBUTORS_SIZE = 12
export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Управление поставщиками',
    path: CONTRIBUTORS_PATH,
  },
]
