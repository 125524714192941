import classNames from 'classnames'
import React from 'react'
import { StatisticItem } from '../../../../components/StatisticItem'
import { getNormalizeDate } from '../../../../utils/utils'

interface RequestInfoModuleProps {
  className?: string
  name: string
  login: string
  email: string
  phone: string
  registerDate: string
}

export const RequestInfoModule: React.FC<RequestInfoModuleProps> = ({
  className,
  name,
  login,
  email,
  phone,
  registerDate,
}) => {
  const modulePrfx = 'module-info'
  const divCls = classNames(modulePrfx, {
    [`${className}`]: className,
  })

  const nameValue = name ?? 'неизвестно'
  const loginValue = login ?? 'неизвестно'
  const emailValue = email ?? 'неизвестно'
  const phoneValue = phone ?? 'неизвестно'
  const dateValue = getNormalizeDate(registerDate) ?? 'неизвестно'

  return (
    <div className={divCls}>
      <h2 className={`${modulePrfx}__title`}>Информация о заявке</h2>
      <div className={`${modulePrfx}-body`}>
        <StatisticItem
          title='Имя'
          value={nameValue}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Логин'
          value={loginValue}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Телефон'
          value={phoneValue}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Дата регистрации'
          value={dateValue}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Почта'
          value={emailValue}
          className={`${modulePrfx}-body__item`}
        />
      </div>
    </div>
  )
}
