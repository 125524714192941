import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonHeaderStatistic.sass'

interface SkeletonHeaderStatisticProps {
  withRegisterInfo: boolean
}

export const SkeletonHeaderStatistic: React.FC<SkeletonHeaderStatisticProps> =
  ({ withRegisterInfo }) => {
    return (
      <div className='skeleton-header-statistic'>
        <div className='skeleton-header-statistic-line'>
          <SkeletonBase
            type='text'
            className='skeleton-header-statistic-line__breadcrumbs'
          />
          <SkeletonBase
            type='text'
            className='skeleton-header-statistic-line__logo'
          />
          <SkeletonBase
            type='text'
            className='skeleton-header-statistic-line__icon'
          />
        </div>
        <SkeletonBase
          type='text'
          className='skeleton-header-statistic__title'
        />
        {withRegisterInfo && (
          <div className='skeleton-header-statistic-info'>
            <div className='skeleton-header-statistic-info__item'>
              <SkeletonBase
                type='text'
                className='skeleton-header-statistic-info__text'
              />
            </div>
            <div className='skeleton-header-statistic-info__item'>
              <SkeletonBase
                type='text'
                className='skeleton-header-statistic-info__text'
              />
            </div>
          </div>
        )}
      </div>
    )
  }
