import { instance } from '../../utils/coreAPI'
import {
  CONTRIBUTORS_STATISTIC_API_PATH,
  CONTRIBUTOR_API_PATH,
  REPORT_API_PATH,
} from '../../constants/globalConstants'
import { REPORT_SIZE } from './ContributorEditPage-constants'
import { ContributorEditType } from '../../types/contributor'

export const getIdContributor = async (id: string) => {
  try {
    const response = await instance.get(`${CONTRIBUTOR_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getIdContributorStatistics = async (id: string) => {
  try {
    const response = await instance.get(
      `${CONTRIBUTORS_STATISTIC_API_PATH}/${id}`
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getContributorReports = async (id: string, page: number) => {
  try {
    const response = await instance.get(`${REPORT_API_PATH}`, {
      params: {
        contributor: id,
        page: page,
        size: REPORT_SIZE,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const contributorChange = async (
  id: string,
  data: ContributorEditType
) => {
  try {
    const PATH = `${CONTRIBUTOR_API_PATH}/${id}`
    const response = await instance.patch(PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const contributorRemove = async (id: string) => {
  try {
    const PATH = `${CONTRIBUTOR_API_PATH}/${id}`
    const response = await instance.delete(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
export const contributorChangeStatus = async (id: string) => {
  try {
    const PATH = `${CONTRIBUTOR_API_PATH}/${id}/switch/lock`
    const response = await instance.post(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
