import React, { ReactElement } from "react";
import AntTable from "../../../../components/Table/AntTable";
import { getNormalizeDate } from "../../../../utils/utils";
import { ContributorGroupType } from "../../ContributorGroupPage-types";
import "./GroupsTable.sass";

export const COLUMNS = [
  {
    title: "Название",
    dataIndex: "groupName",
  },
  {
    title: "Участников",
    dataIndex: "membersCount",
  },
  {
    title: "Начало контракта",
    dataIndex: "contract",
    render: (contract: any) => {
      return <>{getNormalizeDate(contract?.startDate)}</>;
    },
  },
];

interface GroupsTableProps {
  className?: string;
  groupList: ContributorGroupType[];
  handleRowClick: (id: string) => void;
  handleChangePage: (page: number) => void;
  currentPage: number;
  btn: ReactElement<any, any> | null;
  total: number;
  pageSize: number;
}

export const GroupsTable: React.FC<GroupsTableProps> = ({
  groupList,
  handleRowClick,
  handleChangePage,
  currentPage,
  btn,
  total,
  pageSize,
}) => {
  const antColumns = React.useMemo(() => COLUMNS, []) as any;
  const data = React.useMemo(() => groupList, [groupList]);
  return (
    <AntTable
      columns={antColumns}
      data={data}
      currentPage={currentPage}
      handleChangePage={handleChangePage}
      handleRowClick={handleRowClick}
      btn={btn}
      total={total}
      pageSize={pageSize}
    />
  );
};
