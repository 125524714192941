import { useQuery } from 'react-query'
import { GROUP_CONSUMERS_CACHE_PATH } from '../../constants/globalConstants'
import { fetchConsumerGroupList } from './ConsumerGroupPage-api'

export const useFetchConsumerGroups = (page: number) => {
  const query = useQuery(
    [GROUP_CONSUMERS_CACHE_PATH, { page: page }],
    async () => await fetchConsumerGroupList(page),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}
