import { useQuery } from 'react-query'
import { fetchContributorGroupList } from '../api/fetchContributorGroupList'
import { GROUP_CONTRIBUTORS_CACHE_PATH } from '../constants/globalConstants'

export const useFetchContributorGroupList = () => {
  const query = useQuery(
    [GROUP_CONTRIBUTORS_CACHE_PATH, { type: 'list' }],
    async () => await fetchContributorGroupList(),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}
