import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './ContributorGroupPage-constants'
import './ContributorGroupPage.sass'
import { Groups } from './modules/Groups'

export const ContributorGroupPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <Groups />
      </div>
    </div>
  )
}
