import { useQuery } from 'react-query'
import { SEARCH_HISTORY_CACHE_PATH } from '../../constants/globalConstants'
import { getListSearchHistory } from './SearchHistoryPage-api'

export const useFetchSearchHistory = (
  page: number,
  interval: string | undefined = undefined,
  category: string | undefined = undefined,
  author: string | undefined = undefined,
  isConsumer: boolean | undefined = undefined
) => {
  const query = useQuery(
    [
      SEARCH_HISTORY_CACHE_PATH,
      { page, interval, category, author, isConsumer },
    ],
    async () =>
      await getListSearchHistory(page, interval, category, author, isConsumer),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}
