import React, { ReactElement } from "react";
import AntTable from "../../../../components/Table/AntTable";
import { getNormalizeDate, getOrderStatus } from "../../../../utils/utils";
import { OrdersType } from "../../OrdersPage-types";

const COLUMNS = [
  {
    title: "Поставщик",
    dataIndex: "contributor",
    render: (contributor: any) => {
      return <>{contributor.name ?? "-"}</>;
    },
  },
  {
    title: "Модератор",
    dataIndex: "moderator",

    render: (moderator: any) => {
      return <>{moderator.name ?? "-"}</>;
    },
  },
  {
    title: "Сумма вывода",
    dataIndex: "outgo",
  },
  {
    title: "Дата заявки",
    dataIndex: "createDate",
    render: (createDate: any) => {
      return <>{getNormalizeDate(createDate)}</>;
    },
  },
  {
    Header: "Статус",
    dataIndex: "status",
    render: (status: any) => {
      return <>{getOrderStatus(status)}</>;
    },
  },
];

interface OrdersTableProps {
  ordersList: OrdersType;
  handleRowClick: (id: string) => void;
  handleChangePage: (page: number) => void;
  currentPage: number;
  btn: ReactElement<any, any> | null;
  total: number;
  pageSize: number;
}

export const OrdersTable: React.FC<OrdersTableProps> = ({
  ordersList,
  handleRowClick,
  handleChangePage,
  currentPage,
  btn,
  total,
  pageSize,
}) => {
  const antColumns = React.useMemo(() => COLUMNS, []) as any;
  const data = React.useMemo(() => ordersList, [ordersList]);

  return (
    <AntTable
      columns={antColumns}
      data={data}
      currentPage={currentPage}
      handleChangePage={handleChangePage}
      handleRowClick={handleRowClick}
      btn={btn}
      total={total}
      pageSize={pageSize}
    />
  );
};
