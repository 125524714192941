import { instance } from '../../utils/coreAPI'
import { ADMIN_API_PATH } from '../../constants/globalConstants'
import { ADMINS_SIZE } from './AdminsPage-constants'

export const getListAdmins = async (
  page: number,
  interval: string | undefined,
  name: string | undefined,
  status: string | undefined
) => {
  try {
    const response = await instance.get(`${ADMIN_API_PATH}`, {
      params: {
        page,
        size: ADMINS_SIZE,
        interval,
        login: name,
        status,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
