import React from 'react'
import { CategoryWindow } from '../../../../components/CategoryWindow'
import { CategoriesType } from '../../CategoriesPage-types'

interface CategoriesMobileProps {
  categoriesList: CategoriesType
  handleRowClick: (id: string) => void
}

export const CategoriesMobile: React.FC<CategoriesMobileProps> = ({
  categoriesList,
  handleRowClick,
}) => {
  if (!categoriesList.length) {
    return <span>Еще не создано ни одной категории</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {categoriesList.map((category) => (
          <CategoryWindow
            className='windows-wrapper-windows__item'
            key={category.id}
            id={category.id}
            category={category.category}
            onClick={handleRowClick}
          />
        ))}
      </div>
    </div>
  )
}
