import classNames from 'classnames'
import React from 'react'
import {
  getNormalizeDate,
  getOrderStatus,
  getUserType,
} from '../../utils/utils'
import './RequestWindow.sass'

interface RequestWindowProps {
  className?: string
  id: string
  login: string
  status: string
  registerDate: string
  userType: string
  onClick: (id: string) => void
}

export const RequestWindow: React.FC<RequestWindowProps> = ({
  className,
  id,
  login,
  status,
  registerDate,
  userType,
  onClick,
}) => {
  const windowPrfx = 'request-window'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })

  const statusPrfx = 'request-window__status'
  const statusCls = classNames(statusPrfx, {
    [`${statusPrfx}_accept`]: status === 'accept',
    [`${statusPrfx}_deny`]: status === 'deny',
  })

  const statusValue = getOrderStatus(status)
  const loginValue = login ?? 'Неизвестно'
  const registerDateValue = getNormalizeDate(registerDate)
  const userTypeValue = getUserType(userType)

  return (
    <div className={windowCls} onClick={() => onClick(id)}>
      <div className={statusCls}>{statusValue}</div>
      <div className={`${windowPrfx}__login`}>{loginValue}</div>
      <div className={`${windowPrfx}__type`}>{userTypeValue}</div>
      <div className={`${windowPrfx}__date`}>{registerDateValue}</div>
    </div>
  )
}
