import classNames from 'classnames'
import React from 'react'
import './Textarea.sass'

interface TextAreaProps {
  className?: string
  [x: string]: any
}

export const Textarea: React.FC<TextAreaProps> = (props) => {
  const { className, ...rest } = props
  const controlClassName = classNames('textarea-custom', {
    [`${className}`]: className,
  })
  return <textarea className={controlClassName} {...rest} />
}
