import { GROUP_CONSUMERS_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { GroupConsumerAddType } from './ConsumerGroupAddPage-types'

export const groupConsumerAdd = async (data: GroupConsumerAddType) => {
  try {
    const response = await instance.post(GROUP_CONSUMERS_API_PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
