import classNames from 'classnames'
import React from 'react'
import { REPORT_STATUS_ENUM } from '../../types/enums'
import { ReportType } from '../../types/report'
import { getReportTranslatedStatus } from '../../utils/translate'
import { getNormalizeDate, getPathToReportFromHash } from '../../utils/utils'
import './ReportListItem.sass'

interface ReportListItemProps {
  className?: string
  reportItem: ReportType
}

export const ReportListItem: React.FC<ReportListItemProps> = ({
  className,
  reportItem,
}) => {
  const itemPrfx = 'report-list-item'
  const itemCls = classNames(itemPrfx, {
    [`${className}`]: className,
    [`${itemPrfx}_disabled`]: reportItem.status !== REPORT_STATUS_ENUM.DONE,
  })

  const statusPrfx = `${itemPrfx}__status`
  const statusCls = classNames(statusPrfx, {
    [`${statusPrfx}_done`]: reportItem.status === REPORT_STATUS_ENUM.DONE,
    [`${statusPrfx}_wrong`]: reportItem.status !== REPORT_STATUS_ENUM.DONE,
  })
  const linkPath = getPathToReportFromHash(reportItem.protectedHash)
  const startDate = getNormalizeDate(reportItem.periodStart)
  const endDate = getNormalizeDate(reportItem.periodFinish)
  const status = getReportTranslatedStatus(reportItem.status)
  const reportType = reportItem.reportType
  return (
    <a href={linkPath} target='_blank' rel='noreferrer' className={itemCls}>
      <span className={`${itemPrfx}__start`}>{startDate}</span>-{' '}
      <span className={`${itemPrfx}__end`}>{endDate}</span>
      <span className={`${itemPrfx}__type`}>{reportType}</span>
      <span className={statusCls}>{status}</span>
    </a>
  )
}
