import classNames from 'classnames'
import React from 'react'
import Select from 'react-select'
import queryString from 'query-string'
import { getDateOption } from './FilterDateInterval-utils'
import { filterDateOptions } from './FilterDateInterval-constants'
import { NavigateFunction } from 'react-router'
import {
  INTERVAL_URL_VALUE,
  PAGE_URL_VALUE,
} from '../../constants/filterURLNames'

interface FilterDateIntervalProps {
  className?: string
  navigate: NavigateFunction
  params: { [k: string]: string }
}

export const FilterDateInterval: React.FC<FilterDateIntervalProps> = React.memo(
  ({ className, navigate, params }) => {
    const filterPrfx = 'filter-param-container'
    const filterCls = classNames(filterPrfx, {
      [`${className}`]: className,
    })
    const date = params[INTERVAL_URL_VALUE]

    const [dateFilter, setDateFilter] = React.useState(getDateOption(date))

    React.useEffect(() => {
      setDateFilter(getDateOption(date))
    }, [date])

    const handleFilterDateChange = (option: any) => {
      setDateFilter(getDateOption(option.value))
      params[INTERVAL_URL_VALUE] = option.value
      params[PAGE_URL_VALUE] = '1'
      navigate({ search: queryString.stringify(params) })
    }
    return (
      <div className={filterCls}>
        <label className={`${filterPrfx}__label`} htmlFor='date'>
          По дате
        </label>
        <Select
          id='date'
          className='react-select-container'
          classNamePrefix='react-select'
          getOptionValue={(option) => `${option.label}`}
          value={dateFilter}
          onChange={handleFilterDateChange}
          options={filterDateOptions}
        />
      </div>
    )
  }
)
