import { REJECTION_ALL_API_PATH } from '../constants/globalConstants'
import { instance } from '../utils/coreAPI'

export const getAllRejectionsCall = async () => {
  try {
    const response = await instance.get(REJECTION_ALL_API_PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
