import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { breadCrumbsData } from './ContributorsPage-constants'
import { useBreadcrumbs } from '../../utils/hooks'
import { Contributors } from './modules/Contributors'

export const ContributorsPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='contributors common-page'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <Contributors />
      </div>
    </div>
  )
}
