import { ROLE_CONSUMER, ROLE_CONTRIBUTOR } from '../../constants/common'

export const userTypeOptions = [
  {
    label: 'Все',
    value: undefined,
  },
  {
    label: 'Потребитель',
    value: ROLE_CONSUMER,
  },
  {
    label: 'Поставщик',
    value: ROLE_CONTRIBUTOR,
  },
]
