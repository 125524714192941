import { instance } from '../../utils/coreAPI'
import { REQUESTS_API_PATH } from '../../constants/globalConstants'
import { REQUESTS_SIZE } from './RequestsPage-constants'
import { ROLE_CONSUMER, ROLE_CONTRIBUTOR } from '../../constants/common'

export const getListRequests = async (
  page: number,
  interval: string | undefined,
  name: string | undefined,
  status: string | undefined,
  userType: string | undefined
) => {
  const isConsumer = userType === ROLE_CONSUMER
  const isContributor = userType === ROLE_CONTRIBUTOR
  try {
    const response = await instance.get(REQUESTS_API_PATH, {
      params: {
        page,
        size: REQUESTS_SIZE,
        interval,
        login: name,
        status,
        consumerOnly: isConsumer ? true : null,
        contributorOnly: isContributor ? true : null,
      },
    })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
