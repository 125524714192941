import React from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { CategoryRequestType } from '../../../CategoriesPage/CategoriesPage-types'
import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import { BsTrash } from 'react-icons/bs'

interface CategoryAddFormProps {
  className?: string
  handleSubmit: (values: CategoryRequestType) => void
  handleCancel: () => void
  isLoading: boolean
}

export const CategoryAddForm: React.FC<CategoryAddFormProps> = ({
  handleSubmit,
  handleCancel,
  isLoading = false,
}) => {
  const initialValues = {
    category: '',
  }
  const validationSchema = Yup.object({
    category: Yup.string().required('Обязательно'),
  })
  const onSubmit = async (values: CategoryRequestType, { resetForm }: any) => {
    handleSubmit({
      category: values.category,
    })
    resetForm()
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={false}
    >
      {(formik) => (
        <Form className={`user-form`}>
          <div className='user-form-left'>
            <h2 className={`user-form__title`}>Информация о категории</h2>
            <Input
              type='text'
              id='category'
              title='Категория'
              value={formik.values['category']}
              onChange={formik.handleChange}
              className={`user-form__control`}
            />
          </div>
          <div className='user-form-btns'>
            <Button
              htmlType='submit'
              disabled={!formik.isValid}
              className={`user-form-btns__item user-form-form__control`}
              loading={isLoading}
            >
              Сохранить изменения
            </Button>
            <Button
              htmlType='button'
              type='transparent'
              className={`user-form-btns__item user-form-form__control`}
              onClick={handleCancel}
            >
              Отмена{' '}
              <span>
                <BsTrash />
              </span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
