export const BACKEND_URL: string =
  process.env.REACT_APP_BACKEND_URL ?? 'https://api.media4media.ru'
export const BANK_PHOTO_PATH =
  process.env.REACT_APP_DATA_URL ?? 'https://storage.media4media.ru'
export const BANK_REPORTS_PATH =
  process.env.REACT_APP_REPORTS_URL ?? 'https://reports.media4media.ru/reports'

export const DAYS_IN_MS = 86400000

export const DOMAIN_PATH = 'media4media.ru'

export const SIGNIN_PATH = '/signin'

export const MAIN_PATH = '/main'
export const MAIN_CACHE_PATH = 'main'

export const GEO_API_PATH = '/geo/prompt'

export const STATISTIC_API_PATH = '/statistics'

export const SELF_PATH = `/self`
export const SELF_CACHE_PATH = 'self'
export const SELF_STATISTICS = `${STATISTIC_API_PATH}`

export const AUTH_API_PATH = '/auth/login'
export const LOGOUT_API_PATH = 'auth/logout'
export const FORBIDDEN_PATH = '/forbidden'

export const ADMIN_API_PATH = '/admin'
export const ADMINS_PATH = '/admins'
export const ADMINS_CACHE_PATH = 'admins'
export const ADMIN_STATISTIC_API_PATH = `${STATISTIC_API_PATH}/admin`

export const MODERATOR_API_PATH = '/moderator'
export const MODERATORS_PATH = '/moderators'
export const MODERATORS_CACHE_PATH = 'moderators'
export const MODERATORS_STATISTIC_API_PATH = `${STATISTIC_API_PATH}/moderators`

export const CONTRIBUTOR_API_PATH = '/contributor'
export const CONTRIBUTOR_LIST_API_PATH = `${CONTRIBUTOR_API_PATH}/list`
export const CONTRIBUTORS_PATH = '/contributors'
export const CONTRIBUTORS_CACHE_PATH = 'contributors'
export const CONTRIBUTORS_LIST_CACHE_PATH = 'contributorsList'
export const CONTRIBUTORS_STATISTIC_API_PATH = `${STATISTIC_API_PATH}/contributor`

export const CONSUMER_API_PATH = '/consumer'
export const CONSUMERS_PATH = '/consumers'
export const CONSUMERS_CACHE_PATH = 'consumers'
export const CONSUMERS_STATISTIC_API_PATH = `${STATISTIC_API_PATH}/consumer`

export const GROUP_CONSUMERS_PATH = `/groups/consumers`
export const GROUP_CONSUMERS_API_PATH = `/group/consumers`
export const GROUP_CONSUMERS_CACHE_PATH = 'groups/consumers'
export const GROUP_CONTRIBUTORS_PATH = `/groups/contributors`
export const GROUP_CONTRIBUTORS_API_PATH = `/group/contributors`
export const GROUP_CONTRIBUTORS_CACHE_PATH = 'groups/contributors'

export const REQUESTS_API_PATH = '/registration'
export const REQUESTS_CACHE_PATH = 'registration'
export const REQUESTS_PATH = '/requests'

export const WITHDRAW_API_PATH = '/withdraw'
export const WITHDRAW_PATH = '/orders'
export const WITHDRAW_CACHE_PATH = 'orders'

export const NEWS_PATH = '/news'
export const NEWS_API_PATH = '/news'
export const NEWS_SNATCH_API_PATH = '/news/snatcher'
export const NEWS_CACHE_PATH = 'news'

export const CATEGORY_API_PATH = '/category'
export const CATEGORY_ALL_API_PATH = '/category/list'
export const CATEGORIES_PATH = '/categories'
export const CATEGORIES_CACHE_PATH = 'categories'

export const REJECTION_API_PATH = '/rejection'
export const REJECTION_ALL_API_PATH = '/rejection/list'
export const REJECTIONS_PATH = '/rejections'
export const REJECTIONS_CACHE_PATH = 'rejections'

export const MATERIAL_API_PATH = '/material'
export const MATERIALS_PATH = '/materials'
export const MATERIALS_CACHE_PATH = 'materials'
export const MATERIALS_STATISTIC_API_PATH = `${STATISTIC_API_PATH}/material`

export const MODERATION_HISTORY_API_PATH = `${MATERIAL_API_PATH}/moderation/history`
export const MODERATION_HISTORY_PATH = '/history/moderation'
export const MODERATION_HISTORY_CACHE_PATH = 'moderationHistory'

export const MODERATION_PATH = '/moderation'
export const MODERATION_API_PATH = `${MATERIAL_API_PATH}/to-moderation/rand`
export const MODERATION_MATERIALS_CACHE_PATH = 'moderationMaterial'

export const SEARCH_HISTORY_PATH = '/history/search'
export const SEARCH_HISTORY_API_PATH = 'search/history'
export const SEARCH_HISTORY_CACHE_PATH = 'search_history'

export const REPORT_API_PATH = '/report'
export const REPORT_CACHE_PATH = 'report'

export const NOT_FOUND_PATH = '/404'
