import React from 'react'
import queryString from 'query-string'
import { useNavigate, useParams } from 'react-router'
import { PAGE_URL_VALUE } from '../../constants/filterURLNames'
import { useURLParams } from '../../hooks/useURLParams'
import {
  GROUP_CONSUMERS_PATH,
  MAIN_PATH,
} from '../../constants/globalConstants'
import { useBreadcrumbs } from '../../utils/hooks'
import { StatisticModule } from '../../modules/StatisticModule'
import { WrapperGrid } from '../../components/WrapperGrid'
import { ConsumerGroupEditModule } from './modules/ConsumerGroupEditModule'
import { GroupUsersList } from '../../components/GroupUsersList'
import {
  useConsumerGroupReports,
  useGroupConsumerById,
} from './ConsumerGroupEditPage-hooks'
import { ConsumerGroupWithUsersType } from '../../types/group'
import { ReportAPIAnswerType } from '../../types/report'
import { ReportListModule } from '../../modules/ReportListModule'
import { ROLE_CONSUMER } from '../../constants/common'

export const ConsumerGroupEditPage = React.memo(() => {
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  const urlParams = useURLParams()
  const { page } = urlParams
  const currentPage = page ? +page : 1
  const handleChangePage = (page: string) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString()
    navigate({ search: queryString.stringify(urlParams) })
  }

  const {
    data: groupData,
    isLoading,
    isError,
  } = useGroupConsumerById(id!) as {
    data: ConsumerGroupWithUsersType
    isLoading: boolean
    isError: boolean
  }

  const {
    data: reportsData,
    isLoading: isReportsLoading,
    isError: isReportsError,
  } = useConsumerGroupReports(id!, currentPage) as {
    data: ReportAPIAnswerType
    isLoading: boolean
    isError: boolean
  }

  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Группы потребителей',
        path: GROUP_CONSUMERS_PATH,
      },
      {
        id: '2',
        name: `Группа ${groupData?.groupName ?? ''} `,
        path: `${GROUP_CONSUMERS_PATH}/${id}`,
      },
    ],
    [id, groupData]
  )

  useBreadcrumbs(breadCrumbsData)

  const handleReport = () => {
    navigate('../report')
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Редактирование группы'} />
      </div>

      <WrapperGrid>
        <ConsumerGroupEditModule
          id={id!}
          isLoading={isLoading}
          isError={isError}
          groupData={groupData}
        />
        <GroupUsersList
          members={groupData?.consumers}
          membersType={ROLE_CONSUMER}
          isLoading={isLoading}
          isError={isError}
        />
        <ReportListModule
          reportList={reportsData}
          currentPage={currentPage}
          handlePageChange={handleChangePage}
          handleReport={handleReport}
          isLoading={isReportsLoading}
          isError={isReportsError}
        />
      </WrapperGrid>
    </div>
  )
})
