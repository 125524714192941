import classNames from 'classnames'
import React from 'react'
import './RejectionWindow.sass'

interface RejectionWindowProps {
  className?: string
  id: string
  name: string
  onClick: (id: string) => void
}

export const RejectionWindow: React.FC<RejectionWindowProps> = ({
  className,
  id,
  name,
  onClick,
}) => {
  const windowPrfx = 'rejection-window'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })

  const rejectionValue = name ?? 'Неизвестно'

  return (
    <div className={windowCls} onClick={() => onClick(id)}>
      <div className={`${windowPrfx}__name`}>{rejectionValue}</div>
    </div>
  )
}
