import React from 'react'
import { GET_ENUMS } from '../../constants/modals'
import { useGetPopupState } from '../../hooks/useGetPopupState'
import { AdminProfile } from '../../modules/AdminProfile'

const popups = {
  [GET_ENUMS.popup.profileInfo]: AdminProfile,
}

export const GetParameterPopup = () => {
  const { mountedPopup, isOpened } = useGetPopupState()

  if (!mountedPopup) {
    return null
  }

  const Component = popups[mountedPopup]

  if (!Component) {
    return null
  }
  return <Component isOpened={isOpened} />
}
