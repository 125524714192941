import classNames from "classnames";
import React from "react";
import { ReactComponent as ArrowLeft } from "../../assets/img/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/img/arrowRight.svg";
import { Loader } from "../Loader";
import Paginate from "react-paginate";
import "./Pagination.sass";

interface PaginationProps {
  className?: string;
  onChange: (id: number) => void;
  activeId: string;
  count: number;
  loading?: boolean;
}

type selectedObject = {
  selected: number;
};

export const Pagination: React.FC<PaginationProps> = ({
  className,
  onChange,
  activeId,
  count,
  loading = false,
}) => {
  const onPageChange = (selectedItem: selectedObject) => {
    onChange(`${selectedItem.selected}`);
  };
  const paginationClassName = classNames("pagination-list", {
    [`${className}`]: className,
  });
  return loading ? (
    <Loader position={"right"} />
  ) : (
    <Paginate
      forcePage={+activeId}
      pageCount={count}
      pageRangeDisplayed={2}
      onPageChange={onPageChange}
      marginPagesDisplayed={2}
      containerClassName={paginationClassName}
      pageClassName={"pagination-list__item"}
      activeClassName={"pagination-list__item pagination-list__item_active"}
      previousClassName={"pagination-list__item pagination-list__item_prev"}
      nextClassName={"pagination-list__item pagination-list__item_next"}
      breakClassName={"pagination-list__item pagination-list__item_break"}
      disabledClassName={"pagination-list__item_disabled"}
      previousLabel={<ArrowLeft />}
      nextLabel={<ArrowRight />}
    />
  );
};
