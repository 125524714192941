import classNames from 'classnames'
import React from 'react'
import { NewsType } from '../../pages/NewsPage/NewsPage-types'
import { InputTag } from '../InputTag'
import { Textarea } from '../Textarea'
import './NewsComponent.sass'

interface NewsComponentProps {
  className?: string
  news: NewsType
  handleChangeTags: (tag: string) => void
  handleRemoveTags: (tag: string) => void
  handleChangeText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export const NewsComponent: React.FC<NewsComponentProps> = ({
  className,
  news,
  handleChangeTags,
  handleRemoveTags,
  handleChangeText,
}) => {
  const newsPrfx = 'news-component'
  const newsCls = classNames(newsPrfx, {
    [`${className}`]: className,
  })

  return (
    <div className={newsCls}>
      <Textarea
        value={news.title}
        onChange={handleChangeText}
        className={`${newsPrfx}__text`}
      />
      <InputTag
        tags={news.tags}
        handleSubmit={handleChangeTags}
        handleCancel={handleRemoveTags}
        className={`${newsPrfx}__tags`}
      />
    </div>
  )
}
