import classNames from 'classnames'
import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonForm.sass'

interface SkeletonFormProps {
  className?: string
  count: number
}

export const SkeletonForm: React.FC<SkeletonFormProps> = ({
  className,
  count,
}) => {
  const skeletonPrfx = 'skeleton-form'
  const skeletonCls = classNames(skeletonPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={skeletonCls}>
      <SkeletonBase type='text' className={`${skeletonPrfx}__title`} />
      {[...Array(count)].map((e, i) => (
        <div className={`${skeletonPrfx}-control`} key={i}>
          <SkeletonBase
            type='title'
            className={`${skeletonPrfx}-control__placeholder`}
          />
          <SkeletonBase
            type='text'
            className={`${skeletonPrfx}-control__input`}
          />
        </div>
      ))}
      <div className={`${skeletonPrfx}-btns`}>
        <SkeletonBase type='text' className={`${skeletonPrfx}-btns__item`} />
        <SkeletonBase type='text' className={`${skeletonPrfx}-btns__item`} />
      </div>
    </div>
  )
}
