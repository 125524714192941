import React from 'react'
import { SearchWindow } from '../../../../components/SearchWindow'
import { SearchHistoryArrayType } from '../../SearchHistoryPage-types'

interface SearchHistoryMobileProps {
  searchHistoryList: SearchHistoryArrayType
  handleClick: (id: string) => void
}

export const SearchHistoryMobile: React.FC<SearchHistoryMobileProps> = ({
  searchHistoryList,
  handleClick,
}) => {
  if (!searchHistoryList.length) {
    return <span>Отсутствует история поиска</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {searchHistoryList.map((searchItem, idx) => (
          <SearchWindow
            className='windows-wrapper-windows__item'
            key={idx}
            consumer={searchItem.consumer}
            category={searchItem.category}
            tags={searchItem.tags}
            search={searchItem.search}
            contributor={searchItem.contributor}
            searchDate={searchItem.searchDate}
            handleClick={() => handleClick(`${idx}`)}
          />
        ))}
      </div>
    </div>
  )
}
