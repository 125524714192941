import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { ADMINS_CACHE_PATH } from '../../constants/globalConstants'
import { UserRequestType } from '../../types/common'
import {
  adminChange,
  adminChangeStatus,
  adminRemove,
  getIdAdmin,
} from './AdminEditPage-api'

export const useAdminById = (adminId: string) => {
  const query = useQuery(
    [ADMINS_CACHE_PATH, adminId],
    async () => {
      const adminById = await getIdAdmin(adminId)
      return adminById
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useUpdateAdmin = () => {
  const client = useQueryClient()
  const mutation = useMutation(
    (values: UserRequestType) => adminChange(values.id!, { ...values }),
    {
      onSuccess: async (data, variables, context) => {
        await client.invalidateQueries([ADMINS_CACHE_PATH, variables.id])
        toast.info('Данные успешно обновлены')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось обновить данные')
      },
    }
  )

  return mutation
}

export const useRemoveAdmin = () => {
  const navigate = useNavigate()
  const mutation = useMutation((adminId: string) => adminRemove(adminId), {
    onSuccess: async (data, id, context) => {
      navigate(-1)
      toast.info('Администратор удален')
    },
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось удалить администратора')
    },
  })
  return mutation
}

export const useChangeStatusAdmin = () => {
  const client = useQueryClient()
  const mutation = useMutation(
    (adminId: string) => adminChangeStatus(adminId),
    {
      onSuccess: async (data, variables, context) => {
        await client.invalidateQueries([ADMINS_CACHE_PATH, variables])
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось сменить статус')
      },
    }
  )
  return mutation
}
