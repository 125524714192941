import { instance } from '../../utils/coreAPI'
import { MATERIAL_API_PATH } from '../../constants/globalConstants'
import { MODERATION_HISTORY_SIZE } from './ModerationHistoryPage-constants'

export const getModerationHistory = async (
  page: number,
  interval: string | undefined,
  status: string | undefined
) => {
  try {
    const response = await instance.get(
      `${MATERIAL_API_PATH}/moderation/history`,
      {
        params: {
          page,
          size: MODERATION_HISTORY_SIZE,
          interval,
          status,
        },
      }
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
