import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './ModeratorsPage-constants'
import { Moderators } from './modules/Moderators'

export const ModeratorsPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page moderators'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <Moderators />
      </div>
    </div>
  )
}
