import React from 'react'
import { useParams } from 'react-router'
import { ROLE_CONSUMER } from '../../constants/common'
import {
  CONSUMERS_PATH,
  CONTRIBUTORS_PATH,
  MAIN_PATH,
} from '../../constants/globalConstants'
import { StatisticModule } from '../../modules/StatisticModule'
import { ConsumerOrContributorRole } from '../../types/common'
import { useBreadcrumbs } from '../../utils/hooks'
import { MaterialsModule } from './modules/MaterialsModule'
import './PurchasedMaterialsPage.sass'

export const PurchasedMaterialsPage = ({
  userType,
}: {
  userType: ConsumerOrContributorRole
}) => {
  const { id } = useParams<'id'>()
  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Управление потребителями',
        path: CONSUMERS_PATH,
      },
      {
        id: '2',
        name:
          userType === ROLE_CONSUMER
            ? `Профиль потребителя`
            : `Профиль поставщика`,
        path:
          userType === ROLE_CONSUMER
            ? `${CONSUMERS_PATH}/${id}/edit`
            : `${CONTRIBUTORS_PATH}/${id}/edit`,
      },
      {
        id: '3',
        name: `Купленные материалы`,
        path:
          userType === ROLE_CONSUMER
            ? `${CONSUMERS_PATH}/${id}/purchased`
            : `${CONTRIBUTORS_PATH}/${id}/purchased`,
      },
    ],
    [id, userType]
  )
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <MaterialsModule id={id} userType={userType} />
      </div>
    </div>
  )
}
