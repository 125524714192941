import { OptionType } from '../../types/common'

export const getAuthorOption = (
  id: string | string[] | null,
  authorsList: OptionType[] | undefined
) => {
  if (!authorsList) return null
  if (!id) return null
  let currentId = '-1'
  typeof id === 'object' ? (currentId = id[0]) : (currentId = id)
  const authorOption = authorsList.filter(
    (authorObject) => authorObject.value === currentId
  )
  return authorOption[0] ?? null
}
