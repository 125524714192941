import classNames from 'classnames'
import React from 'react'
import { StatisticItem } from '../../../../components/StatisticItem'
import {
  getNormalizeDate,
  getOrderStatus,
  getRubles,
} from '../../../../utils/utils'

interface OrderInfoModuleProps {
  className?: string
  contributor: string
  moderator: string
  creationDate: string
  solutionDate: string
  description: string
  outgo: number
  status: string
}

export const OrderInfoModule: React.FC<OrderInfoModuleProps> = ({
  className,
  contributor,
  moderator,
  creationDate,
  solutionDate,
  description,
  outgo,
  status,
}) => {
  const modulePrfx = 'module-info'
  const divCls = classNames(modulePrfx, {
    [`${className}`]: className,
  })

  const contributorValue = contributor ?? 'неизвестно'
  const moderatorValue = moderator ?? 'неизвестно'
  const creationDateValue = getNormalizeDate(creationDate) ?? 'неизвестно'
  const solutionDateValue = getNormalizeDate(solutionDate) ?? 'неизвестно'
  const descriptionValue = description ?? 'отсутствует'
  const outgoValue = getRubles(outgo) ?? '-'
  const statusValue = getOrderStatus(status ?? 'неизвестно')

  return (
    <div className={divCls}>
      <h2 className={`${modulePrfx}__title`}>Информация о заявке</h2>
      <div className={`${modulePrfx}-body`}>
        {contributor && (
          <StatisticItem
            title='Поставщик'
            value={contributorValue}
            className={`${modulePrfx}-body__item`}
          />
        )}
        {moderator && (
          <StatisticItem
            title='Модератор'
            value={moderatorValue}
            className={`${modulePrfx}-body__item`}
          />
        )}
        {creationDate && (
          <StatisticItem
            title='Дата подачи'
            value={creationDateValue}
            className={`${modulePrfx}-body__item`}
          />
        )}
        {solutionDate && (
          <StatisticItem
            title='Дата решения'
            value={solutionDateValue}
            className={`${modulePrfx}-body__item`}
          />
        )}
        {outgo && (
          <StatisticItem
            title='Номинал вывода'
            green
            value={outgoValue}
            className={`${modulePrfx}-body__item`}
          />
        )}
        {status && (
          <StatisticItem
            title='Статус'
            value={statusValue}
            className={`${modulePrfx}-body__item`}
          />
        )}
        {description && (
          <StatisticItem
            title='Описание'
            value={descriptionValue}
            className={`${modulePrfx}-body__item`}
          />
        )}
      </div>
    </div>
  )
}
