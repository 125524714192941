import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './RejectionsPage-constants'
import { Rejections } from './modules/Rejections'

export const RejectionsPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <Rejections />
      </div>
    </div>
  )
}
