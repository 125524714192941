import classNames from 'classnames'
import React from 'react'
import { BsCheck, BsTrash, BsX } from 'react-icons/bs'
import { USER_STATUS_ENUM } from '../../types/enums'
import { Button } from '../Button'
import './AccountButtons.sass'

interface AccountButtonsProps {
  className?: string
  status: USER_STATUS_ENUM
  loadingChangeStatus: boolean
  loadingDeleteUser: boolean
  handleChangeStatus: () => void
  handleDeleteUser: () => void
}

export const AccountButtons: React.FC<AccountButtonsProps> = React.memo(
  ({
    className,
    status,
    loadingChangeStatus,
    loadingDeleteUser,
    handleChangeStatus,
    handleDeleteUser,
  }) => {
    const itemPrfx = 'accounts-btns'
    const itemCls = classNames(itemPrfx, {
      [`${className}`]: className,
    })
    const getStatusButton = () => {
      if (status === USER_STATUS_ENUM.ACTIVE) {
        return (
          <Button
            className={`${itemPrfx}__item ${itemPrfx}__item_danger`}
            type='transparent'
            htmlType={'button'}
            onClick={handleChangeStatus}
            loading={loadingChangeStatus}
          >
            Отключить аккаунт
            <span>
              <BsX />
            </span>
          </Button>
        )
      } else if (
        status === USER_STATUS_ENUM.DISABLED ||
        status === USER_STATUS_ENUM.LOCKED
      )
        return (
          <Button
            className={`${itemPrfx}__item ${itemPrfx}__item_success`}
            type='transparent'
            htmlType={'button'}
            onClick={handleChangeStatus}
            loading={loadingChangeStatus}
          >
            Включить аккаунт
            <span>
              <BsCheck />
            </span>
          </Button>
        )
    }
    return (
      <div className={itemCls}>
        {getStatusButton()}
        <Button
          className={`${itemPrfx}__item ${itemPrfx}__item_danger`}
          type='transparent'
          onClick={handleDeleteUser}
          loading={loadingDeleteUser}
        >
          Удалить аккаунт{' '}
          <span>
            <BsTrash />
          </span>
        </Button>
      </div>
    )
  }
)
