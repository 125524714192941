import React from 'react'
import { IncorretResult } from '../../components/IncorretResult'
import { MAIN_PATH, REJECTIONS_PATH } from '../../constants/globalConstants'
import { RejectionChangeForm } from './modules/RejectionChangeForm'
import { StatisticModule } from '../../modules/StatisticModule'
import { isObjectEmpty } from '../../utils/check'
import { useBreadcrumbs } from '../../utils/hooks'
import { RejectionType } from '../../types/common'

import { RejectionRequestType } from '../RejectionsPage/RejectionsPage-types'
import {
  useRejectionById,
  useRemoveRejection,
  useUpdateRejection,
} from './RejectionEditPage-hooks'
import { SkeletonFormPage } from '../../components/SkeletonFormPage'
import { useParams } from 'react-router'
import { confirmDeleteDialog } from '../../utils/confirms'

export const RejectionEditPage = () => {
  const { id } = useParams<'id'>()
  const {
    data: rejectionData,
    isLoading,
    isError,
  } = useRejectionById(id!) as {
    data: RejectionType
    isLoading: boolean
    isError: boolean
  }

  const { mutateAsync: updateRejection, isLoading: loadingChange } =
    useUpdateRejection() as {
      mutateAsync: (values: RejectionType) => Promise<any>
      isLoading: boolean
    }
  const { mutateAsync: removeRejection, isLoading: loadingDelete } =
    useRemoveRejection() as {
      mutateAsync: (id: string) => Promise<any>
      isLoading: boolean
    }

  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Управление причинами отказа',
        path: REJECTIONS_PATH,
      },
      {
        id: '2',
        name: `Причина "${rejectionData?.rejection ?? ''}"`,
        path: `${REJECTIONS_PATH}/${id}`,
      },
    ],
    [id, rejectionData]
  )

  useBreadcrumbs(breadCrumbsData)

  const handleSubmit = async (rejectionData: RejectionRequestType) => {
    await updateRejection({ id: id!, ...rejectionData })
  }

  const handleRemove = async (id: string) => {
    await removeRejection(id)
  }

  const handleDelete = async (id: string) => {
    const isOk = await confirmDeleteDialog(
      'Уверены, что хотите удалить эту причину?'
    )
    if (isOk) {
      handleRemove(id)
    }
  }

  if (isLoading) {
    return (
      <SkeletonFormPage
        rightColumn={'empty'}
        leftFormCount={1}
        withRegisterInfo={false}
      />
    )
  }
  if (isObjectEmpty(rejectionData) || isError) {
    return <IncorretResult />
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Изменение причины отказа'} />
      </div>

      <div className='common-page-body common-page-body_restricted'>
        <RejectionChangeForm
          id={id!}
          rejection={rejectionData.rejection}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          loadingChange={loadingChange}
          loadingDelete={loadingDelete}
        />
      </div>
    </div>
  )
}
