import { instance } from '../../utils/coreAPI'
import { MODERATOR_API_PATH } from '../../constants/globalConstants'
import { UserFormType } from '../../types/common'

export const moderatorAdd = async (data: UserFormType) => {
  try {
    const response = await instance.post(MODERATOR_API_PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
