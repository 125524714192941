import { AUTH_DATA_SET, AUTH_DATA_RESET } from './AuthModule-constants'
import { AuthActions, AuthStateParams } from './AuthModule-types'

export const authDataSetAction = (authData: AuthStateParams) => {
  return {
    type: AUTH_DATA_SET,
    payload: authData,
  }
}

export const authDataResetAction = (): AuthActions => {
  return {
    type: AUTH_DATA_RESET,
  }
}
