import classNames from "classnames";
import React from "react";
import queryString from "query-string";
// import { Button } from "../../../../components/Button";
import { Button } from "antd";

import { IncorretResult } from "../../../../components/IncorretResult";
import { Pagination } from "../../../../components/Pagination";
import { isMobileDevice } from "../../../../utils/check";
import { CONSUMERS_SIZE } from "../../ConsumerPage-constants";
import { useFetchConsumers } from "../../ConsumersPage-hooks";
import { ConsumersMobile } from "../ConsumersMobile";
import { ConsumersTable } from "../ConsumersTable";
import { FilterModule } from "../../../../modules/FilterModule";
import { SkeletonBody } from "../../../../components/SkeletonBody";
import { ConsumerAPIAnswerType } from "../../../../types/consumer";
import { useURLParams } from "../../../../hooks/useURLParams";
import { useNavigate } from "react-router";
import {
  INTERVAL_URL_VALUE,
  NAME_URL_VALUE,
  PAGE_URL_VALUE,
  USER_STATUS_URL_VALUE,
} from "../../../../constants/filterURLNames";

export const Consumers = () => {
  const navigate = useNavigate();
  const urlParams = useURLParams();
  const interval = urlParams[INTERVAL_URL_VALUE];
  const name = urlParams[NAME_URL_VALUE];
  const status = urlParams[USER_STATUS_URL_VALUE];
  const page = urlParams[PAGE_URL_VALUE];
  const currentPage = page ? +page : 1;

  const isMobile = isMobileDevice();
  // const isMobile = false;

  const {
    data: consumersRequestData,
    isLoading,
    isFetching,
    isError,
  } = useFetchConsumers(currentPage, status, interval, name) as {
    data: ConsumerAPIAnswerType;
    isFetching: boolean;
    isLoading: boolean;
    isError: boolean;
  };

  const consumersList = React.useMemo(
    () => consumersRequestData?.items,
    [consumersRequestData]
  );

  const total = consumersRequestData?.total;

  const pageCount = React.useMemo(() => {
    return Math.ceil(total / CONSUMERS_SIZE);
  }, [total]);

  if (isLoading) {
    const mode = isMobile === true ? "windows" : "table";
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={7}
        actionMode={"all"}
        withFilters={true}
      />
    );
  }
  if (isError) {
    return <IncorretResult />;
  }

  const handleRowClick = (id: string) => {
    navigate(`${id}/edit`);
  };

  const handleChangePage = (page: number) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString();
    navigate({ search: queryString.stringify(urlParams) });
  };

  const goToNewconsumer = () => {
    navigate(`new`);
  };

  const bodyCls = classNames("list-data-wrapper", {
    "list-data-wrapper_fetching": isFetching === true,
  });
  const btn = (
    <Button onClick={goToNewconsumer} size='large' type='primary'>
      Добавить аккаунт
    </Button>
  );

  return (
    <div className={bodyCls}>
      <FilterModule
        title={"Вы управляете следующими аккаунтами"}
        withUserStatus
        withSearch
        withDateInterval
        className='list-data-wrapper__filters'
      />
      {isMobile ? (
        <>
          <ConsumersMobile
            consumersList={consumersList}
            handleRowClick={handleRowClick}
          />
          <div className='list-data-wrapper-actions'>
            {btn}
            {pageCount > 1 && (
              <Pagination
                className='list-data-wrapper-actions__pagination'
                activeId={`${currentPage - 1}`}
                onChange={handleChangePage}
                count={pageCount}
              />
            )}
          </div>
        </>
      ) : (
        <ConsumersTable
          consumersList={consumersList}
          handleRowClick={handleRowClick}
          handleChangePage={handleChangePage}
          currentPage={currentPage}
          btn={btn}
          total={total}
          pageSize={CONSUMERS_SIZE}
        />
      )}
    </div>
  );
};
