import React from "react";
import queryString from "query-string";
import { useNavigate } from "react-router";
import { PAGE_URL_VALUE } from "../../../../constants/filterURLNames";
import { useURLParams } from "../../../../hooks/useURLParams";
import { isMobileDevice } from "../../../../utils/check";
import "./Groups.sass";
import { useFetchContributorGroups } from "../../ContributorGroupPage-hooks";
import { ContributorGroupAPIAnswerType } from "../../ContributorGroupPage-types";
import { GROUPS_SIZE } from "../../ContributorGroupPage-constants";
import { SkeletonBody } from "../../../../components/SkeletonBody";
import { IncorretResult } from "../../../../components/IncorretResult";
import classNames from "classnames";
import { Title } from "../../../../components/Title";
import { GroupsMobile } from "../GroupsMobile";
import { GroupsTable } from "../GroupsTable";
// import { Button } from "../../../../components/Button";
import { Button } from "antd";

import { Pagination } from "../../../../components/Pagination";

export const Groups = () => {
  const navigate = useNavigate();
  const urlParams = useURLParams();
  const page = urlParams[PAGE_URL_VALUE];
  const currentPage = page ? +page : 1;
  const isMobile = isMobileDevice();

  const {
    data: groupRequestData,
    isLoading,
    isFetching,
    isError,
  } = useFetchContributorGroups(currentPage) as {
    data: ContributorGroupAPIAnswerType;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
  };

  const groupList = React.useMemo(
    () => groupRequestData?.items,
    [groupRequestData]
  );
  const total = groupRequestData?.total;
  const pageCount = React.useMemo(() => {
    return Math.ceil(total / GROUPS_SIZE);
  }, [total]);

  const handleRowClick = (id: string) => {
    navigate(`${id}/edit`);
  };

  const handleChangePage = (page: number) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString();
    navigate({ search: queryString.stringify(urlParams) });
  };
  const handleAddGroup = () => {
    goToNewGroup();
  };
  const goToNewGroup = () => {
    navigate(`new`);
  };
  const btn = (
    <Button onClick={handleAddGroup} size='large' type='primary'>
      Добавить группу
    </Button>
  );

  if (isLoading) {
    const mode = isMobile === true ? "windows" : "table";
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={1}
        actionMode={"all"}
        withFilters={true}
      />
    );
  }
  if (isError) {
    return <IncorretResult />;
  }

  const bodyCls = classNames("list-data-wrapper", {
    "list-data-wrapper_fetching": isFetching === true,
  });

  return (
    <div className={bodyCls}>
      <Title text='Группы поставщиков' />
      {isMobile ? (
        <>
          <GroupsMobile groupList={groupList} handleRowClick={handleRowClick} />
          <div className='list-data-wrapper-actions'>
            {btn}
            {pageCount > 1 && (
              <Pagination
                className='list-data-wrapper-actions__pagination'
                activeId={`${currentPage - 1}`}
                onChange={handleChangePage}
                count={pageCount}
              />
            )}
          </div>
        </>
      ) : (
        <GroupsTable
          groupList={groupList}
          handleRowClick={handleRowClick}
          handleChangePage={handleChangePage}
          currentPage={currentPage}
          btn={btn}
          total={total}
          pageSize={GROUPS_SIZE}
        />
      )}
    </div>
  );
};
