import React from 'react'
import { Button } from '../Button'
import './EmptyComponent.sass'

interface EmptyComponentProps {
  text?: string
  action?: (x: any) => void
}

export const EmptyComponent: React.FC<EmptyComponentProps> = ({
  text,
  action,
}) => {
  return (
    <div className='empty-component'>
      <h1 className='empty-component__title'>
        По вашему запросу еще нет данных
      </h1>
      <h2 className='empty-component__sub'>Самое время пополнить коллецию</h2>
      {text ? <Button onClick={action}>{`Добавить ${text}`}</Button> : ''}
    </div>
  )
}
