import classNames from 'classnames'
import React from 'react'
import { BsCheck, BsTrash, BsX } from 'react-icons/bs'
import { Button } from '../../../../components/Button'
import { StatisticItem } from '../../../../components/StatisticItem'

interface InfoModuleProps {
  className?: string
  id: string
  contributors: number | string
  consumers: number | string
  moderators: number | string
  status: string
  onDelete: (id: string) => void
  onChangeStatus: (id: string) => void
  loadingDelete: boolean
  loadingChangeStatus: boolean
}

export const InfoModule: React.FC<InfoModuleProps> = ({
  className,
  id,
  contributors,
  consumers,
  moderators,
  status,
  onDelete,
  onChangeStatus,
  loadingDelete,
  loadingChangeStatus,
}) => {
  const modulePrfx = 'module-info'
  const divCls = classNames(modulePrfx, {
    [`${className}`]: className,
  })

  const getButton = () => {
    if (status === 'active') {
      return (
        <Button
          className={`${modulePrfx}-btns__item ${modulePrfx}-btns__item_change-status`}
          type='transparent'
          onClick={() => onChangeStatus(id)}
          loading={loadingChangeStatus}
        >
          Отключить аккаунт
          <span>
            <BsX />
          </span>
        </Button>
      )
    } else if (status === 'disabled' || 'locked')
      return (
        <Button
          className={`${modulePrfx}-btns__item ${modulePrfx}-btns__item_change-status ${modulePrfx}-btns__item_success`}
          type='transparent'
          onClick={() => onChangeStatus(id)}
          loading={loadingChangeStatus}
        >
          Включить аккаунт
          <span>
            <BsCheck />
          </span>
        </Button>
      )
  }

  return (
    <div className={divCls}>
      <h2 className={`${modulePrfx}__title`}>Статистика администратора</h2>
      <div className={`${modulePrfx}-body`}>
        <StatisticItem
          title='Поставщики'
          value={contributors ? contributors.toString() : '0'}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Потребители'
          value={consumers ? consumers.toString() : '0'}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Модераторы'
          value={moderators ? moderators.toString() : '0'}
          className={`${modulePrfx}-body__item`}
        />
      </div>
      <div className={`${modulePrfx}-btns`}>
        {getButton()}
        <Button
          className={`${modulePrfx}-btns__item ${modulePrfx}-btns__item_remove`}
          type='transparent'
          onClick={() => onDelete(id)}
          loading={loadingDelete}
        >
          Удалить аккаунт{' '}
          <span>
            <BsTrash />
          </span>
        </Button>
      </div>
    </div>
  )
}
