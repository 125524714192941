import React from 'react'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './AdminAddPage-constants'
import { useCreateAdmin } from './AdminAddPage-hooks'
import { StatisticModule } from '../../modules/StatisticModule'
import { UserAddForm } from '../../modules/UserAddForm'
import { useNavigate } from 'react-router'
import { UserFormType } from '../../types/common'

export const AdminAddPage = () => {
  useBreadcrumbs(breadCrumbsData) // Заполнение breadcrumbs для модуля
  const navigate = useNavigate()

  const { mutateAsync: createAdmin, isLoading: loadingCreate } =
    useCreateAdmin() as {
      mutateAsync: (adminData: UserFormType) => Promise<any>
      isLoading: boolean
    }

  const handleSubmit = (userData: UserFormType) => {
    createAdmin(userData)
  }
  const handleCancel = () => {
    navigate(-1)
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Добавление администратора'} />
      </div>
      <div className='common-page-body'>
        <UserAddForm
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isLoading={loadingCreate}
        />
      </div>
    </div>
  )
}
