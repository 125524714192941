import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { NEWS_CACHE_PATH } from '../../constants/globalConstants'
import {
  getNewsCall,
  getSnatchedNewsCall,
  invalidateNewsCall,
  saveNewsCall,
} from './NewsPage-api'
import { NewsArrayType } from './NewsPage-types'

export const useFetchNews = () => {
  const query = useQuery(
    [NEWS_CACHE_PATH, { type: 'current' }],
    async () => await getNewsCall(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )
  return query
}

export const useFetchSnatchedNews = () => {
  const query = useQuery(
    [NEWS_CACHE_PATH, { type: 'snatch' }],
    async () => await getSnatchedNewsCall(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      refetchInterval: 600000, // 10 min
    }
  )
  return query
}

export const useSaveNews = () => {
  const mutation = useMutation((news: NewsArrayType) => saveNewsCall(news), {
    onSuccess: async (data, variables, context) => {
      // await client.invalidateQueries([NEWS_CACHE_PATH, { type: 'current' }])
      toast.info('Новости успешно сохранены')
    },
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось сохранить изменения')
    },
  })

  return mutation
}

export const useInvalidateNews = () => {
  const client = useQueryClient()
  const mutation = useMutation(() => invalidateNewsCall(), {
    onSuccess: async (data, variables, context) => {
      await client.setQueryData([NEWS_CACHE_PATH, { type: 'current' }], data)
      // await client.invalidateQueries([NEWS_CACHE_PATH, { type: 'current' }])
      toast.info('Новости успешно обновлены')
    },
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось обновить новости')
    },
  })

  return mutation
}
