import React, { ReactElement } from "react";
import { COLUMNS } from "./CategoriesTable-constants";
import { CategoriesType } from "../../CategoriesPage-types";
import AntTable from "../../../../components/Table/AntTable";

interface CategoriesTableProps {
  categoriesList: CategoriesType;
  handleRowClick: (id: string) => void;
  handleChangePage: (page: number) => void;
  currentPage: number;
  btn: ReactElement<any, any> | null;
  total: number;
  pageSize: number;
}

export const CategoriesTable: React.FC<CategoriesTableProps> = ({
  categoriesList,
  handleRowClick,
  handleChangePage,
  currentPage,
  btn,
  total,
  pageSize,
}) => {
  const antColumns = React.useMemo(() => COLUMNS, []) as any;
  const data = React.useMemo(() => categoriesList, [categoriesList]);

  return (
    <AntTable
      columns={antColumns}
      data={data}
      currentPage={currentPage}
      handleChangePage={handleChangePage}
      handleRowClick={handleRowClick}
      btn={btn}
      total={total}
      pageSize={pageSize}
    />
  );
};
