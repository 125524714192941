import { instance } from '../../utils/coreAPI'
import { ADMIN_API_PATH } from '../../constants/globalConstants'
import { UserFormType } from '../../types/common'

export const getIdAdmin = async (id: string) => {
  try {
    const response = await instance.get(`${ADMIN_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const adminChange = async (id: string, data: UserFormType) => {
  try {
    const PATH = `${ADMIN_API_PATH}/${id}`
    const response = await instance.patch(PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const adminRemove = async (id: string) => {
  try {
    const PATH = `${ADMIN_API_PATH}/${id}`
    const response = await instance.delete(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
export const adminChangeStatus = async (id: string) => {
  try {
    const PATH = `${ADMIN_API_PATH}/${id}/switch/lock`
    const response = await instance.post(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
