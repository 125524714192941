import { REQUESTS_API_PATH } from '../../constants/globalConstants'
import { ContractType } from '../../types/contract'
import { instance } from '../../utils/coreAPI'

export const getIdRequest = async (id: string) => {
  try {
    const response = await instance.get(`${REQUESTS_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const requestAccept = async (
  id: string,
  data: ContractType,
  limit?: number
) => {
  try {
    const sendObject = {
      contract: {
        ...data,
      },
      limit: limit,
    }
    const PATH = `${REQUESTS_API_PATH}/${id}/accept`
    const response = await instance.post(PATH, sendObject)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const requestDeny = async (id: string) => {
  try {
    const response = await instance.post(`${REQUESTS_API_PATH}/${id}/deny`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
