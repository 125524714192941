import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { CATEGORIES_CACHE_PATH } from '../../constants/globalConstants'
import { CategoryType } from '../../types/common'
import {
  categoryChange,
  categoryRemove,
  getIdCategory,
} from './CategoriesEditPage-api'

export const useCategoryById = (categoryId: string) => {
  const query = useQuery(
    [CATEGORIES_CACHE_PATH, categoryId],
    async () => {
      const categoryById = await getIdCategory(categoryId)
      return categoryById
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useUpdateCategory = () => {
  const client = useQueryClient()
  const mutation = useMutation(
    (values: CategoryType) => categoryChange(values.id, { ...values }),
    {
      onSuccess: async (data, variables, context) => {
        await client.invalidateQueries([CATEGORIES_CACHE_PATH, variables.id])
        toast.info('Данные успешно обновлены')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось обновить данные')
      },
    }
  )

  return mutation
}

export const useRemoveCategory = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (categoryId: string) => categoryRemove(categoryId),
    {
      onSuccess: async () => {
        navigate(-1)
        toast.info('Категория удалена')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось удалить категорию')
      },
    }
  )
  return mutation
}
