import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { RootState } from '../../reducers'
import './BreadCrumbs.sass'

interface BreadCrumbsProps {
  className?: string
}

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ className }) => {
  const breadCrumbsPrfx = 'breadcrumbs'
  const breadCrumbsCls = classNames(breadCrumbsPrfx, {
    [`${className}`]: className,
  })
  const breadcrumbs = useSelector(
    (state: RootState) => state.breadCrumbs.breadcrumbs
  )
  return (
    <div className={breadCrumbsCls}>
      {breadcrumbs.map((breadcrumb, i, array) => {
        return (
          <React.Fragment key={i}>
            <NavLink className='breadcrumbs__item' to={breadcrumb.path}>
              {breadcrumb.name}
            </NavLink>
            {array.length !== i + 1 && (
              <span className='breadcrumbs__divider'> / </span>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}
