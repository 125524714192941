import classNames from 'classnames'
import React from 'react'
import { Button } from '../Button'
import { ReactComponent as RotateLeftIcon } from '../../assets/img/rotateLeft.svg'
import { ReactComponent as RotateRightIcon } from '../../assets/img/rotateRight.svg'
import './RotationBlock.sass'

interface RotationBlockProps {
  className?: string
  rotateLeft: () => void
  rotateRight: () => void
  isRotatingLeft: boolean
  isRotatingRight: boolean
}

export const RotationBlock: React.FC<RotationBlockProps> = ({
  className,
  rotateLeft,
  rotateRight,
  isRotatingLeft,
  isRotatingRight,
}) => {
  const itemPrfx = 'rotate-block'
  const itemCls = classNames(itemPrfx, {
    [`${className}`]: className,
  })

  return (
    <div className={itemCls}>
      <Button
        className={`${itemPrfx}__btn`}
        type='transparent'
        onClick={rotateLeft}
        loading={isRotatingLeft}
      >
        <RotateLeftIcon className={`${itemPrfx}__icon`} />
      </Button>
      <Button
        className={`${itemPrfx}__btn`}
        type='transparent'
        onClick={rotateRight}
        loading={isRotatingRight}
      >
        <RotateRightIcon className={`${itemPrfx}__icon`} />
      </Button>
    </div>
  )
}
