import classNames from 'classnames'
import React from 'react'
import { IncorretResult } from '../../../../components/IncorretResult'
import { SkeletonSnatchForm } from '../../../../components/SkeletonSnatchForm'
import { SnatchNewsComponent } from '../../../../components/SnatchNewsComponent'
import { Title } from '../../../../components/Title'
import { useFetchSnatchedNews } from '../../NewsPage-hooks'
import { NewsArrayType } from '../../NewsPage-types'
import './TodayNews.sass'

interface TodayNewsProps {
  className?: string
}

export const TodayNews: React.FC<TodayNewsProps> = ({ className }) => {
  const newsPrfx = 'today-news'

  const { data, isLoading, isFetching, isError } = useFetchSnatchedNews() as {
    data: NewsArrayType
    isLoading: boolean
    isFetching: boolean
    isError: boolean
  }

  const newsCls = classNames(newsPrfx, {
    [`${className}`]: className,
    [`${newsPrfx}_fetching`]: isFetching === true,
  })

  if (isLoading) {
    return <SkeletonSnatchForm />
  }
  if (isError) {
    return <IncorretResult />
  }

  return (
    <div className={newsCls}>
      <Title
        className={`${newsPrfx}__title`}
        text={'Актуальные новости в мире'}
      />
      {data.map((news, idx) => (
        <SnatchNewsComponent
          className={`${newsPrfx}__news`}
          news={news}
          key={idx}
        />
      ))}
    </div>
  )
}
