import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router'
import { IncorretResult } from '../../../../components/IncorretResult'
import { SkeletonInfoModule } from '../../../../components/SkeletonInfoModule'
import { StatisticItem } from '../../../../components/StatisticItem'
import {
  CONTRIBUTOR_URL_VALUE,
  INTERVAL_URL_VALUE,
  PURCHASE_INTERVAL_URL_VALUE,
} from '../../../../constants/filterURLNames'
import { ContributorStatisticAPIAnswerType } from '../../../../types/contributor'

interface InfoModuleProps {
  className?: string
  id: string
  statisticData: ContributorStatisticAPIAnswerType
  isLoading: boolean
  isError: boolean
}

export const InfoModule: React.FC<InfoModuleProps> = ({
  className,
  id,
  statisticData,
  isLoading,
  isError,
}) => {
  const navigate = useNavigate()
  const modulePrfx = 'module-info'
  const divCls = classNames(modulePrfx, {
    [`${className}`]: className,
  })

  if (isLoading) {
    return (
      <div className={divCls}>
        <SkeletonInfoModule count={10} />
      </div>
    )
  }
  if (isError) {
    return <IncorretResult />
  }

  const materialsMonth = `${statisticData?.monthly?.totalMaterials ?? '-'}`
  const materialsTotal = `${statisticData?.total?.totalMaterials ?? '-'}`

  const purchasedMonth = `${statisticData?.monthly?.purchased ?? '-'}`
  const purchasedTotal = `${statisticData?.total?.purchased ?? '-'}`

  // const processedMonth = `accept+denied+inPreload+newDownloaded+waitingForModeration`
  const processedMonth = `${
    statisticData?.monthly?.acceptModerated +
    statisticData?.monthly?.deniedModerated +
    statisticData?.monthly?.inPreload +
    statisticData?.monthly?.newDownloaded +
    statisticData?.monthly?.waitingForModeration
  }`
  const processedTotal = `${
    statisticData?.total?.acceptModerated +
    statisticData?.total?.deniedModerated +
    statisticData?.total?.inPreload +
    statisticData?.total?.newDownloaded +
    statisticData?.total?.waitingForModeration
  }`

  const deletedMonth = `${statisticData?.monthly?.deleted ?? '-'}`
  const deletedTotal = `${statisticData?.total?.deleted ?? '-'}`

  const handleTotalMonthClick = () => {
    navigate(
      `/materials?${CONTRIBUTOR_URL_VALUE}=${id}&${INTERVAL_URL_VALUE}=begin`
    )
  }
  const handleTotalClick = () => {
    navigate(`/materials?${CONTRIBUTOR_URL_VALUE}=${id}`)
  }
  const handleMonthPurchasedClick = () => {
    navigate(`../purchased?${PURCHASE_INTERVAL_URL_VALUE}=begin`)
  }
  const handleTotalPurchasedClick = () => {
    navigate('../purchased')
  }

  return (
    <div className={divCls}>
      <h2 className={`${modulePrfx}__title`}>Статистика поставщика</h2>
      <div className={`${modulePrfx}-body`}>
        <StatisticItem
          title='Обработано за этот месяц'
          value={processedMonth}
          onClick={handleTotalMonthClick}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Обработано всего'
          value={processedTotal}
          onClick={handleTotalClick}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Удалено за этот месяц'
          value={deletedMonth}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Удалено всего'
          value={deletedTotal}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Приобретено за этот месяц'
          value={purchasedMonth}
          onClick={handleMonthPurchasedClick}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Приобретено всего'
          value={purchasedTotal}
          onClick={handleTotalPurchasedClick}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Загружено за этот месяц'
          value={materialsMonth}
          onClick={handleTotalMonthClick}
          className={`${modulePrfx}-body__item`}
        />
        <StatisticItem
          title='Загружено всего'
          value={materialsTotal}
          onClick={handleTotalClick}
          className={`${modulePrfx}-body__item`}
        />
      </div>
    </div>
  )
}
