import {
  MODERATORS_STATISTIC_API_PATH,
  MODERATOR_API_PATH,
} from '../../constants/globalConstants'
import { UserFormType } from '../../types/common'
import { instance } from '../../utils/coreAPI'

export const getIdModerator = async (id: string) => {
  try {
    const response = await instance.get(`${MODERATOR_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getIdModeratorStatistics = async (id: string) => {
  try {
    const response = await instance.get(
      `${MODERATORS_STATISTIC_API_PATH}/${id}`
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const moderatorChange = async (id: string, data: UserFormType) => {
  try {
    const PATH = `${MODERATOR_API_PATH}/${id}`
    const response = await instance.patch(PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const moderatorRemove = async (id: string) => {
  try {
    const PATH = `${MODERATOR_API_PATH}/${id}`
    const response = await instance.delete(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
export const moderatorChangeStatus = async (id: string) => {
  try {
    const PATH = `${MODERATOR_API_PATH}/${id}/switch/lock`
    const response = await instance.post(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
