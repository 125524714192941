import { ADMINS_PATH, MAIN_PATH } from '../../constants/globalConstants'

export const ADMINS_SIZE = 12

export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Управление администраторами',
    path: ADMINS_PATH,
  },
]
