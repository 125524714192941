import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { REJECTIONS_CACHE_PATH } from '../../constants/globalConstants'
import { useNavigate } from 'react-router'
import { RejectionType } from '../../types/common'
import {
  getIdRejection,
  rejectionChange,
  rejectionRemove,
} from './RejectionEditPage-api'

export const useRejectionById = (rejectionId: string) => {
  const query = useQuery(
    [REJECTIONS_CACHE_PATH, rejectionId],
    async () => {
      const rejectionById = await getIdRejection(rejectionId)
      return rejectionById
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useUpdateRejection = () => {
  const client = useQueryClient()
  const mutation = useMutation(
    (values: RejectionType) => rejectionChange(values.id, { ...values }),
    {
      onSuccess: async (data, variables, context) => {
        await client.invalidateQueries([REJECTIONS_CACHE_PATH, variables.id])
        toast.info('Данные успешно обновлены')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось обновить данные')
      },
    }
  )

  return mutation
}

export const useRemoveRejection = () => {
  const navigate = useNavigate()
  const mutation = useMutation(
    (rejectionId: string) => rejectionRemove(rejectionId),
    {
      onSuccess: async (data, variables, context) => {
        navigate(-1)
        toast.info('Ограничение удалено')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось удалить ограничение')
      },
    }
  )
  return mutation
}
