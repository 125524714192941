import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getModifiedValue } from "../../../../utils/check";
import { Button } from "../../../../components/Button";
import {
  Input,
  InputNumber,
  InputPassword,
} from "../../../../components/Input";
import { phoneRe } from "../../../../utils/regulars";
import classNames from "classnames";
import { InputPhone } from "../../../../components/InputPhone";
import { AccountButtons } from "../../../../components/AccountButtons";
import { DatePickerCustom } from "../../../../components/DatePickerCustom";
import {
  ConsumerAddFormType,
  ConsumerEditType,
  ConsumerListItemType,
} from "../../../../types/consumer";
import Select from "react-select";
import { WrapperDoubleLine } from "../../../../components/WrapperDoubleLine";
import { FormTitle } from "../../../../components/FormTitle";
import "./ConsumerEditForm.sass";
import { getDateFromISO } from "../../../../utils/utils";
import { GroupListType } from "../../../../types/group";

interface ConsumerEditFormProps {
  className?: string;
  groups: GroupListType;
  consumerData: ConsumerListItemType;
  handleSubmit: (userData: ConsumerEditType) => void;
  handleDelete: (id: string) => void;
  handleChangeStatus: (id: string) => void;
  loadingDelete: boolean;
  loadingChangeStatus: boolean;
  loadingChange: boolean;
  loadingGroups: boolean;
  errorGroups: boolean;
}

export const ConsumerEditForm: React.FC<ConsumerEditFormProps> = React.memo(
  ({
    className,
    consumerData,
    groups,
    handleSubmit,
    handleDelete,
    handleChangeStatus,
    loadingDelete,
    loadingChangeStatus,
    loadingChange,
    errorGroups,
  }) => {
    const { name, login, limit, phone, email, status, id, group, contract } =
      consumerData;
    const formPrfx = "consumer-edit-form";
    const formCls = classNames(formPrfx, {
      [`${className}`]: className,
    });
    const groupId = group?.id || ""; //?

    const initialValues = {
      name: name,
      password: "",
      login: login,
      limit: limit,
      phone: phone,
      email: email,
      groupId: groupId /* ? groupId : "0" */,

      number: contract?.number ?? "",
      title: contract?.title ?? "",
      description: contract?.description ?? "",
      startDate: contract?.startDate
        ? new Date(contract.startDate)
        : new Date(),
      expiredDate: contract?.expiredDate
        ? new Date(contract.expiredDate)
        : new Date(),
    };

    const validationSchema = Yup.object({
      name: Yup.string().required("Обязательно"),
      login: Yup.string().required("Обязательно"),
      password: Yup.string(),
      email: Yup.string().email().required("Обязательно"),
      phone: Yup.string()
        .matches(phoneRe, "Неверный формат номера")
        .required("Обязательно"),
      limit: Yup.number(),
      groupId: Yup.string(),

      number: Yup.string().required("Обязательно"),
      title: Yup.string().required("Обязательно"),
      description: Yup.string(),
      startDate: Yup.date().required("Обязательно").max(new Date()),
      expiredDate: Yup.date().required("Обязательно").min(new Date()),
    });
    const onSubmit = async (values: ConsumerAddFormType) => {
      const modifiedValues: Partial<ConsumerAddFormType> = getModifiedValue(
        values,
        initialValues
      );     
      const modifiedContractValues = {
        contract: {
          ...(modifiedValues?.number && { number: modifiedValues.number }),
          ...(modifiedValues?.title && {
            title: modifiedValues.title,
          }),
          ...(modifiedValues?.description && {
            description: modifiedValues.description,
          }),
          ...(modifiedValues?.startDate && {
            startDate: getDateFromISO(modifiedValues.startDate.toISOString()),
          }),
          ...(modifiedValues?.expiredDate && {
            expiredDate: getDateFromISO(
              modifiedValues.expiredDate.toISOString()
            ),
          }),
        },
      };
      const modifiedValuesForRequest = {
        ...(modifiedValues?.name && { name: modifiedValues.name }),
        ...(modifiedValues?.login && { login: modifiedValues.login }),
        ...(modifiedValues?.password && { password: modifiedValues.password }),
        ...(modifiedValues?.email && { email: modifiedValues.email }),
        ...(modifiedValues?.limit && { limit: modifiedValues.limit }),
        ...(modifiedValues?.groupId && { groupId: modifiedValues.groupId }),
        ...(modifiedValues?.phone && {
          phone: modifiedValues.phone
            .replace(/\)/g, "")
            .replace(/\(/g, "")
            .replace(/-/g, "")
            .replace(/ /g, ""),
        }),
        ...modifiedContractValues,
      };

      if(modifiedValues?.groupId === '') modifiedValuesForRequest.groupId = 0;
      handleSubmit(modifiedValuesForRequest);
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
      >
        {(formik) => (
          <>
            <Form className={formCls}>
              <FormTitle text={"Персональная информация"} />
              <WrapperDoubleLine>
                <Input
                  type='text'
                  id='login'
                  title='Логин'
                  value={formik.values["login"]}
                  errors={formik.errors["login"]}
                  onChange={formik.handleChange}
                  className={`consumer-edit-form__control`}
                />
                <Input
                  type='text'
                  id='name'
                  title='Имя'
                  value={formik.values["name"]}
                  errors={formik.errors["name"]}
                  onChange={formik.handleChange}
                  className={`consumer-edit-form__control`}
                />
              </WrapperDoubleLine>
              <InputPassword
                type='password'
                id='password'
                title='Пароль'
                value={formik.values["password"]}
                errors={formik.errors["password"]}
                onChange={formik.handleChange}
                className={`consumer-edit-form__control`}
              />
              <WrapperDoubleLine>
                <Input
                  type='email'
                  id='email'
                  title='Почта'
                  value={formik.values["email"]}
                  errors={formik.errors["email"]}
                  onChange={formik.handleChange}
                  className={`consumer-edit-form__control`}
                />
                <InputPhone
                  id='phone'
                  value={formik.values["phone"]}
                  errors={formik.errors["phone"]}
                  onChange={formik.handleChange}
                  title='Номер телефона'
                  className={`consumer-edit-form__control`}
                />
              </WrapperDoubleLine>
              <InputNumber
                name='limit'
                className={`consumer-edit-form__control`}
                min={0}
                max={1000}
                step={1}
                type='number'
                title={"Лимит скачивания в месяц:"}
                value={formik.values["limit"]}
                onChange={formik.handleChange}
              />
              {!errorGroups && (
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Выберите группу'
                  name='groupId'
                  isClearable
                  defaultValue={formik.values["groupId"] && groups.find(
                    (group) => group.id === formik.values["groupId"]
                  )}
                  onChange={(option: any) => {
                    formik.setFieldValue("groupId", option?.id || "");
                  }}
                  getOptionLabel={(option) => (option) ? option?.groupName : ""}
                  getOptionValue={(option) => (option) ? option?.id : ""}
                  options={groups}
                />
              )}
              <FormTitle text={"Информация о контракте"} />
              <WrapperDoubleLine>
                <Input
                  type='text'
                  id='number'
                  title='Номер'
                  value={formik.values["number"]}
                  errors={formik.errors["number"]}
                  onChange={formik.handleChange}
                  className={`consumer-edit-form__control`}
                />
                <Input
                  type='text'
                  id='title'
                  title='Название'
                  value={formik.values["title"]}
                  errors={formik.errors["title"]}
                  onChange={formik.handleChange}
                  className={`consumer-edit-form__control`}
                />
              </WrapperDoubleLine>
              <Input
                type='text'
                id='description'
                title='Описание'
                value={formik.values["description"]}
                errors={formik.errors["description"]}
                onChange={formik.handleChange}
                className={`consumer-edit-form__control`}
              />
              <WrapperDoubleLine>
                <DatePickerCustom
                  name='startDate'
                  title='Дата начала контракта'
                  selected={formik.values["startDate"]}
                  errors={formik.errors["startDate"]}
                  onChange={(val) => formik.setFieldValue("startDate", val)}
                  className={`consumer-edit-form__control`}
                />
                <DatePickerCustom
                  name='expiredDate'
                  title='Дата конца контракта'
                  selected={formik.values["expiredDate"]}
                  errors={formik.errors["expiredDate"]}
                  onChange={(val) => formik.setFieldValue("expiredDate", val)}
                  className={`consumer-edit-form__control`}
                />
              </WrapperDoubleLine>
              <Button
                htmlType='submit'
                disabled={!formik.isValid}
                className={`consumer-edit-form__btn`}
                loading={loadingChange}
              >
                Сохранить изменения
              </Button>
            </Form>
            <AccountButtons
              status={status}
              loadingDeleteUser={loadingDelete}
              loadingChangeStatus={loadingChangeStatus}
              handleChangeStatus={() => handleChangeStatus(id)}
              handleDeleteUser={() => handleDelete(id)}
            />
          </>
        )}
      </Formik>
    );
  }
);
