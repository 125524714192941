import classNames from 'classnames'
import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonNewsComponent.sass'

interface SkeletonNewsComponentProps {
  className?: string
}

export const SkeletonNewsComponent: React.FC<SkeletonNewsComponentProps> = ({
  className,
}) => {
  const skeletonCls = classNames('skeleton-news-component', {
    [`${className}`]: className,
  })
  return (
    <div className={skeletonCls}>
      <SkeletonBase type='text' className='skeleton-news-component__text' />
      <SkeletonBase type='text' className='skeleton-news-component__tags' />
    </div>
  )
}
