import {
  GROUP_CONTRIBUTORS_PATH,
  MAIN_PATH,
} from '../../constants/globalConstants'

export const GROUPS_SIZE = 12

export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Группы поставщиков',
    path: GROUP_CONTRIBUTORS_PATH,
  },
]
