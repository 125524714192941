import { useQuery } from 'react-query'
import { MATERIALS_CACHE_PATH } from '../../../../constants/globalConstants'
import { getMaterialsList } from './MaterialsModule-api'

export const useFetchMaterials = (
  page: number,
  interval: string | undefined = undefined,
  sort: string | undefined = undefined,
  status: string | undefined = undefined,
  contributorId: string | undefined = undefined
) => {
  const query = useQuery(
    [MATERIALS_CACHE_PATH, { page, interval, sort, status, contributorId }],
    async () =>
      await getMaterialsList(page, interval, sort, status, contributorId),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
    }
  )
  return query
}
