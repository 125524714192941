import { ADMINS_PATH, MAIN_PATH } from '../../constants/globalConstants'

export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Управление администраторами',
    path: ADMINS_PATH,
  },
  {
    id: '2',
    name: 'Добавление нового администратора',
    path: `${ADMINS_PATH}/new`,
  },
]
