import React from 'react'
import { IncorretResult } from '../../components/IncorretResult'
import { MAIN_PATH, MODERATORS_PATH } from '../../constants/globalConstants'
import { StatisticModule } from '../../modules/StatisticModule'
import { UserChangeForm } from '../../modules/UserChangeForm'
import { isObjectEmpty } from '../../utils/check'
import { useBreadcrumbs } from '../../utils/hooks'
import {
  useChangeStatusModerator,
  useModeratorById,
  useRemoveModerator,
  useUpdateModerator,
} from './ModeratorEditPage-hooks'
import { InfoModule } from './modules/InfoModule'
import { SkeletonFormPage } from '../../components/SkeletonFormPage'
import { useParams } from 'react-router'
import { confirmDeleteDialog } from '../../utils/confirms'
import { UserFormType, UserRequestType } from '../../types/common'
import { ModeratorType } from '../../types/moderator'

export const ModeratorEditPage = () => {
  const { id } = useParams<'id'>()

  const {
    data: moderatorData,
    isLoading,
    isError,
  } = useModeratorById(id!) as {
    data: ModeratorType
    isLoading: boolean
    isError: boolean
  }

  const { mutateAsync: updateModerator, isLoading: loadingChange } =
    useUpdateModerator() as {
      mutateAsync: (values: Partial<UserRequestType>) => Promise<any>
      isLoading: boolean
    }

  const { mutateAsync: changeModeratorStatus, isLoading: loadingChangeStatus } =
    useChangeStatusModerator() as {
      mutateAsync: (id: string) => Promise<any>
      isLoading: boolean
    }
  const { mutateAsync: removeModerator, isLoading: loadingDelete } =
    useRemoveModerator() as {
      mutateAsync: (id: string) => Promise<any>
      isLoading: boolean
    }

  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Управление модераторами',
        path: MODERATORS_PATH,
      },
      {
        id: '2',
        name: `Профиль ${moderatorData?.login ?? ''}`,
        path: `${MODERATORS_PATH}/${id}`,
      },
    ],
    [id, moderatorData]
  )

  useBreadcrumbs(breadCrumbsData)

  const handleSubmit = async (userData: Partial<UserFormType>) => {
    await updateModerator({ id, ...userData })
  }

  const removeUser = async (id: string) => {
    await removeModerator(id)
  }

  const handleDelete = async (id: string) => {
    const isOk = await confirmDeleteDialog('Вы хотите удалить пользователя?')
    if (isOk) {
      removeUser(id)
    }
  }

  const handleChangeStatus = async (id: string) => {
    await changeModeratorStatus(id)
  }
  const handleHistory = () => {}

  if (isLoading) {
    return (
      <SkeletonFormPage
        rightColumn={'statistic'}
        leftFormCount={5}
        statisticCount={6}
        withRegisterInfo={true}
      />
    )
  }
  if (isError) {
    return <IncorretResult />
  }
  if (isObjectEmpty(moderatorData)) {
    return <IncorretResult />
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule
          registerBy={moderatorData?.registerBy}
          registerDate={moderatorData.registerDate}
          title={'Редактирование профиля'}
        />
      </div>

      <div className='common-page-body common-page-body_double'>
        <UserChangeForm
          className='common-page-body__left'
          name={moderatorData.name}
          password=''
          login={moderatorData.login}
          email={moderatorData.email}
          phone={moderatorData.phone}
          handleSubmit={handleSubmit}
          handleAction={handleHistory}
          loadingChange={loadingChange}
          buttonText='История модерации'
        />
        <InfoModule
          className='common-page-body__right'
          id={id!}
          status={moderatorData.status}
          onDelete={handleDelete}
          onChangeStatus={handleChangeStatus}
          loadingDelete={loadingDelete}
          loadingChangeStatus={loadingChangeStatus}
        />
      </div>
    </div>
  )
}
