import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { CONSUMER_GROUP } from '../../constants/common'
import {
  GROUP_CONSUMERS_CACHE_PATH,
  REPORT_CACHE_PATH,
} from '../../constants/globalConstants'
import { ConsumerGroupEditType } from '../../types/group'
import {
  consumerGroupReportGenerate,
  getConsumerGroupReports,
  getGroupConsumerById,
  groupConsumerUpdate,
} from './ConsumerGroupEditPage-api'
import { ReportRequestFieldsType } from './ConsumerGroupEditPage-types'

export const useReportGenerate = (id: string) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    (values: ReportRequestFieldsType) =>
      consumerGroupReportGenerate(id, values.startDate, values.endDate),
    {
      onMutate: () => {
        toast.info(
          'Отчет будет сформирован в ближайшее время. Вы можете продолжить пользоваться сервисом.'
        )
      },
      onSuccess: async (data, variables, context) => {
        navigate('../edit?page=1')
        queryClient.invalidateQueries([
          REPORT_CACHE_PATH,
          { id, page: 1, type: CONSUMER_GROUP },
        ])
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось создать отчет')
      },
    }
  )

  return mutation
}

export const useConsumerGroupReports = (
  consumerGroupId: string,
  page: number
) => {
  const query = useQuery(
    [
      REPORT_CACHE_PATH,
      { id: consumerGroupId, page: page, type: CONSUMER_GROUP },
    ],
    async () => {
      const contributorReports = await getConsumerGroupReports(
        consumerGroupId,
        page
      )
      return contributorReports
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
    }
  )
  return query
}

export const useGroupConsumerById = (consumerGroupId: string) => {
  const query = useQuery(
    [GROUP_CONSUMERS_CACHE_PATH, { id: consumerGroupId }],
    async () => {
      const consumerById = await getGroupConsumerById(consumerGroupId)
      return consumerById
    },
    {
      staleTime: 100000,
      refetchInterval: 100000,
      enabled: Boolean(consumerGroupId),
    }
  )
  return query
}

export const useUpdateConsumerGroup = (id: string) => {
  const client = useQueryClient()
  const mutation = useMutation(
    (values: ConsumerGroupEditType) => groupConsumerUpdate(id, { ...values }),
    {
      onSuccess: async (data, variables, context) => {
        await client.invalidateQueries([
          GROUP_CONSUMERS_CACHE_PATH,
          { id: `${id}` },
        ])
        toast.info('Данные успешно обновлены')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось обновить данные')
      },
    }
  )

  return mutation
}
