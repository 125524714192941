import { instance } from '../../utils/coreAPI'
import { REJECTION_API_PATH } from '../../constants/globalConstants'
import { RejectionRequestType } from '../RejectionsPage/RejectionsPage-types'

export const rejectionAdd = async (data: RejectionRequestType) => {
  try {
    const response = await instance.post(REJECTION_API_PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
