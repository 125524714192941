import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { IncorretResult } from '../../../../components/IncorretResult'
import { Pagination } from '../../../../components/Pagination'
import { FilterModule } from '../../../../modules/FilterModule'
import { MATERIALS_SIZE } from './MaterialsModule-constants'
import { useFetchMaterials } from './MaterialsModule-hooks'
import './MaterialsModule.sass'
import { GalleryModule } from '../../../../modules/GalleryModule'
import { SkeletonBody } from '../../../../components/SkeletonBody'
import { useURLParams } from '../../../../hooks/useURLParams'
import { useNavigate } from 'react-router'
import {
  CONTRIBUTOR_URL_VALUE,
  INTERVAL_URL_VALUE,
  MATERIAL_STATUS_URL_VALUE,
  PAGE_URL_VALUE,
  SORT_URL_VALUE,
} from '../../../../constants/filterURLNames'
import { MaterialsListAPIAnswerType } from '../../../../types/materials'

export const MaterialsModule = () => {
  const navigate = useNavigate()
  const urlParams = useURLParams()
  const interval = urlParams[INTERVAL_URL_VALUE]
  const sort = urlParams[SORT_URL_VALUE]
  const status = urlParams[MATERIAL_STATUS_URL_VALUE]
  const authorId = urlParams[CONTRIBUTOR_URL_VALUE]
  const page = urlParams[PAGE_URL_VALUE]
  const currentPage = page ? +page : 1

  const {
    data: materialsRequestData,
    isLoading,
    isError,
    isFetching,
  } = useFetchMaterials(currentPage, interval, sort, status, authorId) as {
    data: MaterialsListAPIAnswerType
    isLoading: boolean
    isError: boolean
    isFetching: boolean
  }

  const materials = React.useMemo(
    () => materialsRequestData?.items,
    [materialsRequestData]
  )

  const total = materialsRequestData?.total

  const pageCount = React.useMemo(() => {
    return Math.ceil(total / MATERIALS_SIZE)
  }, [total])

  const handleChangePhoto = (page: string) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString()
    navigate({ search: queryString.stringify(urlParams) })
  }

  if (isLoading) {
    return (
      <SkeletonBody
        mode={'gallery'}
        columnsCount={0}
        actionMode={'pagination'}
        withFilters={true}
      />
    )
  }
  if (isError) {
    return <IncorretResult />
  }

  const materialsPrfx = 'materials'
  const galleryCls = classNames(materialsPrfx, {
    [`${materialsPrfx}_fetching`]: isFetching === true,
  })

  return (
    <div className={galleryCls}>
      <FilterModule
        title={'Материалы'}
        withMaterialStatus
        withDateInterval
        withSort
        className={`${materialsPrfx}__filters`}
      />
      <GalleryModule materials={materials} className={'materials-gallery'} />
      {pageCount > 1 && (
        <Pagination
          className='materials__pagination'
          activeId={`${currentPage - 1}`}
          onChange={handleChangePhoto}
          count={pageCount}
        />
      )}
    </div>
  )
}
