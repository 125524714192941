import classNames from 'classnames'
import React from 'react'
import placeholder from '../../assets/img/placholder_img.jpg'
import { format, parseISO } from 'date-fns'
import ru from 'date-fns/esm/locale/ru/index.js'
import { MaterialImg } from '../MaterialImg'
import './MaterialItem.sass'
import { StatusItem } from '../StatusItem'
import { Link } from 'react-router-dom'

interface MaterialItemProps {
  className?: string
  path: string
  imgSrc: string
  imgSrcOriginal?: string
  tags: string[]
  status: string
  date: string
  author: string
}

export const MaterialItem: React.FC<MaterialItemProps> = ({
  path,
  className,
  imgSrc,
  tags,
  date,
  status,
  author,
}) => {
  const imgSrcWithDefault = imgSrc ? imgSrc : placeholder
  const materialPrfx = 'material-item'
  const materialStatistics = `${materialPrfx}-statistics`
  const materialCls = classNames(materialPrfx, {
    [`${className}`]: className,
  })
  return (
    <Link className={materialCls} to={path}>
      <MaterialImg
        srcPath={imgSrcWithDefault}
        className={`${materialPrfx}__img`}
        contain
      />
      <div className={`${materialPrfx}-statistics`}>
        <div className={`${materialPrfx}-statistics__line`}>
          <span className={`${materialStatistics}__author`}>
            Автор: {author}
          </span>
        </div>
        <div className={`${materialPrfx}-statistics__line`}>
          <span className={`${materialStatistics}__date`}>
            {format(parseISO(date), 'dd.MM.yyyy', {
              locale: ru,
            })}
          </span>

          <StatusItem value={status} className={`${materialPrfx}__status`} />
        </div>
        {tags.length > 0 ? (
          <ul className={`${materialPrfx}-tags`}>
            {tags.slice(0, 3).map((tag, key) => (
              <li
                className={`${materialPrfx}-tags__item`}
                key={key}
                title={tag}
              >
                {tag}
              </li>
            ))}
          </ul>
        ) : (
          {}
        )}
      </div>
    </Link>
  )
}
