import { MAIN_PATH } from '../../constants/globalConstants'

export const breadcrumbs = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Статистика администратора',
    path: MAIN_PATH,
  },
]
