import { MAIN_PATH, MODERATORS_PATH } from '../../constants/globalConstants'

export const MODERATORS_SIZE = 12
export const breadCrumbsData = [
  {
    id: '0',
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: '1',
    name: 'Управление модераторами',
    path: MODERATORS_PATH,
  },
]
