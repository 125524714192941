import React from 'react'
import { ContributorWindow } from '../../../../components/ContributorWindow'
import { ContributorsType } from '../../../../types/contributor'

interface ContributorsMobileProps {
  contributorsList: ContributorsType
  handleRowClick: (id: string) => void
}

export const ContributorsMobile: React.FC<ContributorsMobileProps> = ({
  contributorsList,
  handleRowClick,
}) => {
  if (!contributorsList.length) {
    return <span>Еще не создано ни одного поставщика</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {contributorsList.map((contributor) => (
          <ContributorWindow
            className='windows-wrapper-windows__item'
            key={contributor.id}
            id={contributor.id}
            status={contributor.status}
            login={contributor.login}
            earning={contributor.earning}
            registerDate={contributor.registerDate}
            onClick={handleRowClick}
          />
        ))}
      </div>
    </div>
  )
}
