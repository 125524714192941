import React from 'react'
import { Button } from '../Button'
import { Modal } from '../Modal'
import './ConfirmDialog.sass'

interface ConfirmDialogProps {
  proceedLabel: string
  cancelLabel: string
  title: string
  confirmation: string
  show: boolean
  proceed: (...args: any[]) => any
  enableEscape: boolean
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  proceedLabel,
  cancelLabel,
  title,
  confirmation,
  show,
  proceed,
}) => {
  return (
    <Modal onHide={() => proceed(false)} show={show}>
      <div className='confirm-dialog'>
        <span className='confirm-dialog__title'>{title}</span>
        <span className={'confirm-dialog__text'}>{confirmation}</span>
        <div className='confirm-dialog-btns'>
          <Button
            className={'confirm-dialog-btns__item confirm-dialog-btns__item_ok'}
            type='transparent'
            border
            onClick={() => proceed(true)}
          >
            {proceedLabel}
          </Button>
          <Button
            className={
              'confirm-dialog-btns__item confirm-dialog-btns__item_cancel'
            }
            type='danger'
            onClick={() => proceed(false)}
          >
            {cancelLabel}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
