import classNames from "classnames";
import React from "react";
import queryString from "query-string";
// import { Button } from "../../../../components/Button";
import { Button } from "antd";

import { IncorretResult } from "../../../../components/IncorretResult";
import { Pagination } from "../../../../components/Pagination";
import { FilterModule } from "../../../../modules/FilterModule";
import { isMobileDevice } from "../../../../utils/check";
import { CONTRIBUTORS_SIZE } from "../../ContributorsPage-constants";
import { useFetchContributors } from "../../ContributorsPage-hooks";
import { ContributorsMobile } from "../ContributorsMobile";
import { ContributorsTable } from "../ContributorsTable";
import { SkeletonBody } from "../../../../components/SkeletonBody";
import { ContributorAPIAnswerType } from "../../../../types/contributor";
import { useNavigate } from "react-router";
import { useURLParams } from "../../../../hooks/useURLParams";
import {
  INTERVAL_URL_VALUE,
  NAME_URL_VALUE,
  PAGE_URL_VALUE,
  USER_STATUS_URL_VALUE,
} from "../../../../constants/filterURLNames";

export const Contributors = () => {
  const navigate = useNavigate();
  const urlParams = useURLParams();
  const interval = urlParams[INTERVAL_URL_VALUE];
  const name = urlParams[NAME_URL_VALUE];
  const status = urlParams[USER_STATUS_URL_VALUE];
  const page = urlParams[PAGE_URL_VALUE];
  const currentPage = page ? +page : 1;

  const isMobile = isMobileDevice();

  const {
    data: contributorsRequestData,
    isLoading,
    isFetching,
    isError,
  } = useFetchContributors(currentPage, status, interval, name) as {
    data: ContributorAPIAnswerType;
    isFetching: boolean;
    isLoading: boolean;
    isError: boolean;
  };

  const contributorsList = React.useMemo(
    () => contributorsRequestData?.items,
    [contributorsRequestData]
  );
  const total = contributorsRequestData?.total;
  const pageCount = React.useMemo(() => {
    return Math.ceil(total / CONTRIBUTORS_SIZE);
  }, [total]);

  const handleRowClick = (id: string) => {
    navigate(`${id}/edit`);
  };

  const handleChangePage = (page: number) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString();
    navigate({ search: queryString.stringify(urlParams) });
  };

  const goToNewContributor = () => {
    navigate(`new`);
  };

  if (isLoading) {
    const mode = isMobile === true ? "windows" : "table";
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={5}
        actionMode={"all"}
        withFilters={true}
      />
    );
  }

  if (isError) {
    return <IncorretResult />;
  }

  const bodyCls = classNames("list-data-wrapper", {
    "list-data-wrapper_fetching": isFetching === true,
  });
  const btn = (
    <Button onClick={goToNewContributor} size='large' type='primary'>
      Добавить аккаунт
    </Button>
  );

  return (
    <div className={bodyCls}>
      <FilterModule
        title={"Вы управляете следующими аккаунтами"}
        withUserStatus
        withSearch
        withDateInterval
        className='list-data-wrapper__filters'
      />
      {isMobile ? (
        <>
          <ContributorsMobile
            contributorsList={contributorsList}
            handleRowClick={handleRowClick}
          />
          <div className='list-data-wrapper-actions'>
            <Button onClick={goToNewContributor} size='large' type='primary'>
              Добавить аккаунт
            </Button>
            {pageCount > 1 && (
              <Pagination
                className='list-data-wrapper-actions__pagination'
                activeId={`${currentPage - 1}`}
                onChange={handleChangePage}
                count={pageCount}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <ContributorsTable
            contributorsList={contributorsList}
            handleRowClick={handleRowClick}
            handleChangePage={handleChangePage}
            currentPage={currentPage}
            btn={btn}
            total={total}
            pageSize={CONTRIBUTORS_SIZE}
          />
        </>
      )}
    </div>
  );
};
