import { instance } from '../../utils/coreAPI'
import { REJECTION_API_PATH } from '../../constants/globalConstants'
import { RejectionRequestType } from '../RejectionsPage/RejectionsPage-types'

export const getIdRejection = async (id: string) => {
  try {
    const response = await instance.get(`${REJECTION_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const rejectionChange = async (
  id: string,
  data: RejectionRequestType
) => {
  try {
    const PATH = `${REJECTION_API_PATH}/${id}`
    const response = await instance.patch(PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const rejectionRemove = async (id: string) => {
  try {
    const PATH = `${REJECTION_API_PATH}/${id}`
    const response = await instance.delete(PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
