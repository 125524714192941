import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadCrumbsData } from './MaterialsPage-constants'
import { MaterialsModule } from './modules/MaterialsModule'

export const MaterialsPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule />
      </div>
      <div className='common-page-body'>
        <MaterialsModule />
      </div>
    </div>
  )
}
