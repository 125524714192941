import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router'
import Close from '../../assets/img/close.svg'
import { Popup } from '../../components/Popup'
import { UserImg } from '../../components/UserImg'
import { AdminSelfType } from '../../types/admin'
import { MenuModule } from '../MenuModule'
import { useFetchProfileInfo } from './AdminProfile-hooks'
import './AdminProfile.sass'

export const AdminProfile = ({ isOpened = false }: { isOpened?: boolean }) => {
  const navigate = useNavigate()
  const closeProfileWindow = () => {
    navigate(-1)
  }
  const divPrfx = 'admin-profile'
  const divCls = classNames(divPrfx)

  const { data } = useFetchProfileInfo() as {
    data: AdminSelfType
  }

  return (
    <Popup>
      <div className={divCls}>
        <div className='admin-profile__close' onClick={closeProfileWindow}>
          <img src={Close} alt='Закрыть панель' />
        </div>
        <div className='admin-profile-content'>
          <div className='admin-profile-content-top'>
            <UserImg className='admin-profile-content-top__photo' photo={''} />
            <span className='admin-profile-content-top__login'>
              {data?.login}
            </span>
            <span className='admin-profile-content-top__role'>
              Администратор ресурса
            </span>
          </div>
          <div className='admin-profile-content-bottom'>
            <MenuModule
              alignCenter={true}
              className='admin-profile-content-bottom__menu'
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}
