import React from 'react'
import queryString from 'query-string'
import { StatisticModule } from '../../modules/StatisticModule'
import { InfoModule } from './modules/InfoModule'
import { useBreadcrumbs } from '../../utils/hooks'
import {
  useConsumerById,
  useConsumerReports,
  useConsumerStatistics,
} from './ConsumerEditPage-hooks'
import { CONSUMERS_PATH, MAIN_PATH } from '../../constants/globalConstants'
import { ReportListModule } from '../../modules/ReportListModule'
import { WrapperGrid } from '../../components/WrapperGrid'
import {
  ConsumerListItemType,
  ConsumerStatisticAPIAnswerType,
} from '../../types/consumer'
import { ConsumerEditModule } from './modules/ConsumerEditModule'
import { ReportAPIAnswerType } from '../../types/report'
import { useNavigate, useParams } from 'react-router'
import { useURLParams } from '../../hooks/useURLParams'
import { PAGE_URL_VALUE } from '../../constants/filterURLNames'

export const ConsumerEditPage = React.memo(() => {
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  const urlParams = useURLParams()
  const { page } = urlParams
  const currentPage = page ? +page : 1
  const handleChangePage = (page: string) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString()
    navigate({ search: queryString.stringify(urlParams) })
  }

  const {
    data: consumerData,
    isLoading,
    isError,
  } = useConsumerById(id!) as {
    data: ConsumerListItemType
    isLoading: boolean
    isError: boolean
  }

  const {
    data: statisticData,
    isLoading: statisticLoading,
    isError: statisticError,
  } = useConsumerStatistics(id!) as {
    data: ConsumerStatisticAPIAnswerType
    isLoading: boolean
    isError: boolean
  }

  const {
    data: reportsData,
    isLoading: isReportsLoading,
    isError: isReportsError,
  } = useConsumerReports(id!, currentPage) as {
    data: ReportAPIAnswerType
    isLoading: boolean
    isError: boolean
  }

  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Управление потребителями',
        path: CONSUMERS_PATH,
      },
      {
        id: '2',
        name: `Профиль ${consumerData?.login ?? ''}`,
        path: `${CONSUMERS_PATH}/${id}`,
      },
    ],
    [id, consumerData]
  )

  useBreadcrumbs(breadCrumbsData)

  const handleReport = () => {
    navigate('../report')
  }

  // if (isLoading) {
  //   return (
  //     <SkeletonFormPage
  //       rightColumn={'statistic'}
  //       leftFormCount={6}
  //       withRegisterInfo={true}
  //       statisticCount={4}
  //     />
  //   )
  // }
  // if (isObjectEmpty(consumerData) || isError) {
  //   return <IncorretResult />
  // }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule
          title={'Редактирование профиля'}
          registerBy={consumerData?.registerBy}
          registerDate={consumerData?.registerDate}
        />
      </div>

      <WrapperGrid>
        <ConsumerEditModule
          id={id!}
          isLoading={isLoading}
          isError={isError}
          consumerData={consumerData}
        />
        <InfoModule
          statisticData={statisticData}
          isLoading={statisticLoading}
          isError={statisticError}
        />
        <ReportListModule
          reportList={reportsData}
          currentPage={currentPage}
          handlePageChange={handleChangePage}
          handleReport={handleReport}
          isLoading={isReportsLoading}
          isError={isReportsError}
        />
      </WrapperGrid>
    </div>
  )
})
