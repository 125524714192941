import React, { ReactElement } from "react";
import AntTable from "../../../../components/Table/AntTable";
import {
  getNormalizeDate,
  getOrderStatus,
  getUserType,
} from "../../../../utils/utils";
import { RequestsType } from "../../RequestsPage-types";

const COLUMNS = [
  {
    title: "Логин",
    dataIndex: "login",
  },
  {
    title: "Имя",
    dataIndex: "name",
  },
  {
    title: "Дата регистрации",
    dataIndex: "registerDate",
    // Cell: (cellInfo: any) => {
    //   return getNormalizeDate(cellInfo.row.original?.registerDate);
    // },
    render: (cellInfo: any) => {
      return <>{getNormalizeDate(cellInfo)}</>;
    },
  },
  {
    title: "Тип пользователя",
    dataIndex: "userType",
    render: (cellInfo: any) => {
      return <>{getUserType(cellInfo)}</>;
    },
  },
  {
    title: "Статус",
    dataIndex: "status",
    render: (cellInfo: any) => {
      return <>{getOrderStatus(cellInfo)}</>;
    },
  },
];

interface RequestsTableProps {
  requestsList: RequestsType;
  handleRowClick: (id: string | number) => void;
  handleChangePage: (page: number) => void;
  pageCount: number;
  currentPage: number;
  btn: ReactElement<any, any> | null;
  total: number;
  pageSize: number;
}

export const RequestsTable: React.FC<RequestsTableProps> = ({
  requestsList,
  handleRowClick,
  handleChangePage,
  pageCount,
  currentPage,
  btn,
  total,
  pageSize,
}) => {
  const antColumns = React.useMemo(() => COLUMNS, []) as any;
  const data = React.useMemo(() => requestsList, [requestsList]);
  return (
    <AntTable
      columns={antColumns}
      data={data}
      currentPage={currentPage}
      handleChangePage={handleChangePage}
      handleRowClick={handleRowClick}
      btn={btn}
      total={total}
      pageSize={pageSize}
    />
  );
};
