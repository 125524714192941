import React from 'react'
import queryString from 'query-string'
import { StatisticModule } from '../../modules/StatisticModule'
import { InfoModule } from './modules/InfoModule'
import { useBreadcrumbs } from '../../utils/hooks'
import {
  useContributorById,
  useContributorReports,
  useContributorStatistics,
} from './ContributorEditPage-hooks'
import { CONTRIBUTORS_PATH, MAIN_PATH } from '../../constants/globalConstants'
import {
  ContributorType,
  ContributorStatisticAPIAnswerType,
} from '../../types/contributor'
import { WrapperGrid } from '../../components/WrapperGrid'
import { ContributorEditModule } from './modules/ContributorEditModule'
import { ReportListModule } from '../../modules/ReportListModule'
import { ReportAPIAnswerType } from '../../types/report'
import { useNavigate, useParams } from 'react-router'
import { useURLParams } from '../../hooks/useURLParams'
import { PAGE_URL_VALUE } from '../../constants/filterURLNames'

export const ContributorEditPage = () => {
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  const urlParams = useURLParams()
  const { page } = urlParams
  const currentPage = page ? +page : 1
  const handleChangePage = (page: string) => {
    urlParams[PAGE_URL_VALUE] = (+page + 1).toString()
    navigate({ search: queryString.stringify(urlParams) })
  }

  const {
    data: contributorData,
    isLoading,
    isError,
  } = useContributorById(id!) as {
    data: ContributorType
    isLoading: boolean
    isError: boolean
  }

  const {
    data: statisticData,
    isLoading: isStatisticLoading,
    isError: isStatisticError,
  } = useContributorStatistics(id!) as {
    data: ContributorStatisticAPIAnswerType
    isLoading: boolean
    isError: boolean
  }

  const {
    data: reportsData,
    isLoading: isReportsLoading,
    isError: isReportsError,
  } = useContributorReports(id!, currentPage) as {
    data: ReportAPIAnswerType
    isLoading: boolean
    isError: boolean
  }

  const userLogin = contributorData?.login ?? ''

  const breadCrumbsData = React.useMemo(
    () => [
      {
        id: '0',
        name: 'Кабинет администратора',
        path: MAIN_PATH,
      },
      {
        id: '1',
        name: 'Управление поставщиками',
        path: CONTRIBUTORS_PATH,
      },
      {
        id: '2',
        name: `Профиль ${userLogin}`,
        path: `${CONTRIBUTORS_PATH}/${id}/edit`,
      },
    ],
    [id, userLogin]
  )

  useBreadcrumbs(breadCrumbsData)

  const handleReport = () => {
    navigate('../report')
  }

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule
          title={'Редактирование профиля'}
          registerBy={contributorData?.registerBy}
          registerDate={contributorData?.registerDate}
        />
      </div>
      <WrapperGrid>
        <ContributorEditModule
          id={id!}
          isLoading={isLoading}
          isError={isError}
          contributorData={contributorData}
        />
        <InfoModule
          id={id!}
          statisticData={statisticData}
          isLoading={isStatisticLoading}
          isError={isStatisticError}
        />
        <ReportListModule
          reportList={reportsData}
          isLoading={isReportsLoading}
          isError={isReportsError}
          currentPage={currentPage}
          handlePageChange={handleChangePage}
          handleReport={handleReport}
        />
      </WrapperGrid>
    </div>
  )
}
